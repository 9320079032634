import React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import "./faq.css";

const FaqBanner = () => {
  return (
    <>
      <Box gap={4} className="banner-container">
        <Box className="banner">
          <Grid container sx={{ backgroundColor: "#0078D6", color: "#fff" }}>
            <Grid item sm={12} md={8} className="banner-content">
              <Typography variant="h2" className="banner-heading">
                Volunteering Guidelines
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                To ensure a seamless and impactful volunteering experience,
                we've set forth a few guidelines. Let these be your compass as
                you embark on this transformative journey.
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  paddingTop: "50px",
                  paddingBottom: "50px",
                  paddingX: "60px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "20px",
                    borderRadius: 0,
                  }}
                  variant="contained"
                  className="download-button"
                  target="_blank"
                  href={require("../../static/docs/Volunteering Guidelines.pdf")}
                >
                  Download Guidelines
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default FaqBanner;
