import { CircularProgress, Grid } from "@mui/material";
import React from "react";

const Loader = () => {
    return (
        <>
        <Grid
         container
         spacing={0}
         direction="column"
         alignItems="center"
         justifyContent='center'
         style={{ minHeight: '50vh' }}
        >
            <CircularProgress />
        </Grid>
        
        </>
    )
}
export default Loader