import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Avatar, Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import HubIcon from "@mui/icons-material/Hub";
import "./style.css";
import { Link as RouterLink } from "react-router-dom";
import { pages } from "../helpers/constant";
import { useAppSelector } from "../app/hooks";

export default function Footer() {
  const user = useAppSelector((state) => state.userProfile.profile);

  return (
    <React.Fragment>
      <Container
        className="dark-bg-footer footer-navigation"
        disableGutters
        maxWidth={false}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid py={3} px={"5%"} container rowSpacing={1}>
            <Grid item sm={6} sx={{ display: "flex" }}>
              <Avatar
                className="logo-footer"
                src={require("../static/images/logo/benz-logo.png")}
                alt="m-logo"
              />
              <Avatar
                className="logo-watermark-footer"
                src={require("../static/images/logo/benz-watermark.png")}
                alt="m-logo"
              />
            </Grid>
            <Grid
              item
              sm={6}
              display={"flex"}
              justifyContent={"right"}
              sx={{ marginTop: "16px" }}
            >
              {pages.map((page, index) => {
                return (
                  <>
                    {page.title === "Dashboard" &&
                    user &&
                    user.userType !== "ADMIN" ? (
                      ""
                    ) : (
                      <Typography
                        key={index}
                        className="footer-menu"
                        component={RouterLink}
                        to={page.link}
                        variant="body1"
                        pl={2}
                      >
                        {page.title}
                      </Typography>
                    )}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container className="light-bg footer-copyright-links">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            pt={4}
            pb={4}
            justifyContent="center"
            alignItems="center"
            container
            rowSpacing={1}
          >
            <Grid item sm={8}>
              <Copyright />
            </Grid>
            <Grid item sm={4} sx={{ display: "flex" }} justifyContent="right">
              <Typography
                variant="h6"
                color="#000"
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "500!important",
                  lineHeight: "40px",
                }}
              >
                Contact Us
              </Typography>

              <EmailIcon
                color="primary"
                onClick={() =>
                  (window.location.href = `mailto:csr-mbrdi@mercedes-benz.com`)
                }
                className="icon mail-icon"
              />
              <a
                target="_blank"
                href="https://social.cloud.corpintra.net/groups/csr-mbrdi/"
              >
                <HubIcon color="primary" className="icon hub-icon" />
              </a>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="#000" align="left">
      {"© "}
      {new Date().getFullYear()}
      {
        ". Mercedes-Benz Research and Development India Pvt. Ltd. All Rights Reserved."
      }
    </Typography>
  );
}
