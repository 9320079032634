import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface myProfileState {
  profileDetails: {
    event: [
      {
        id: number;
        title: string;
        imagePath: string;
        imageName: string;
        description: string;
        location: string;
        eventDate: string;
        startTime: string;
        endTime: string;
        qrCode: string;
        totalSlots: number;
        contact: string;
        createdBy: string;
        createdAt: string;
        updatedAt: string;
        eventCategory: {
          id: number;
          name: string;
          createdAt: string;
          updatedAt: string;
          deleted: boolean;
        };
        deleted: boolean;
        eventCategoryName: string;
      }
    ];
    feedback: [
      {
        id: number;
        userId: string;
        rating: number;
        event: {
          id: number;
          title: string;
          description: string;
        };
        createdAt: string;
        imageName: string;
        comments: string;
        deleted: boolean;
      }
    ];
    feedbackImages: [
      {
      id: number;
      feedback: [
        {
          id: number;
          userId: string;
          rating: number;
          event: {
            id: number;
            title: string;
            description: string;
          };
          createdAt: string;
          imageName: string;
          comments: string;
          deleted: boolean;
        }
      ];
      imageName: string;
      deleted: boolean;
      featured: boolean;
      }
    ];
    hours: number;
    events: number;
    user: {
      id: number;
      userId: number;
      userName: string;
      designation: string;
      userType: string;
      image: string;
      department: string;
      userEmail: string;
      imageName: string;
    };
    testimonial: [
      {
        testimonial_id: number;
        userId: number;
        description: string;
      }
    ];
  };
}

const initialState: myProfileState = {
  profileDetails: {
    event: [
      {
        id: 0,
        title: "",
        imagePath: "",
        imageName: "",
        description: "",
        location: "",
        eventDate: "",
        startTime: "",
        endTime: "",
        qrCode: "",
        totalSlots: 0,
        contact: "",
        createdBy: "",
        createdAt: "",
        updatedAt: "",
        eventCategory: {
          id: 0,
          name: "",
          createdAt: "",
          updatedAt: "",
          deleted: false,
        },
        deleted: false,
        eventCategoryName: "",
      },
    ],
    feedback: [
      {
        id: 0,
        userId: "",
        rating: 0,
        event: {
          id: 0,
          title: "",
          description: "",
        },
        createdAt: "",
        imageName: "",
        comments: "",
        deleted: false,
      },
    ],
    feedbackImages: [
      {
      id: 0,
      feedback: [
        {
          id: 0,
          userId: "",
          rating: 0,
          event: {
            id: 0,
            title: "",
            description: ""
          },
          createdAt: "",
          imageName: "",
          comments: "",
          deleted: false
        }
      ],
      imageName: "",
      deleted: false,
      featured: false
      }
    ],
    hours: 0,
    events: 0,
    user: {
      id: 0,
      userId: 0,
      userName: "",
      designation: "",
      userType: "",
      image: "",
      department: "",
      userEmail: "",
      imageName: "",
    },
    testimonial: [
      {
        testimonial_id: 0,
        userId: 0,
        description: "",
      },
    ],
  },
};

const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.profileDetails = action.payload;
    });
  },
});
//export const {fetchEventList} = homeSlice.actions;
export default profileDetailsSlice.reducer;

export const fetchProfileDetails = createAsyncThunk(
  "profile/getProfileDetails",
  async () => {
    const shortId = localStorage.getItem("shortId");
    const response = await axiosInstance.get(
      `api/profile/getProfileDetails/${shortId}`
    );
    return response.data;
  }
);

export const updateProfileImage = createAsyncThunk(
  "profile/updateProfileImage",
  async (imageName: any) => {
    const shortId = localStorage.getItem("shortId");
    const response = await axiosInstance.post(
      `api/profile/updateProfileImage?shortId=${shortId}&imageName=${imageName}`
    );
    return response.data;
  }
);
