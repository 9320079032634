import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import "./home.css";
import CustomizedAccordion from "../accordion";
import { Link as RouterLink } from "react-router-dom";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";
import { fetchFaq } from "../../features/faq/faqSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

export default function Faq() {
  const faq = useAppSelector((state) => state.faq.faqList);
  const dispatch = useAppDispatch();
  const [showFaq, setShowFaq] = React.useState([]);

  logAnalyticsEvent("FAQ & Guidelines");

  React.useEffect(() => {
    dispatch(fetchFaq());
  }, []);

  React.useEffect(() => {
    setShowFaq([...faq].slice(0, 3));
  }, [faq]);

  return (
    <React.Fragment>
      <Container className="dark-bg" disableGutters maxWidth={false}>
        <Box mb={1} sx={{ flexGrow: 1 }}>
          <Grid
            pt={8}
            pb={8}
            justifyContent="center"
            alignItems="center"
            container
            rowSpacing={1}
          >
            <Grid item sm={6} xs={12} textAlign={"center"}>
              <Typography variant="h3">Frequently Asked Questions</Typography>
              <Typography
                mt={2}
                px={2}
                variant="body1"
                sx={{ fontSize: "18px" }}
                className="subtext"
              >
                Explore every aspect of our volunteering program – from getting
                involved to understanding our impact and delving into our
                guidelines.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={4} sx={{ flexGrow: 1 }}>
          <Grid
            justifyContent="center"
            alignItems="center"
            container
            rowSpacing={1}
          >
            <Grid className="custom-accordion" item sm={8}>
              <CustomizedAccordion faq={showFaq} />
            </Grid>
          </Grid>
        </Box>
        <Box pb={8} textAlign={"center"}>
          <Button
            sx={{
              textTransform: "initial",
              fontSize: "18px",
              borderRadius: 0,
              fontWeight: 400,
            }}
            size="large"
            component={RouterLink}
            to="/faq-and-guidelines"
            variant="contained"
          >
            View all FAQs
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
