import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL, URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface checkInState {
    eventCheckin: string
}

const initialState:checkInState = {
    eventCheckin:'',
}

const checkInSlice = createSlice({
    name: 'eventCheckIn',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(checkInForEvent.fulfilled, (state, action) => {
            state.eventCheckin = action.payload
          })
          
      },
}
)

export default checkInSlice.reducer;

export const checkInForEvent = createAsyncThunk('/checkInAttendance', async (code:string) => {
    
    let userId = localStorage.getItem('userId');
    let id;
    if(userId != null)
        id = parseInt(userId);

    const response = await axiosInstance.get(`api/events/checkInAttendance?code=${code}&userId=${id}`)
    return response.data;
})