import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import BarChartStacked from '../components/visualisation/stackedBarChart';
import DoughNutChart from '../components/visualisation/doughNutChart';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Box, MenuItem, Typography, FormControl, NativeSelect, OutlinedInput, Select } from '@mui/material';
import dayjs from 'dayjs';
import { eventYearlyCombinedStats, getMonthlyEventsData } from "../features/common/visualisationStats";
import { userProfile } from "../features/profile/userProfileSlice";
import EventUserStatsTable from "../components/visualisation/eventUserStatsTable";
import Loader from "../components/loader";
import { logAnalyticsEvent } from "../utils/firebaseConfig";

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const yearlyStats = useAppSelector((state) => state.eventStats)
    const [yearlyData, setYearlyData] = React.useState([]);
    const [currentYear, setCurrentYear] = React.useState(dayjs().year())
    const [selectedYearData, setSelectedYearData] = React.useState([])
    const [month, setMonth] = useState(0);
    const [isLoading, setIsLoading] = React.useState<Boolean | null>(null);
    const eventUserStats = useAppSelector((state) => state.eventStats.eventUserRegisteredCheckInStats)

    const handleYearChange = (e: any) => {
        setCurrentYear(e.target.value)
    }

    const setSelectedMonth = (m: any) => {
        setMonth(m)
    }

    useEffect(() => {
        logAnalyticsEvent("Dashboard");
        if (month != 0) {
            let data = {
                month: month,
                year: currentYear
            }
            setIsLoading(true);
            dispatch(getMonthlyEventsData(data)).then((res: any) => {
                if (res.meta.requestStatus == 'fulfilled') setIsLoading(false);
            });
        }
    }, [month])

    React.useEffect(() => {
        const selectedData = yearlyStats?.eventStats?.filter((item: any) => item.year == currentYear)
        setSelectedYearData(selectedData[0]);
    }, [yearlyStats, currentYear])

    React.useEffect(() => {

        dispatch(eventYearlyCombinedStats())
    }, [])

    return (
        <>
            <Grid container spacing={2} sx={{ margin: 4 }}>
                <Grid xs={12} sm={12} md={12} textAlign='center'>
                    <Typography variant='h4' display='inline'>Your Cummulative Dashboard</Typography>
                    <FormControl variant="standard" sx={{ display: 'inline', marginLeft: 2 }}>
                        <Select
                            id="demo-customized-select-native"
                            name="Year"
                            placeholder="Select"
                            value={currentYear}
                            onChange={(e) => handleYearChange(e)}
                            input={<OutlinedInput />}
                            size="small"
                        >
                            {/* <option aria-label="None" value="" /> */}
                            {yearlyStats?.eventStats?.map((lc: any, index: number) => {
                                return (<MenuItem key={index} value={lc.year}>{lc.year}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
            <Grid container spacing={2} sx={{ margin: 2 }}>
                <Grid xs={12} sm={1} md={1}>
                </Grid>

                <Grid xs={12} sm={7} md={7}>
                    <BarChartStacked yearlyStats={selectedYearData} selectedMonth={setSelectedMonth} />
                </Grid>
                <Grid xs={12} sm={3} md={3}>
                    <DoughNutChart yearlyStats={selectedYearData} />
                </Grid>

                <Grid xs={12} sm={1} md={1}>

                </Grid>
            </Grid>


            <Grid mt={4} mb={4} container spacing={2} >

                <Grid xs={12} sm={1} md={1}>
                </Grid>

                <Grid xs={12} sm={10} md={10}>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        eventUserStats && eventUserStats.length > 0 && <EventUserStatsTable eventUserTableData={eventUserStats} />
                    )
                    }

                </Grid>

                <Grid xs={12} sm={1} md={1}>
                </Grid>

            </Grid>

        </>
    )
}

export default Dashboard