import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import UserListing from '../eventDetails/userListing';
import { Typography } from '@mui/material';

interface Column {
  id:  'eventName' | 'registrations' | 'attended';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'eventName', label: 'Event Name', minWidth: 170 },
  { id: 'registrations', label: 'Total Registered', minWidth: 100 },
  { id: 'attended', label: 'Total Attended', minWidth: 100 },

];

export default function EventUserStatsTable( {eventUserTableData }:any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const [openModal, setOpenModal] = React.useState(false);
  const [usersData, setUsersData] = React.useState([] as any)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
      setOpenModal(false);
  }

  const handleUserData = (row:any) => {

    setUsersData(row);
    setOpenModal(true)
  }

return(
  <>
<Typography mt={4} mb={4} variant='body1' ><b>Note:</b> Click on rows to view the registered users and attended users list</Typography>
<Paper >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={1}>
                Event
              </TableCell>
              <TableCell align="center" colSpan={6}>
                User
              </TableCell>
            </TableRow>
            
            <TableRow>
              
              {columns.map((column:any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {eventUserTableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any) => {
                return (
                  <TableRow onClick={() => handleUserData(row)} hover tabIndex={-1} key={row.eventId} sx={{cursor:'pointer'}}>
                
                    {columns.map((column:any) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} >
                          {column.format && typeof value === 'number'
                            ? column.format(value) 
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={eventUserTableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

  {
    openModal && usersData && <UserListing open={openModal} handleClose={handleClose} userData={usersData}/>
  }
</>
  );
}
