import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";


export const getFirbaseConfig = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyB_RhlHivFCdJ7ZEVoDRj-L-JDKvNAN77U",
        authDomain: "csr-website-prod.firebaseapp.com",
        projectId: "csr-website-prod",
        storageBucket: "csr-website-prod.appspot.com",
        messagingSenderId: "3734030714",
        appId: "1:3734030714:web:ba47952ae706add5af2ec3",
        measurementId: "G-RK5L4WGSHV"
      };
    return firebaseConfig;
  };

const app = initializeApp(getFirbaseConfig());
const analytics = getAnalytics(app);

export function logAnalyticsEvent (eventName: any) {
    logEvent(analytics, eventName); 
  }

