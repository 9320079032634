import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface userProfileState {
  profile: {
    id: number;
    userId: number;
    shortId: string;
    employeeId: number;
    userName: string;
    designation: string;
    userType: string;
    image: string;
    department: string;
    userEmail: string;
  };
  certificates: [];
}

const initialState: userProfileState = {
  profile: {
    id: 0,
    userId: 0,
    shortId: "",
    employeeId: 0,
    userName: "",
    designation: "",
    userType: "",
    image: "",
    department: "",
    userEmail: "",
  },
  certificates: [],
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(userProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(getAllCertificates.fulfilled, (state, action) => {
        state.certificates = action.payload;
      });
  },
});
//export const {fetchEventList} = homeSlice.actions;
export default userProfileSlice.reducer;

export const userProfile = createAsyncThunk("user/userInfo", async () => {
  //const response = await axiosInstance.get(`Myprofile/getEvent/1234`)
  const shortId = localStorage.getItem("shortId");
  const response = await axiosInstance.get(`user/userInfo/${shortId}`);

  return response.data;
});

export const getAllCertificates = createAsyncThunk(
  "getAllCertificates/",
  async () => {
    //const response = await axiosInstance.get(`Myprofile/getEvent/1234`)
    const shortId = localStorage.getItem("shortId");
    const response = await axiosInstance.get(
      `api/events/getAllCertificates/${shortId}`
    );

    return response.data;
  }
);

export const getEventCertificate = createAsyncThunk(
  "getEventCertificate/",
  async (eventId: any) => {
    //const response = await axiosInstance.get(`Myprofile/getEvent/1234`)
    const shortId = localStorage.getItem("shortId");
    const response = await axiosInstance.get(
      `api/events/getEventCertificate/${eventId}/${shortId}`
    );

    return response.data;
  }
);
