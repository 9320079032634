import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Avatar, Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import './home.css'
import Carousel from 'react-material-ui-carousel';
import { testimonials } from '../../helpers/constant';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchStories } from '../../features/home/impactStoriesSlice';
import { relative } from 'path';
import UpdateTestimonials from './testimonialsForm';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../loader';

export default function ImpactStories() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = React.useState(true);
    const stories = useAppSelector((state) => state.impactStories.storiesList);
    const user = useAppSelector((state) => state.userProfile.profile);
    const [open, setOpen] = React.useState(false);
    const [testimonial, setTestimonial] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [autoPlay, setAutoPlay] = React.useState(true);

    const openTestimonialForm = () => {
        setMode('add')
        setTitle('Add Testimonial')
        setOpen(true);
    }
    const handlePopupClose = () => {
        setAutoPlay(true);
        setOpen(false);
    }

    const openEditTestimonial = (e: any, testimonial: any) => {
        setAutoPlay(false);
        setMode('edit');
        setTitle('Update Testimonial')
        setTestimonial(testimonial);
        setOpen(true);
    }

    const openDeleteConfirmation = (e: any, testimonial: any) => {
        setAutoPlay(false);
        setMode('delete')
        setTitle('Delete Testimonial')
        setTestimonial(testimonial);
        setOpen(true);
    }
    //Fetch call for Impact Stories
    React.useEffect(() => {
        dispatch(fetchStories()).then((res)=>{
            if(res.meta.requestStatus == 'fulfilled') setIsLoading(false);
        });
    }, [])

    return (
        <React.Fragment>
            <Container>
                <Box mb={4} sx={{ flexGrow: 1 }}>
                    <Grid pt={4} container  alignItems='center'>
                        <Grid item xs={12}>
                            <Typography variant='h2' textAlign='center'>Stories of Impact</Typography>
                        </Grid>
                        {user && user.userType == 'ADMIN' && (
                            <>
                               <Button variant='contained' className='testimonials-add-button' onClick={openTestimonialForm}>+ Add</Button></>
                         )}
                         <Grid item xs={12}>
                        <Typography mt={2} textAlign='center' variant='body1' sx={{ fontSize: '16px' }}>Hear from our dedicated volunteers as they share their experiences and the profound impact they've had through their contributions.</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={4} sx={{ flexGrow: 1 }}>
                    <Grid justifyContent="center" alignItems="center" container rowSpacing={1} columns={{ xs: 1, md: 12 }} >
                        <Grid item sm={12} xs={2} textAlign={'center'} sx={{ marginBottom: '30px' }}>
                            {isLoading?(
                                <Loader />
                            ):(
                                <Carousel
                                autoPlay={autoPlay}
                                height='297px'
                                indicators={false}
                                fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                    // style: {
                                    //     backgroundColor: '#000',
                                    //     borderRadius: 0,
                                    //     opacity: 1,
                                    //     margin: '0 6px',
                                        
                                    // }
                                    className: 'navigation-button'
                                    
                                }}
                                // navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                //     style: {
                                //         bottom: '0',
                                //         top: 'unset',
                                //         right: '-82%',
                                //         height: '70px'
                                //     }
                                // }}
                                NextIcon={<a className="carousel-control-prev" href="#testimonial4" data-slide="prev">
                                    <span className="carousel-control-next-icon"></span>
                                </a>}             // Change the "inside" of the next button to "next"
                                PrevIcon={<a className="carousel-control-next" href="#testimonial4" data-slide="next">
                                    <span className="carousel-control-prev-icon"></span>

                                </a>}

                            >
                                {
                                    stories && stories.map((item, i) =>
                                        <Item key={i} item={item} modalOpenEdit={openEditTestimonial} modalOpenDelete={openDeleteConfirmation} user={user} />
                                    )
                                }
                            </Carousel>
                            )}
                        </Grid>
                    </Grid>
                </Box>

            </Container>
            {open && <UpdateTestimonials open={open} handleClose={handlePopupClose} testimonial={testimonial} mode={mode} title={title} />}
        </React.Fragment>
    )
}

function Item(props: any) {
    return (
        <Stack spacing={2.5}>
        <Paper className='impactStories' sx={{ height: '280px', background: '#0078D6', overflow: 'auto' }} style={{
            backgroundImage: 'url(' + require('../../static/images/impact-bg.png') + ')'
            , mixBlendMode: 'lighten'
        }} >
            <Grid container rowSpacing={1} spacing={{ xs: 2, md: 8 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                <Grid item sm={4} xs={2} md={2.5}  >

                    {/* <Avatar sx={{ width: 200, height: 200, m: 4 }} src={require('../../static/images/spoc/abhiraj-purandre.png')} alt='spoc-image' /> */}
                    <Avatar className='userImage' alt={props.item.userName} src={props.item.userImageName} {...stringAvatar(props.item && props.item.userName)} />

                </Grid>

                <Grid textAlign={'left'} item sm={9} xs={3} md={8}>
                    <Typography mt={2} variant='body1' sx={{typography: { sm: 'body1', xs: 'body2' }, pl: 8, pt: 4, color: '#fff', fontSize: '18px', zIndex: 10, position: 'relative' }}>{`"${props.item.testimonial.description}"`}</Typography>
                    <Typography sx={{typography: { sm: 'body1', xs: 'body2' }, pl: 8, pt: 2, color: '#fff', fontWeight: 600, fontSize: '22px' }} variant='h6'>{props.item.userName}</Typography>
                    <Typography sx={{typography: { sm: 'body1', xs: 'body2' }, pl: 8, color: '#fff', fontSize: '1.125rem' }} variant='body2'>{props.item.department}</Typography>
                </Grid>
                <Grid item sm={1}>
                    {props.user && props.user.userType == 'ADMIN' && (
                        <>
                            <IconButton aria-label="settings" sx={{ color: 'white', marginTop: '4px' }} onClick={(e) => props.modalOpenEdit(e, props.item.testimonial)}>
                                <ModeEditIcon />
                            </IconButton>
                            <IconButton aria-label="settings" sx={{ color: 'white', marginTop: '4px' }} onClick={(e) => props.modalOpenDelete(e, props.item.testimonial)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Grid>
            </Grid>

        </Paper>
        </Stack>

    )
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    if (name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                fontSize: '5em'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    else {
        return "";
    }
}