import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";

interface eventState {
  file: {};
}

const initialState: eventState = {
  file: {},
};

const uploadFileSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(uploadFile.fulfilled, (state, action) => {
        state.file = action.payload;
      })
      .addCase(uploadSingleFile.fulfilled, (state, action) => {
        state.file = action.payload;
      });
  },
});

export default uploadFileSlice.reducer;

// Multiple File Upload
export const uploadFile = createAsyncThunk(
  "sas/uploadMultipleBlob",
  async (data: any) => {
    // get token
    let accessToken = localStorage.getItem("access_token");

    const response = await axios.post(`${APIURL}sas/uploadMultipleBlob`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Authorization": `Bearer ${accessToken}`
      },
    });
    return response.data;
  }
);

// Single File Upload
export const uploadSingleFile = createAsyncThunk(
  "sas/uploadBlob",
  async (data: any) => {
    // get token
    let accessToken = localStorage.getItem("access_token");
    let formData = new FormData();
    formData.append("file", data[0]);

    const response = await axios.post(`${APIURL}sas/uploadBlob`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

//Admin multiple file Upload
export const uploadAdminFiles = createAsyncThunk(
  "feedbackImages/adminUploadImage",
  async (data: any) => {
    let accessToken = localStorage.getItem("access_token");
    const userId = localStorage.getItem("userId");
    const response = await axios.post(
      `${APIURL}feedbackImages/adminUploadImage/user/${userId}/event/${data.id}`,
      data.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);
