import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";
import axios from "axios";
import { URL } from './../../helpers/constant';

interface authState {
    authResponse: {}
}

const initialState:authState = {
    authResponse: {},
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(fetchToken.fulfilled, (state, action) => {
            console.log(action.payload)
            state.authResponse = action.payload;
          })
          .addCase(fetchToken.pending, (state, action) => {
            console.log(action.payload)
          })
          .addCase(fetchToken.rejected, (state, action) => {
            console.log(action.payload)
          })
          
      },
}
)
//export const {fetchEventList} = homeSlice.actions;
export default authSlice.reducer;

export const fetchToken = createAsyncThunk('/fetchToken', async (code:any) => {
    const response = await axiosInstance.get(`token/fetchToken?authCode=${code}`)
    return response.data
})

export const refreshToken = createAsyncThunk('/refreshToken', async (code:any) => {
  const response = await axiosInstance.get(`token/refreshToken`)
  return response.data
})

export const logout = createAsyncThunk('/logout', async ()=>{
  
  // get token
  let refreshToken = localStorage.getItem('refresh_token');
 
  const response = await axios.get(`${URL}/token/logout`, {headers:{ 
  Authorization: `Bearer ${refreshToken}`
  }});
  return response.data;
})