import * as React from "react";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../helpers/functions";
import { useNavigate } from "react-router-dom";
import { getEventCertificate } from "../../features/profile/userProfileSlice";
import { useAppDispatch } from "../../app/hooks";
import { Button } from "@mui/material";

const CompletedRegistration = (props: any) => {
  console.log("props inside completedRegistration",props)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openDetails = (e: any, id: string) => {
    e.preventDefault();
    localStorage.setItem("eventId", id);
    navigate(`/event-details`);
  };

 console.log("props",props.event.title)

  return (
    <>
      <Card>
        <CardMedia
          // sx={{ height: 240 }}
          component="img"
          height="240"
          image={props.event?.eventImages!==null && props.event.eventImages[0]?.imageUrl}
          title="completed registration"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {formatDate(props.event.eventStartDate)}
          </Typography>
          <Typography
            fontWeight={400}
            color="#212529"
            gutterBottom
            variant="h6"
            component="div"
            sx={{ cursor: "pointer" }}
            onClick={(e) => openDetails(e, props.event.id)}
          >
            {props.event.title}
          </Typography>

         
        </CardContent>
      </Card>
    </>
  );
};
export default CompletedRegistration;
