import * as React from "react";
import BannerImage from "../components/profile/banner";
import ProfileDetails from "../components/profile/profileDetails";
import Grid from "@mui/material/Unstable_Grid2";
import Certificates from "../components/profile/certificates";
import "../components/profile/profile.css";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchProfileDetails } from "../features/profile/profileDetailsSlice";
import {
  getAllCertificates,
  userProfile,
} from "../features/profile/userProfileSlice";
import { getAllUserRegistered } from "../features/profile/userRegisteredSlice";
import Loader from "../components/loader";

const Profile = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [registeredData,setRegisteredData] = React.useState([])
  
  const userprofileDetails = useAppSelector(
    (state) => state.profileDetails.profileDetails
  );
  const user = useAppSelector((state) => state.userProfile.profile);
  const certificates = useAppSelector(
    (state) => state.userProfile.certificates
  );
  const userRegistered = useAppSelector(
    (state) => state.getAllUserRegistered.getAllUserRegistered
  );
  console.log("userRegistered",user.id)
  localStorage.setItem('userEmail',user?.userEmail)
  console.log("history")
  React.useEffect(() => {
    dispatch(fetchProfileDetails());
    dispatch(userProfile());
    dispatch(getAllCertificates()).then((res) => {
      if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
    });
  }, []);
  React.useEffect(() => {
    setIsLoading(true)
    if(user.id!==0){
      console.log("inside if id",user.id)
    dispatch(getAllUserRegistered(user.id)).then((res)=>{
      console.log("res",res.payload)
      if (res.meta.requestStatus == "fulfilled") {
        setIsLoading(false);
        setRegisteredData(res.payload)
      }
    })
    }
  }, [user.id]);
  
  

  return (
    <>
      <BannerImage />
      <Grid container spacing={2} sx={{ margin: 0 }}>
        <Grid xs={12} sm={4} md={4} className="container-profile">
          <ProfileDetails
            userDetails={userprofileDetails.user}
            userFeedback={userprofileDetails.feedback}
            hours={userprofileDetails.hours}
            events={userprofileDetails.events}
            user={user}
            testimonial={userprofileDetails.testimonial}
          />
        </Grid>
        <Grid xs={12} sm={8} md={8}>
          {isLoading ? (
            <Loader />
          ) : (
            <Certificates
              data={userprofileDetails.event}
              feedbackData={userprofileDetails.feedbackImages}
              shortId={user.shortId}
              certificates={certificates}
              registeredData = {registeredData}

            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Profile;
