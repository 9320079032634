import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventState {
    eventList: []
    topEvents: []
    exploreEvents:[]
    totalEventCount: 0
}

const initialState:eventState = {
    eventList: [],
    topEvents: [],
    exploreEvents: [],
    totalEventCount: 0
}

const eventListSlice = createSlice({
    name: 'eventList',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(listEvent.fulfilled, (state, action) => {
            state.eventList = action.payload
          })
          .addCase(searchEvent.fulfilled, (state, action) => {
            state.eventList = action.payload
          })
          .addCase(topEvent.fulfilled, (state, action) => {
            state.topEvents = action.payload
          })
          .addCase(exploreEvent.fulfilled, (state, action) => {
            state.exploreEvents = action.payload
          })
          .addCase(totalEventCount.fulfilled, (state, action) => {
            state.totalEventCount = action.payload
          })
          .addCase(searchEventsCount.fulfilled, (state, action) => {
            state.totalEventCount = action.payload
          })

      },
}
)

export default eventListSlice.reducer;

export const listEvent = createAsyncThunk('events/getAllEvents', async (page:any) => {
  let pageNumber;
  if(page == null || page === undefined)
    pageNumber = 1;
  else
    pageNumber = page;
    
    const response = await axiosInstance.get(`api/events/getAllEvents?page=${pageNumber}`)
    return response.data;
})


export const searchEvent = createAsyncThunk('events/searchEvents', async (data:any)=>{
  const response = await axiosInstance.get(`api/events/searchEvents?${data}`)
  return response.data;
})

export const topEvent = createAsyncThunk('events/getTopEvents', async () => {
    const response = await axiosInstance.get(`api/events/getTopEvents`)
    return response.data;
})

export const exploreEvent = createAsyncThunk('events/getExploreEvents', async () => {
  const response = await axiosInstance.get(`api/events/getExploreEvents`)
  return response.data;
})

export const totalEventCount = createAsyncThunk('events/getTotalEventCount', async () => {
  const response = await axiosInstance.get(`api/events/getTotalEventCount`)
  return response.data;
})

export const searchEventsCount = createAsyncThunk('events/searchEventsCount', async (data:any) => {
  const response = await axiosInstance.get(`api/events/searchEventsCount?${data}`)
  return response.data;
})