import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../features/home/homeSlice";
import faqReducer from "../features/faq/faqSlice";
import impactStoriesReducer from "../features/home/impactStoriesSlice";
import feedbackReducer from "../features/home/feedbackSlice";
import eventDetailsReducer from "../features/eventDetails/eventDetailsSlice";
import eventCategoriesReducer from "../features/eventDetails/eventCategorySlice";
import createEventReducer from "../features/eventDetails/createEventSlice";
import profileDetailsReducer from "../features/profile/profileDetailsSlice";
import eventRegistrationReducer from "../features/eventDetails/eventRegistrationSlice";
import getUserReducer from "../features/eventDetails/getUserSlice";
import authReducer from "../features/authenticate/authSlice"
import fileUploadSlice, { uploadFile } from "../features/common/fileUploadSlice";
import spocsReducer from "../features/spocs/spocsSlice";
import eventListReducer from "../features/eventDetails/eventListSlice";
import userProfileReducer from "../features/profile/userProfileSlice";
import highlightsReducer from "../features/home/highlightsSlice";
import feedbackImagesReducer from "../features/eventDetails/uploadFeedbackImagesSlice";
import updateEventReducer from "../features/eventDetails/updateEventSlice";
import eventStatsReducer from "../features/common/visualisationStats";
import checkInReducer from "../features/eventDetails/eventCheckInSlice";
import getAllUserRegisteredReducer from "../features/profile/userRegisteredSlice";


export const store = configureStore({
    reducer: {
        authenticate: authReducer,
        home: homeReducer,
        faq: faqReducer,
        impactStories: impactStoriesReducer,
        feedback: feedbackReducer,
        eventDetails: eventDetailsReducer,
        eventCategories: eventCategoriesReducer,
        createEvent: createEventReducer,
        updateEvent: updateEventReducer,
        profileDetails: profileDetailsReducer,
        eventRegistration: eventRegistrationReducer,
        getUser: getUserReducer,
        spocsList: spocsReducer,
        fileUpload:fileUploadSlice,
        eventList:eventListReducer,
        userProfile:userProfileReducer,
        highlightsImages: highlightsReducer,
        uploadFeedbackImages: feedbackImagesReducer,
        eventStats:eventStatsReducer,
        eventCheckIn:checkInReducer,
        getAllUserRegistered:getAllUserRegisteredReducer
        
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>