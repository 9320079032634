export const pages = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Volunteering Events",
    link: "/volunteering-events",
  },
  {
    title: "My Profile",
    link: "/my-profile",
  },
  {
    title: "FAQ and Guidelines",
    link: "/faq-and-guidelines",
  },
];

export const IMAGEURL =
  "https://mbrdiwebsitepoc.blob.core.windows.net/mbrdi-csr-dev/";

export const banners = [
  {
    name: "Welcome to Rev for Reach",
    image: "banner-1.png",
    description:
      "Your one-stop destination for all things volunteering at MBRDI",
  },
  {
    name: "Welcome to Rev for Reach",
    image: "banner-2.png",
    description:
      "Your one-stop destination for all things volunteering at MBRDI",
  },
  {
    name: "Welcome to Rev for Reach",
    image: "banner-3.png",
    description:
      "Your one-stop destination for all things volunteering at MBRDI",
  },
];

export const eventData = [
  {
    id: 1,
    name: "MobileKids Road Safety Awareness Program",
    slotsAvailable: 27,
    location: "Bangalore",
    eventCategory: "Sustianable Mobility",
    eventDate: "27th August, 2024",
    isVirtual: false,
    registrationStatus: true,
    contact: "janedoe@mercedes-benz.com",
    image: "events-image-4.png",
  },
  {
    id: 2,
    name: "MobileKids Road Safety Awareness Program",
    slotsAvailable: 0,
    location: "Pune",
    eventCategory: "Sustianable Mobility",
    eventDate: "7th October, 2024",
    isVirtual: false,
    registrationStatus: false,
    contact: "janedoe@mercedes-benz.com",
    image: "events-image-1.png",
  },
  {
    id: 3,
    name: "MobileKids Road Safety Awareness Program",
    slotsAvailable: 7,
    location: "Virtual",
    eventCategory: "Sustianable Mobility",
    eventDate: "27th November, 2024",
    isVirtual: true,
    registrationStatus: true,
    contact: "janedoe@mercedes-benz.com",
    image: "events-image-2.png",
  },
];

export const faq = [
  {
    id: 1,
    question: "What kind of CSR activities does MBRDI support?",
    answer:
      "CSR at MBRDI is focused on three areas: sustainable mobility, environmental sustainability, and need-based projects.",
  },
  {
    id: 2,
    question:
      "What types of volunteer activities does MBRDI typically engage in?",
    answer:
      "Within the focus areas, we typically engage in road safety activities at government schools, biodiversity conservation through plantation and awareness drives, and need-based mentoring programs for persons with disabilities, amongst others. Click here to see the available volunteering opportunities and those that have been completed.",
  },
  {
    id: 3,
    question:
      "How can I get updates on upcoming volunteering opportunities and events?",
    answer:
      "Your one-stop destination for all things volunteering at MBRDIYou can go to the “Events” page and navigate through the list to find upcoming volunteering opportunities and events.",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Christy Abraham",
    description:
      '"My time volunteering with CSR initiatives has been transformative. The meticulous planning behind these events allowed us to make a real difference. I have learned a lot and am proud to be part of such noble causes. Thankful for the opportunity to give back to our community."',
    title: "ITS/IT",
    image: "christy-abraham.png",
  },
  {
    id: 2,
    name: "Neha Singla",
    description:
      '"Volunteering for MobileKids was a rewarding experience. Educating school students about road safety was a highlight, with their eagerness to learn being particularly heartwarming. The practical demonstrations were both fun and educational. I look forward to more such involvement."',
    title: "RD/IME",
    image: "neha-singla.png",
  },
];

export const spocs = [
  {
    id: 1,
    name: "Christy Abraham",
    title: "ITS/IT",
    image: "christy-abraham.png",
  },
  {
    id: 2,
    name: "Neha Singla",
    title: "RD/IME",
    image: "neha-singla.png",
  },
  {
    id: 3,
    name: "Harshada Chavan",
    title: "RD/IME",
    image: "harshada-chavan.png",
  },
  {
    id: 4,
    name: "Pratyush Verma",
    title: "RD/IME",
    image: "pratyush-verma.png",
  },
  {
    id: 5,
    name: "Trusna Meher",
    title: "RD/ITC",
    image: "trusna-meher.png",
  },
  {
    id: 6,
    name: "Biren Chapaneri",
    title: "RD/IGS",
    image: "Biren.png",
  },
  {
    id: 7,
    name: "Ashwin Satish",
    title: "RD/IDV",
    image: "ashwin-satish.png",
  },
  {
    id: 8,
    name: "Sindhu Kamat",
    title: "RD/IDS",
    image: "sindhu-kamat.png",
  },
  {
    id: 9,
    name: "Aditi Gaikwad",
    title: "RD/IDP",
    image: "adii-gaikwad.png",
  },
  {
    id: 10,
    name: "Neha Kulkarni",
    title: "RD/IDP",
    image: "neha-kulkarmi.png",
  },
  {
    id: 11,
    name: "Deepak Sukhasare",
    title: "RD/IDP",
    image: "deepak-sukhasare.png",
  },
  {
    id: 12,
    name: "Santu Mondal",
    title: "RD/IDM",
    image: "santu-mondal.png",
  },
];

export const highlight = [
  {
    id: 1,
    image: "gallery-1.png",
  },
  {
    id: 2,
    image: "gallery-2.png",
  },
  {
    id: 3,
    image: "gallery-3.png",
  },
  {
    id: 4,
    image: "gallery-4.png",
  },
  {
    id: 5,
    image: "gallery-5.png",
  },
  {
    id: 6,
    image: "highlights-1.png",
  },
  {
    id: 7,
    image: "highlights-2.png",
  },
  {
    id: 8,
    image: "highlights-3.png",
  },
];

export const filters = [
  "Sustainable Mobility",
  "Environment",
  "Mentorship",
  "Persons with Disability",
  "Bangalore",
  "Pune",
  "Virtual",
  "Registration Open",
  ,
  "Registration Closed",
];

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const LOCALAPIURL = "http://localhost:8080/api/";
export const APIURL = `${process.env.REACT_APP_API_URL}/api/`;
export const URL = process.env.REACT_APP_API_URL;
export const LOCALURL = "http://localhost:8080";
