import React, { useEffect } from "react";
import Compressor from "compressorjs";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Box, Button, FormControl, Grid, InputAdornment } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getImageName } from "../../helpers/functions";
import { useAppDispatch } from "../../app/hooks";
import { createSpoc, fetchSpocsList } from "../../features/spocs/spocsSlice";
import dayjs, { Dayjs } from 'dayjs';
import { uploadFile } from '../../features/common/fileUploadSlice';
import Toaster from "../toaster";

interface file {
    lastModified: number,
    lastModifiedDate: {}
    name: string
    size: number
    type: string
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'visible',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddSpocs = ({ open, handleClose }: any) => {
    const dispatch = useAppDispatch();
    const [imageName, setImageName] = React.useState<Array<file>>([]);
    const [file, setFile] = React.useState([]);
    const [compressedFile, setCompressedFile] = React.useState([] as any);
    const [openToaster, setOpenToaster] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [toastType, setToastType] = React.useState('info');
    const [spocData, setSpocData] = React.useState({
        name: "",
        imageName: "",
        department: "",
        userId: localStorage.getItem('userId'),
        publishDate: ""
    })

    const handleFormChange = (e: any) => {

        setSpocData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))
    }

    const handleToasterClose = () => {
        setOpenToaster(false)
    }
    const compressImage = (file: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.8,
                width: 512,
                height: 512,
                success(result: any) {
                    resolve(new File([result], file.name, { type: result.type }))

                },
                error(err) {
                    console.log(err.message)
                }
            })
        })
    }

    const handleUploadClick = async (e: any) => {
        const compressedImage: any = await compressImage(e.target.files[0])
        setCompressedFile(compressedImage)
        setImageName(e.target.files)
        setFile(e.target.files);
    }

    const createSpocData = (e: any) => {
        e.preventDefault();
        if (file.length > 0) {
            // upload Image
            let formData = new FormData();
            //formData.append('file', file[0]);
            formData.append('file', compressedFile);
            dispatch(uploadFile(formData)).then((res) => {
                if (res.meta.requestStatus == "fulfilled") {
                    let postBody = { ...spocData }
                    postBody.imageName = res.payload;
                    postBody.publishDate = dayjs().format('YYYY-MM-DD')

                    dispatch(createSpoc(postBody)).then((res) => {
                        setOpenToaster(true);
                        dispatch(fetchSpocsList());
                        setToastType('success');
                        setToastMessage("SPOC created successfully!");

                        setTimeout(() => {
                            handleClose();
                        }, 2000)
                        return;
                    })
                }
            })

        }

        else {


            let postBody = { ...spocData }
            postBody.imageName = imageName[0] ? imageName[0].name : '';
            postBody.publishDate = dayjs().format('YYYY-MM-DD')

            dispatch(createSpoc(postBody)).then((res) => {
                setOpenToaster(true);
                dispatch(fetchSpocsList());
                setToastType('success');
                setToastMessage("SPOC created successfully!");
                setTimeout(() => {
                    handleClose();
                }, 2000)
            })

        }

    }

    const deleteImage = (e: any, imageToDelete: string) => {
        e.preventDefault();


        setImageName([]);
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='lg'

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="modal-title">
                    Add Spoc
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ backgroundColor: '#EAEBEB' }}>

                    <Box
                        component="form"
                        noValidate
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <DemoItem label="Name">
                                        <OutlinedInput name="name" placeholder="Add Here" size="small"
                                            value={spocData.name}
                                            onChange={(e) => handleFormChange(e)}
                                        />
                                    </DemoItem>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <DemoItem label="Department">
                                        <OutlinedInput name="department"
                                            value={spocData.department}
                                            placeholder="Add here" id="bootstrap-input" size="small"
                                            onChange={(e) => handleFormChange(e)}
                                        />
                                    </DemoItem>
                                </FormControl>
                            </Grid>



                            <Grid item xs={6}>
                                <FormControl variant="standard" fullWidth>
                                    <DemoItem label="Add Image">
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            tabIndex={-1}
                                            sx={{ textTransform: 'capitalize' }}
                                            className="image-upload-button"
                                        >
                                            Upload Photo
                                            <VisuallyHiddenInput
                                                type="file"
                                                accept="image/*"
                                                multiple={false}
                                                onChange={(e: any) => handleUploadClick(e)}
                                            />
                                        </Button>
                                    </DemoItem>
                                </FormControl>
                                {imageName.length > 0 && <Box sx={{
                                    display: 'flex',
                                    marginTop: '10px'
                                }}
                                    gap={2}
                                >

                                    <OutlinedInput size="small" value={imageName[0]?.name} className="image-name-style" sx={{ color: 'primary' }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <DeleteOutlinedIcon fontSize="small" color="primary" sx={{ cursor: 'pointer' }}
                                                    onClick={(e) => deleteImage(e, imageName[0]?.name)}
                                                />
                                            </InputAdornment>
                                        } />

                                </Box>}


                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center' }}>
                    <Button variant="contained" sx={{ textTransform: 'capitalize' }} className="mui-modal-button" onClick={(e) => createSpocData(e)}>
                        Publish
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Toaster openToaster={openToaster} toastMessage={toastMessage} type={toastType} handleToasterClose={handleToasterClose} />

        </>
    )
}
export default AddSpocs;
