import React, { useEffect } from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import { Box, Button, FormControl, InputAdornment, } from '@mui/material';
import { getImageName } from "../../helpers/functions";
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { fetchSpocsList, updateSpocsList, deleteSpoc } from "../../features/spocs/spocsSlice";
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {uploadFile} from '../../features/common/fileUploadSlice';
import Toaster from "../toaster";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';


interface file {
    lastModified:number,
    lastModifiedDate: {}
    name: string
    size:number
    type:string
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'visible',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface spocsList {
    id: number,
    name: string,
    imageName: string
    imagePath: null,
    imageData: null,
    department: string,
    publishDate: string,
    userId: number
}

const EditSpocs = ({ open, handleClose }: any) => {
    const title = "Edit SPOCs"
    const dispatch = useAppDispatch();
    const spocsList = useAppSelector((state)=>state.spocsList.spocsList)
    const [currentDate, setCurrentDate] = React.useState('');
    const [openToaster, setOpenToaster] = React.useState(false);
    const [imageName, setImageName] = React.useState<Array<file>>([]);
    const [file, setFile] = React.useState([]);
    const [toastMessage, setToastMessage] = React.useState('');
    const [newSpocList, setNewSpocList] = React.useState([]);
    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState(null);
    const [toastType, setToastType] = React.useState('info');


    const updateSpocs = (e:any, data:any) => {
        e.preventDefault();

        if(file.length > 0){
            // upload Image
            let formData = new FormData();
            formData.append('file', file[0]);

            dispatch(uploadFile(formData));
        }

        const dataToPost = {...data}
            for (let key in dataToPost) {
                if(key === 'file'){
                    delete dataToPost[key];
                }
                if(file.length > 0){
                    dataToPost['imageName'] = imageName[0]?.name?imageName[0]?.name:'';
                }
                else if(key === 'imageName'){
                    dataToPost[key] = getImageName(dataToPost[key]);
                } 
                else if(key === 'publishDate'){
                    dataToPost[key] = dayjs().format('YYYY-MM-DD');
                 }
              }
        dispatch(updateSpocsList(dataToPost)).then((res:any)=>{
            if (res.meta.requestStatus == 'fulfilled') {
                dispatch(fetchSpocsList());
                setOpenToaster(true)
                setToastType('success');
                setToastMessage("SPOC updated successfully!");
                
            }
            
        })
            }

    const handleUploadClick = (e: any, row:any) => {
        e.preventDefault()
        const list = JSON.parse(JSON.stringify(spocsList));
        console.log(e.target.files[0].name);
        list.forEach((element:any, index:number)=>{
            if(element.id===row.id){
                element.imageName = e.target.files[0].name;
                element.file = e.target.files;
            }
        })
        setNewSpocList(list);
        setImageName(e.target.files)
        setFile(e.target.files);
        }

    const deleteSpocFromList = (e:any, )=>{
        e.preventDefault();
        dispatch(deleteSpoc(deleteId)).then((res)=>{
            if (res.meta.requestStatus == 'fulfilled') {
                dispatch(fetchSpocsList());
                setToastType('success');
                setToastMessage("SPOC deleted successfully!");
                setOpenToaster(true)
                setTimeout(()=>{
                    setOpenConfirmation(false);
                },2000)
                
            }
            
        })
    }

    const deleteImage = (e:any, imageToDelete:any) => {
        e.preventDefault();
        const list = JSON.parse(JSON.stringify(spocsList));
        list.forEach((element:any, index:number)=>{
            if(element.id===imageToDelete.id){
                element.imageName = '';
                element.file = [];
            }
        })
        setNewSpocList(list);
        setImageName([]);
        setFile([]);
    }

    const updateButton = (params: any) => {
        
        return (
            <Button variant='contained' sx={{ textTransform: 'capitalize', borderRadius: 0, height: '30px', fontSize: '16px' }} onClick={(e)=>updateSpocs(e, params.row)}>Update</Button>
        )
    }
    const deleteButton = (params: any) => {
        return (
            <IconButton aria-label="delete" size="small" color="primary" sx={{backgroundColor: '#fff'}} onClick={(e)=>{setOpenConfirmation(true);setDeleteId(params.row.id)}} >
                <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
        )
    }
    const renderImage = (params:any) => {
        return (
            <>
            <Avatar alt="Remy Sharp" src={params.row?.file?.length?URL.createObjectURL(params.row.file[0]):params.row.imageName} /></>

        )
    }

    const editImageBlock = (params:any)=> {
        return (
            <>
                <OutlinedInput size="small" value={getImageName(params.row.imageName)} className="image-name-block"
                    endAdornment={
                        <InputAdornment position="end">
                            <Button
                            component="label"
                            role={undefined}
                            variant="outlined"
                            tabIndex={-1}
                            sx={{ minWidth: '20px', padding:0, border:'none' }}
                            
                        >
                            <FileUploadOutlinedIcon fontSize="small"/>
                            <VisuallyHiddenInput 
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={(e: any) => handleUploadClick(e, params.row)} 
                            />
                            </Button>
                            <DeleteOutlinedIcon fontSize="small" color="primary" sx={{cursor:'pointer'}} onClick={(e)=>deleteImage(e,params.row)}/>
                        </InputAdornment>
                    } 
                />
                
                
                                        
            </>
        )
    }
    
    const handleToasterClose = () => {
        setOpenToaster(false);
    }
    
    const columns= [
        { field: 'image', headerName: '',resizable:false,filterable: false, sortable: false, renderCell:(params:any)=>renderImage(params), width: 70, editable: false },
        { field: 'name', headerName: 'Name', resizable:false, filterable: false, width: 150, editable: true, headerClassName: 'table-header' },
        { field: 'department', headerName: 'Department',resizable:false, filterable: false, width: 150, editable: false, headerClassName: 'table-header' },
        { field: 'imageName', headerName: 'Image',resizable:false,filterable: false, sortable: false, headerClassName: 'table-header', renderCell:(params:any)=>editImageBlock(params), width: 150, editable: true },
        { field: 'publishDate', headerName: 'Publish Date',resizable:false,filterable: false,headerClassName: 'table-header',
        valueFormatter: (params:any) => dayjs(params.value).format('DD/MM/YYYY'), 
       
          width: 150, editable: false },
        {
            field: 'update', headerName: '',resizable:false,filterable: false, width: 150, editable: true,sortable: false,
            renderCell: (params:any) => updateButton(params)
        },
        {
            field: 'delete', headerName: '',resizable:false,filterable: false, width: 150, editable: true, sortable: false,
            renderCell: (params:any) => deleteButton(params)
        },

    ];

    React.useEffect(()=>{
        setNewSpocList(spocsList)
    },[spocsList])

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='lg'

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="modal-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{backgroundColor:'#EAEBEB'}}>
                    <DataGrid
                        rows={newSpocList}
                        columns={columns}
                        hideFooter={true}
                        disableColumnMenu
                        sx={{
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "inherit" // Or 'transparent' or whatever color you'd like
                            },
                            "& .MuiDataGrid-columnHeaderRow": {
                                height: '20px !important'
                            }
                          }}

                    />
                <Dialog
                                    open={openConfirmation}
                                    onClose={() => setOpenConfirmation(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                   
                                    <DialogContent sx={{ padding: '40px 80px' }}>
                                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '28px', fontWeight: 400, color: '#000', fontFamily: 'mcregular !important', width: '260px', textAlign: 'center' }}>
                                            Are you sure you want to delete this SPOC?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ margin: '25px auto' }}>
                                        <Button variant='contained' onClick={(e) => deleteSpocFromList(e)} autoFocus sx={{ background: '#008dfc', width: '120px' }}>
                                            Yes
                                        </Button>
                                        <Button variant='contained' onClick={() => setOpenConfirmation(false)} sx={{ background: '#008dfc', width: '120px', }}>No</Button>
                                    </DialogActions>
                                </Dialog>
                </DialogContent>
            </BootstrapDialog>

            <Toaster openToaster={openToaster} type={toastType} toastMessage={toastMessage} handleToasterClose={handleToasterClose}/>

        </>
    )
}
export default EditSpocs