import * as React from 'react';
import Container from '@mui/material/Container';
import EventList from '../components/event/eventList';
import { logAnalyticsEvent } from "../utils/firebaseConfig";
export default function Event() {
  logAnalyticsEvent("Events");
  return (
    <Container maxWidth={false} disableGutters>
        <EventList />
    </Container>
  );
}
