import React, { useState } from "react"
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputBase } from "@mui/material";
import './faq.css';

const Search = ({ changeHandler, search }: any) => {

  return (
    <>
      <TextField
        id="input-with-icon-textfield"
        // label="Search with any keyword"
        placeholder="Search with any keyword"
        fullWidth
        onChange={(e) => changeHandler(e.target.value)}
        InputProps={{
          sx: {
            '&::placeholder': {
              color: '#505050',
              opacity: 1, // otherwise firefox shows a lighter color
              fontSize: '18px'
            },
          },
          endAdornment: (
            <InputAdornment position="start">
              <Button
                variant="contained"
                // color="primary" 
                sx={{ mb: 3, borderRadius: "50%", height: "40px", minWidth: "43px", padding: 0 }}
                onClick={search}
              >
                <img width="37%" src={(require('../../static/images/search.png'))} alt="" />
              </Button>
            </InputAdornment>
          ),
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              search();
            }
          },

        }}
        variant="standard"
        sx={{ mt: 1, fontSize: '24px',
          "& input::placeholder": { fontSize: "24px"}
         }}
      />


    </>
  )
}

export default Search