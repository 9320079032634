import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import "./home/home.css";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { formatDate, getImageName } from "../helpers/functions";
import EventForm from "./event/eventForm";
import dayjs from "dayjs";
import { uploadFile } from "../features/common/fileUploadSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { updateEvent } from "../features/eventDetails/updateEventSlice";
import {
  listEvent,
  topEvent,
  totalEventCount,
} from "../features/eventDetails/eventListSlice";
import {
  fetchEventDetails,
  saveId,
} from "../features/eventDetails/eventDetailsSlice";
import { createEventImages } from "../features/eventDetails/createEventSlice";
import Toaster from "./toaster";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export default function EventCard(props: any) {
  console.log("props inside eventCard",props)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const details = useAppSelector((state) => state.eventDetails.details.event);
  const [open, setOpen] = React.useState(false);
  const editEventFormTitle = "Update Event";
  const [editEventData, setEditEventData] = React.useState<any>();
  const [openToaster, setOpenToaster] = React.useState(false);
  const [registrationOpen, setRegistrationOpen] = React.useState(true);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastType, setToastType] = React.useState("");
  const [cardTitle, setCardTitle] = React.useState(false);
  const [upcomingRegistration, setUpcomingRegistration] = React.useState(false);
  const user = useAppSelector((state) => state.userProfile.profile);

  const openDetails = (e: any, id: string) => {
    e.preventDefault();
    localStorage.setItem("eventId", id);
    dispatch(saveId(id));
    navigate(`/event-details/eventId/${id}`);
  };

  const openEditEventForm = (id: string) => {
    dispatch(fetchEventDetails(id));
    setOpen(true);
  };
  const handlePopupClose = () => {
    setOpen(false);
  };
  const handleFormSubmission = (e: any, postBody: any, files: any,id:any) => {
    e.preventDefault();
    let { eventCategoryName, ...postData } = postBody;
    if (editEventData.allslots<editEventData.totalSlots) {
      setToastMessage("Give total slots should be greater than existing total slots.");
      setOpenToaster(true);
      setToastType('error')
      return;
    } 
    setToastType("error");
    if (!files.length) {
      setToastMessage("Feature image is required");
      setOpenToaster(true);
      return null;
    } else if (files.length > 5) {
      setToastMessage("Please upload maximum 5 images");
      setOpenToaster(true);
      return null;
    } else if (!validateEventDates(postBody)) {
      return null;
    }
    postData["imageName"] = "";
    // postData['eventImages'] = files;
    let uploadImages = [
      {
        imageName: String,
        event: {},
      },
    ];
    let formData = new FormData();

   /* for (let index = 0; index < files.length; index++) {
      if (files[index]["size"] !== undefined) {
        formData.append("file", files[index]);
      } else {
        console.log('filess here1',files[index]);
        files[index]["imageName"] = getImageName(files[index]["imageName"]);
      }
    }*/

    for (let index = 0; index < postData.eventImages.length; index++) {
      if (postData.eventImages[index].name) {
        delete postData.eventImages[index].name;
      }
    }

    if (files && files.length >= 1) {
      if (files[0]["size"] !== undefined) {
        postData["imageName"] = files[0]["name"];
      } else {
        postData["imageName"] = getImageName(files[0]["imageName"]);
      }
    }
   
    /*function dataURItoBlob(dataURI:any) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);
  
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
  
      return new Blob([ia], {type:mimeString});
  }*/
    
     console.log("files inside card",files) 
    for (let index = 0; index < files.length; index++) {
      console.log("Files+++",files[index])
      if (files[index]["size"] !== undefined) {
        formData.append("images",files[index], files[index].name);
        console.log("files new",files[index]['name'])
      }
      
      //formData.append("images", files,images[index]['name']);

    }
    //formData.append('images', images)
    //formData.append('images',JSON.stringify(images))
  //formData.append("images", JSON.stringify(images))
    ///formData.append("images", images);
    
    console.log("id in update form",id.length)
    ///formData.append("images", images);
    console.log("postData",postData)
    formData.append("idToDelete",JSON.stringify(id));
    formData.append("event", JSON.stringify(postData));
    ///formData.append('oldFile',)
        //files_modified.map((file:any)=>{'imageName':})
       
        dispatch(updateEvent(formData)).then((res:any) => {
          if (res.meta.requestStatus == "fulfilled") {
        
            
            setOpenToaster(true);
            dispatch(totalEventCount());
            dispatch(listEvent(1));
            dispatch(topEvent());
            setToastType("success");
            setToastMessage("Event updated successfully!");
            handlePopupClose();
          } else {
            setToastType("error");
            setToastMessage("All fields are required!");
            setOpenToaster(true);
            return null;
          }
        });
      
      
    
    console.log("files in update",files)
    
  };

  const validateEventDates = (data: any) => {
    let registrationStartDateTime = new Date(
      data.registrationStartDate + " " + data?.registrationStartTime
    );
    let registrationEndDateTime = new Date(
      data.registrationEndDate + " " + data?.registrationEndTime
    );
    let eventstartDateTime = new Date(
      data.eventStartDate + " " + data?.eventStartTime
    );
    let eventEndDateTime = new Date(
      data.eventEndDate + " " + data?.eventEndTime
    );

    if (
      dayjs(registrationStartDateTime).isAfter(
        dayjs(registrationEndDateTime)
      ) ||
      dayjs(registrationStartDateTime).isSame(dayjs(registrationEndDateTime))
    ) {
      setToastMessage(
        "Registration end date must be greater then registration start date."
      );
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(registrationStartDateTime).isAfter(dayjs(eventstartDateTime)) ||
      dayjs(registrationStartDateTime).isSame(dayjs(eventstartDateTime))
    ) {
      setToastMessage(
        "Registration start date must be before event start date."
      );
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(registrationEndDateTime).isAfter(dayjs(eventstartDateTime)) ||
      dayjs(registrationEndDateTime).isSame(dayjs(eventstartDateTime))
    ) {
      setToastMessage("Registration end date must be before event start date.");
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(eventstartDateTime).isAfter(dayjs(eventEndDateTime)) ||
      dayjs(eventstartDateTime).isSame(dayjs(eventEndDateTime))
    ) {
      setToastMessage("Event start date must be before event end date.");
      setOpenToaster(true);
      return false;
    } else return true;
  };

  const handleToasterClose = () => {
    setOpenToaster(false);
  };

  const ifRegistrationOpen = () => {
    // if (props.event.registrationStatus && (props.event.registrationStatus == true) && (dayjs(props.event.eventEndDate).isBefore(dayjs(new Date())) == false)){
    if (
      props.event.registrationStatus &&
      props.event.registrationStatus == true
    ) {
      setRegistrationOpen(true);
    } else setRegistrationOpen(false); 
  };

  React.useEffect(() => {
    setEditEventData(details);
  }, [details]);

  React.useEffect(() => {
    ifRegistrationOpen();
    let eventRegDate = new Date(
      props.event.registrationStartDate +
        " " +
        props.event.registrationStartTime
    );
    let status = dayjs().isBefore(dayjs(eventRegDate));
    setUpcomingRegistration(status);
  }, []);
   
  return (
    <>
      <Card
        sx={{
          borderRadius: 0,
          cursor: "pointer",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        {user && user.userType == "ADMIN" && !props.event.completedStatus  && (
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                sx={{ background: "white", color: "#0078D6" }}
                onClick={() => openEditEventForm(props.event.id)}
              >
                <ModeEditIcon />
              </IconButton>
            }
            className="card-edit-icon"
          />
        )}
        <CardMedia
          sx={{ height: 240 }}
          image={
            props.event.eventImages && props.event.eventImages.length>0
              ? props.event.eventImages[0].imageUrl
              : require("../static/images/placeholder.jpg")
          }
          title="image"
          onClick={(e: any) => openDetails(e, props.event.id)}
        />

        <Typography
          display={"block"}
          gutterBottom
          variant="overline"
          sx={{ m: "-50px 0 0 15px" }}
          color="#212529"
        >
          <span className="event-category-tag">
            {props.event.eventCategoryName &&
            props.event.eventCategoryName != ""
              ? props.event.eventCategoryName
              : "Event Category"}
          </span>
        </Typography>

        <CardContent onClick={(e: any) => openDetails(e, props.event.id)}>
          <Typography pt={"5px"} variant="body2" color={"text.secondary"}>
            {formatDate(props.event.eventStartDate)}
          </Typography>
          <Typography
            style={{ zIndex: 999 }}
            onMouseEnter={() => setCardTitle(true)}
            onMouseLeave={() => setCardTitle(false)}
            fontWeight={400}
            color="#212529"
            gutterBottom
            variant="h6"
            component="div"
            sx={
              !cardTitle
                ? {
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    cursor: "pointer",
                  }
                : {

                    fontWeight: "bold",
                    overflow: "visible",                   
                  }
            }
          >
            {props.event.title && props.event.title != ""
              ? props.event.title
              : props.event.name}
          </Typography>
          {props.event?.completedStatus ? (
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                paddingX: "3%",
                width: "62%",
              }}
            >
              <Typography
                component="span"
                variant="h6"
                fontSize=".9rem"
                fontWeight={600}
              >
                Event Status:{" "}
              </Typography>
              <Typography component="span" variant="subtitle1" fontSize=".9rem">
                {" "}
                Completed
              </Typography>
            </Paper>
          ) : (
            <Typography
              variant="body1"
              display={"flex"}
              color={
                props.event.registrationStatus && props.event.totalSlots > 0
                  ? "#008DFC"
                  : "error.main"
              }
            >
              {!props.event.upcomingRegistration && (
                <>
                  <Typography>
                    {props.event.totalSlots && props.event.totalSlots != ""
                      ? props.event.totalSlots
                      : 0}{" "}
                    Slots Available{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    |
                  </Typography>
                </>
              )}
              {props.event.registrationStatus && props.event.totalSlots > 0 ? (
                <Typography className="hyperlink">Register Now</Typography>
              ) : props.event.upcomingRegistration ? (
                <Typography color={"#008DFC"}>Registrations will open soon</Typography>
              ) : (
                <Typography>Registration Closed</Typography>
              )}
            </Typography>
          )}

          <Box display="inline-flex" gap={2} py={2}>
            <LocationOnIcon sx={{ mb: "-4px", fontSize: "1.8rem" }} />
            <Typography
              variant="body1"
              color="#212529"
              sx={{ fontSize: "1.125rem", fontWeight: "400!important" }}
            >
              {props.event.location}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Toaster
        openToaster={openToaster}
        type={toastType}
        toastMessage={toastMessage}
        handleToasterClose={handleToasterClose}
      />
      {open && details.id !== 0 && (
        <EventForm
          open={open}
          handleClose={handlePopupClose}
          title={editEventFormTitle}
          data={editEventData}
          handleChange={setEditEventData}
          submitForm={handleFormSubmission}
        />
      )}
    </>
  );
}