import * as React from "react";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../helpers/functions";
import { useNavigate } from "react-router-dom";
import { getEventCertificate } from "../../features/profile/userProfileSlice";
import { useAppDispatch } from "../../app/hooks";
import { Button } from "@mui/material";

const CompletedEvents = (props: any) => {
  console.log("props.event in CompletedEvents",props.event,Object.keys(props.event).length)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openDetails = (e: any, id: string) => {
    e.preventDefault();
    localStorage.setItem("eventId", id);
    navigate(`/event-details`);
  };

  const viewCertificate = () => {
    dispatch(getEventCertificate(props.event.id)).then((data: any) => {
      if (data.meta.requestStatus == "fulfilled") {
        let url = process.env.REACT_APP_API_URL;
        url = data?.payload[0];
        window.open(url, "_blank");
      }
    });
  };

  return (
    <>
      <Card>
        <CardMedia
          // sx={{ height: 240 }}
          component="img"
          height="240"
          image={props?.event?.eventImages?.length>0?props?.event?.eventImages[0].imageUrl:""}
          title="completed events"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {formatDate(props.event.eventStartDate)}
          </Typography>
          <Typography
            fontWeight={400}
            color="#212529"
            gutterBottom
            variant="h6"
            component="div"
            sx={{ cursor: "pointer" }}
            onClick={(e) => openDetails(e, props.event.id)}
          >
            {props.event.title}
          </Typography>

          <Button
            aria-label="view certificates"
            variant="contained"
            sx={{
              textTransform: "capitalize",
              borderRadius: 0,
              boxShadow: "none",
              fontSize: "18px",
              fontWeight: "400",
            }}
            onClick={viewCertificate}
          >
            View Certificate
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
export default CompletedEvents;
