import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface homeState {
    eventList: [],
    socialImpact: {
      csrUser: any,
      hours: any,
      events: any
    }
}

const initialState:homeState = {
    eventList: [],
    socialImpact: {
      csrUser: 0,
      hours: 0,
      events: 0
    }
}


const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(fetchPosts.fulfilled, (state, action) => {
            state.eventList = action.payload;
          })

          .addCase(socialImpactCount.fulfilled, (state, action) => {
            state.socialImpact = action.payload;
          })
          
      },
}
)

export default homeSlice.reducer;

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
    const response = await axiosInstance.get(`/api/events/getAllEvents`)
    return response.data
  })

export const socialImpactCount = createAsyncThunk('events/eventStats', async () => {
    const response = await axiosInstance.get(`/api/events/eventStats`)
    return response.data
})