import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventDetailsState {
  details:{ 
    event:{
    contact: string;
    createdAt: string;
    createdBy: string;
    deleted: boolean;
    description: string;
    eventEndTime: string;
    eventStartDate: string;
    eventEndDate: string;
    id: number;
    imageName: string | null;
    imagePath: string | null;
    location: string;
    qrCode: string;
    eventStartTime: string;
    title: string;
    totalSlots: number;
    allslots: number;
    updatedAt: string;
    code: string;
    userRegistered: Boolean;
    completedStatus: Boolean;
  };
  mailSent: null;
  feedbackImages:[]
}
eventId: string | null;
}

const initialState: eventDetailsState = {
  details: {
    event:{
    id: 0,
    contact: "",
    createdAt: "",
    createdBy: "",
    deleted: false,
    description: "",
    eventStartTime: "",
    eventEndTime: "",
    eventStartDate: "",
    eventEndDate: "",
    imageName: null,
    imagePath: null,
    location: "",
    qrCode: "",
    title: "",
    totalSlots: 0,
    allslots: 0,
    updatedAt: "",
    code: "",
    userRegistered: false,
    completedStatus: false,
  },
  feedbackImages:[],
  mailSent: null
},
eventId: null,
};

const eventDetailsSlice = createSlice({
  name: "eventDetails",
  initialState,
  reducers: {
    saveId: (state, action) => {
      state.eventId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEventDetails.fulfilled, (state, action) => {
      state.details = action.payload;
    });
  },
});
export const { saveId } = eventDetailsSlice.actions;

export default eventDetailsSlice.reducer;

export const fetchEventDetails = createAsyncThunk(
  "events/getEventById",
  async (id: string | null) => {
    const userId = localStorage.getItem("userId");
    const response = await axiosInstance.get(
      `api/events/getEventById/${id}?userId=${userId}`
    );
    return response.data;
  }
);

export const cancelEvent = createAsyncThunk(
  "/cancelEvent",
  async (cancelData:any) => {
    const userId = localStorage.getItem("userId");
    let data = cancelData.comments;
    const response = 
      await axiosInstance.delete(`api/events/admin/cancelEventById/${cancelData.id}?userId=${userId}`,{data});
      
    return response.data;
  }
);
export const sendEmailToParticipants = createAsyncThunk(
  "/sendMail",
  async (id: string | null) => {
    const response = await axiosInstance.get(
      `api/events/admin/sendThankYouMail/${id}`
    );

    return response.data;
  }
);
export const getUsersList = createAsyncThunk(
  "/eventUserStats",
  async (eventId: string | null) => {
    const userId = localStorage.getItem("userId");
    const response = await axiosInstance.get(
      `api/events/admin/eventUserStats/${eventId}`
    );

    return response.data;
  }
);
