import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Checkbox, DialogContentText, FormControlLabel } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../app/hooks";
import {
  deleteHighlightsImage,
  editHighlightsImageList,
  fetchHighlightsImageList,
} from "../../features/home/highlightsSlice";
import Toaster from "../toaster";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function UpdateHighlights({
  open,
  handleClose,
  imageList,
}: any) {
  const dispatch = useAppDispatch();
  const [featuredImageList, setFeaturedImageList] = React.useState<
    Array<object>
  >([]);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openToaster, setOpenToaster] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [imageIdToDelete, setImageIdToDelete] = React.useState();
  const [toastType, setToastType] = React.useState("info");

  const publishHighlights = (e: any) => {
    dispatch(editHighlightsImageList(featuredImageList)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchHighlightsImageList());
        setOpenToaster(true);
        setToastType("success");
        setToastMessage("Highlights updated successfully!");
        setTimeout(() => {
          handleClose(false);
        }, 2000);
      }
    });
  };

  const deleteImageFromList = (id: number) => {
    let imageList = structuredClone(featuredImageList);
    imageList.forEach((item: any) => {
      if (item.id === imageIdToDelete) {
        item.deleted = true;
      }
    });
    setFeaturedImageList(imageList);
    setOpenConfirmation(false);
    // dispatch(deleteHighlightsImage(id)).then((res)=>{
    //     if(res.meta.requestStatus === 'fulfilled'){
    //         let list = imageList.filter((list:any) => list.id !== id);
    //         setFeaturedImageList(list);
    //         setOpenConfirmation(false);
    //         dispatch(fetchHighlightsImageList());
    //         setToastMessage('Highlights Image deleted successfully');
    //         setOpenToaster(true);
    //     }
    // });
  };

  const handleFeatured = (e: any, id: number) => {
    let imageList = structuredClone(featuredImageList);
    imageList.forEach((item: any) => {
      if (item.id === id) {
        item.featured = e.target.checked;
      }
    });
    setFeaturedImageList(imageList);
  };

  const handleToasterClose = () => {
    setOpenToaster(false);
  };

  React.useEffect(() => {
    let copyList = [...imageList];
    setFeaturedImageList(copyList);
  }, []);
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="modal-title"
        >
          Update Highlights
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ height: "calc(100% - 40px)" }}>
          <ImageList sx={{ width: 750, height: 300 }} cols={3} gap={15}>
            {featuredImageList.map(
              (item: any, index: number) =>
                item.deleted !== true && (
                  <>
                    <ImageListItem key={index}>
                      <img
                        srcSet={item.imageName}
                        src={item.imageName}
                        alt="featured"
                        loading="lazy"
                      />
                      <ImageListItemBar
                        // title={item.title}
                        subtitle={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.featured}
                                onChange={(e) => handleFeatured(e, item.id)}
                              />
                            }
                            label="Featured"
                          />
                        }
                        position="below"
                        actionIcon={
                          <IconButton
                            sx={{ paddingTop: "15px" }}
                            onClick={() => {
                              setOpenConfirmation(true);
                              setImageIdToDelete(item.id);
                            }}
                          >
                            <DeleteIcon color="primary" />
                          </IconButton>
                        }
                      ></ImageListItemBar>
                    </ImageListItem>
                    <Dialog
                      open={openConfirmation}
                      onClose={() => setOpenConfirmation(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        elevation: 3,
                        style: {
                          //   boxShadow: 'none'
                        },
                      }}
                      BackdropProps={{
                        style: { backgroundColor: "transparent" },
                      }}
                    >
                      <DialogContent sx={{ padding: "40px 80px" }}>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{
                            fontSize: "28px",
                            fontWeight: 400,
                            color: "#000",
                            fontFamily: "mcregular !important",
                            width: "260px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this Image?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions sx={{ margin: "25px auto" }}>
                        <Button
                          variant="contained"
                          onClick={() => deleteImageFromList(item.id)}
                          autoFocus
                          sx={{ background: "#008dfc", width: "120px" }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setOpenConfirmation(false)}
                          sx={{ background: "#008dfc", width: "120px" }}
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )
            )}
          </ImageList>
        </DialogContent>
        <DialogActions sx={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={(e) => publishHighlights(e)}
            className="mui-modal-button"
          >
            Publish
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Toaster
        openToaster={openToaster}
        type={toastType}
        toastMessage={toastMessage}
        handleToasterClose={handleToasterClose}
      />
    </>
  );
}
