import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { banners } from '../../helpers/constant';
import { Link as RouterLink } from 'react-router-dom';
import './home.css';

function Item(props:any)
{
    return (
        <Paper className='paper-banner' style={{backgroundImage: 'url(' + require('../../static/images/'+props.item.image) + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <Typography variant='h2' className="home-page-banner-heading" >{props.item.name}</Typography>
            <Typography mt={2} variant='h6' className="home-page-banner-subheading" sx={{pl: 10, color: '#fff', width: '360px', lineHeight:'1.1', fontWeight:'400!important', fontSize:'1.5rem!important'}}>{props.item.description}</Typography>
            <Button size="large" component={RouterLink} to='/volunteering-events' variant="contained" sx={{ mt:2.2, ml:10, textTransform:'capitalize', fontWeight: 400, fontSize: '18px', borderRadius:'0'}}>
                Volunteer Now!
            </Button>
        </Paper>
    )
}

export default function Slider() {

    return(
        <React.Fragment>
            <Carousel
            autoPlay={true}
            swipe={false}
            indicators={true}
            navButtonsAlwaysInvisible={true}
            indicatorContainerProps={{
                className:'custom-indicator',
                style: {
                  zIndex: 1,
                  position: "relative",
                  textAlign: 'right',
                  paddingRight: '85px',
                  bottom: '150px'
                  
                  
                }
              }}
            indicatorIconButtonProps={{
                style: {
                    padding: '2px',
                    marginRight:'8px',
                    color: '#c0c0c0'
                }
            }}
            activeIndicatorIconButtonProps={{
                style:{
                    border:'1px solid #fff',
                    color: '#fff'
                }
            }}
            >
                {
                    banners.map( (item, i) => 
                    <Item key={i} item={item} /> 
                    )
                }
            </Carousel>
        </React.Fragment>
    )
}
