import { useEffect, useState } from "react";
import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button, TextField, Typography } from '@mui/material';
import CustomizedLightAccordions from './lightAccordian';
import './faq.css';
import Search from "./search";
import { fetchFaq, addFaq } from '../../features/faq/faqSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { search } from "../../helpers/functions";
import Modal from '@mui/material/Modal';
import Loader from "../loader";
import Toaster from "../toaster";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -70%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #c0c0c0',
    boxShadow: 24,
    p: 4,
};

const FaqAnswers = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const faq = useAppSelector((state) => state.faq.faqList);
    const dispatch = useAppDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [loadMoreButton, setLoadMoreButton] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openToaster, setOpenToaster] = React.useState(false);
    const [question, setQuestion] = React.useState("");
    const [answer, setAnswer] = React.useState("");
    const [toastMessage, setToastMessage] = React.useState('');
    const user = useAppSelector((state) => state.userProfile.profile)

    //Search Funtionality
    const filteredList = () => {
        return search(faq, searchQuery)
    }
    const searchFaq = () => {
        setLoadMoreButton(false)
        setShowFaq(filteredList());
    }

    // Load More
    // const noOfFaqs = 4;
    const [showFaq, setShowFaq] = useState([]);
    const loadMore = () => {
        console.log("Loading...")
        // Remove comments when need to add functionality to load more.
        //setShowFaq([...showFaq, ...faq.slice(showFaq.length, showFaq.length + noOfFaqs)]);
    };


    useEffect(() => {
        //setShowFaq([...faq].slice(0, noOfFaqs))
        console.log("state updated!")
        setShowFaq(faq);
    }, [faq])

    // Dispatch fetch FAQ 


    useEffect(() => {
        if(!faq.length) {
            dispatch(fetchFaq()).then((res) => {
                if (res.meta.requestStatus == 'fulfilled') setIsLoading(false)
            })
        } else {
            setIsLoading(false)
        }
    }, [])

    const handleToasterClose = () => {
        setOpenToaster(false)
    }

    const handleTextChange = (event: any, type: string) => {
        console.log("Event", event)
        if (type == 'answer') {
            setAnswer(event.target.value);
        }
        else if (type == 'question') {
            setQuestion(event.target.value);
        }
    }

    const handleSubmit = () => {

        let data = {
            question: question,
            answer: answer
        }
        if (question === '' || answer === '') {
            setToastMessage('Both the fields are mandatory');
            setOpenToaster(true)
            return null;
        }

        dispatch(addFaq(data)).then(() => {
            dispatch(fetchFaq());
        });
        handleClose()
        setToastMessage('FAQ added successfullt!')
        setOpenToaster(true)

    }


    return (
        <>
            <React.Fragment>
                <Container className='light-bg' disableGutters maxWidth={false}>
                    <Box mb={1} sx={{ flexGrow: 1 }}>
                        <Grid pt={4} pb={4} container rowSpacing={1} >
                            <Grid item sm={2} ></Grid>
                            <Grid item sm={8} textAlign={'left'} >
                                <Typography variant='h2'>Frequently Asked Questions</Typography>
                                <Search changeHandler={setSearchQuery} search={searchFaq} />
                            </Grid>
                            <Grid item sm={2}>
                                {user && user.userType == 'ADMIN' &&
                                    <Button variant="contained" disableElevation sx={{ textTransform: 'capitalize', marginLeft: 4, padding: 1, background: '#008DFC', borderRadius:0, fontSize:'18px', fontWeight:600 }} onClick={handleOpen}>+Add</Button>}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={4} pb={8} sx={{ flexGrow: 1 }}>
                        <Grid justifyContent="center" alignItems="center" container rowSpacing={1} >
                            <Grid className='custom-accordion' item sm={8} >
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <CustomizedLightAccordions faq={showFaq} />
                                )}

                            </Grid>
                        </Grid>
                    </Box>
                    {/* {showFaq.length < faq.length && loadMoreButton && ( */}
                    {loadMoreButton && (
                        <Box pb={8} textAlign={'center'}>
                            <Button sx={{ textTransform: 'capitalize', borderRadius: 0, fontSize: '18px', fontWeight: '400!important' }} size='large' variant="contained" color="primary" onClick={loadMore}>Load More</Button>
                        </Box>
                    )}
                </Container>
            </React.Fragment>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <TextField fullWidth required label="Question" inputProps={{ minLength: 20 }} defaultValue="" onChange={(event) => handleTextChange(event, "question")} />
                    <TextField
                        id="outlined-multiline-static"
                        label="Answer"
                        required
                        multiline
                        fullWidth
                        inputProps={{ minLength: 20 }}
                        rows={4}
                        defaultValue=""
                        onChange={(event) => handleTextChange(event, "answer")}
                        sx={{ mt: '20px' }}
                    />

                    <Button sx={{ mt: '20px', borderRadius: 0 }} variant="contained" disableElevation onClick={handleSubmit}>Add</Button>
                    <Button sx={{ mt: '20px', borderRadius: 0, ml: 2 }} variant="contained" disableElevation onClick={handleClose}>Cancel</Button>
                </Box>
            </Modal>
            <Toaster openToaster={openToaster} toastMessage={toastMessage} handleToasterClose={handleToasterClose}/>

        </>
    )
}
export default FaqAnswers