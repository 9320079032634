import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//import { eventData } from '../../helpers/constant';
import EventCard from "../card";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { topEvent } from "../../features/eventDetails/eventListSlice";
import "./home.css";
import Loader from "../loader";

export default function VolunteeringEvent() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [eventData, setEventData] = React.useState<any>([]);
  const eventInitialData = useAppSelector((state) => state.eventList.topEvents);
  //useAppSelector((state) => state.home.eventList.slice(0,3));
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(topEvent()).then((res) => {
      if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
    });
  }, []);
  React.useEffect(() => {
    // let getData = eventInitialData.filter((data: any) => {
    //   if (data?.completedStatus == false && data?.registrationStatus == true) {
    //     return data;
    //   }
    // });
    setEventData(eventInitialData);
  }, [eventInitialData]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Typography
          className="change-heading-for-mobile"
          mt={4}
          sx={{ paddingX: "50px" }}
          variant="h2"
          align="center"
        >
          Explore Volunteering Events
        </Typography>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box mt={4} mb={4} sx={{ flexGrow: 1 }}>
              {eventData.length == 0 && (
                <Typography variant="body1" textAlign={"center"} mb={2}>
                  No events available to register!
                </Typography>
              )}
              <Grid container spacing={{ md: 3 }}>
                {eventData.length > 0 &&
                  eventData?.map((event: any, index: any) => (
                    <Grid key={index} item xs={12} sm={4} md={4}>
                      <EventCard key={index} event={event} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Box mb={8} textAlign={"center"}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  fontSize: "18px",
                  boxShadow: "none",
                  borderRadius: 0,
                  fontWeight: 400,
                }}
                component={RouterLink}
                to="/volunteering-events"
                size="large"
                variant="contained"
              >
                See More
              </Button>
            </Box>
          </>
        )}
      </Container>
    </React.Fragment>
  );
}
