import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Rating,
  Stack,
  Typography,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import "./eventDetails.css";
import dayjs from "dayjs";
import { formatDate } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { registerForEvent } from "../../features/eventDetails/eventRegistrationSlice";
import {
  cancelEvent,
  getUsersList,
  sendEmailToParticipants,
} from "../../features/eventDetails/eventDetailsSlice";
import {
  listEvent,
  totalEventCount,
} from "../../features/eventDetails/eventListSlice";
import { fetchEventDetails } from "../../features/eventDetails/eventDetailsSlice";
import QRCode from "react-qr-code";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Toaster from "../toaster";
import UserListing from "./userListing";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { esES } from "@mui/x-data-grid";


const Item = ({ data, title, icon }: any) => {
  return (
    <>
      <Paper elevation={0}>
        <Typography variant="body1" fontSize="18px">
          {title}
        </Typography>
        {title === "Contact:" ? (
          <Typography variant="body1" fontSize="16px">
            <a
              href="#"
              onClick={() => (window.location.href = `mailto:${data}`)}
            >
              {data}
            </a>
          </Typography>
        ) : (
          <Typography variant="body1" fontSize="16px">
            {data}
          </Typography>
        )}
      </Paper>{" "}
    </>
  );
};

const DetailsGrid = ({ eventData }: any) => {
  console.log("eventData", eventData);
  const dispatch = useAppDispatch();
  // const pastEvent = dayjs(eventData.eventEndDate).isBefore(dayjs(new Date()));
  //const usersList = useAppSelector((state)=>state.)
  const [openToaster, setOpenToaster] = useState(false);
  const [toastType, setToastType] = React.useState("");
  const [value, setValue] = useState<number | null>(1);
  const [slotsAvailable, setSlotsAvailable] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [checked, setChecked] = useState(false);
  const [volunteerChecked, setVolunteerChecked] = useState(false);
  const [disableRegistrationButton, setDisableRegistrationButton] =
    useState(false);
  const [disableEmailButton, setDisableEmailButton] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [registrationOpen, setRegistrationOpen] = React.useState(true);
  const [upcomingRegistration, setUpcomingRegistration] = React.useState(false);
  const [openRegisterationDialog, setOpenRegisterationDialog] = useState(false);
  const handleCloseDialog = () => setOpenRegisterationDialog(false);
  const handleOpenDialog = () => setOpenRegisterationDialog(true);
  const [showButtonLoader, setShowButtonLoader] = React.useState(false);
  const [viewButtonLoader, setViewButtonLoader] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [openEventCancellationDialog, setOpenEventCancellationDialog] =
  React.useState(false);
    const [openCancellationReasonDialog, setOpenCancellationReasonDialog] =
    useState(false);
    const mailSent = useAppSelector((state) => state.eventDetails.details.mailSent);
  const handleEventCancellationCloseDialog = () =>{
  setOpenEventCancellationDialog(false);
  setOpenCancellationReasonDialog(false);
}
  const handleEventCancellationOpenDialog = () =>
    setOpenEventCancellationDialog(true);

  const pastEvent = () => {
    let eventDate = new Date(
      eventData.eventEndDate + " " + eventData?.eventEndTime
    );
    let status = dayjs(eventDate).isBefore(dayjs());
    return status;
  };
  //const user = useAppSelector((state) => state.getUser.userId)
  const user = useAppSelector((state) => state.userProfile.profile);
  dayjs.extend(customParseFormat);
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (
      eventData &&
      eventData.userRegistered &&
      eventData.userRegistered === true
    ) {
      setDisableRegistrationButton(true);
    } else if (eventData && eventData.registrationStatus == false) {
      setDisableRegistrationButton(true);
    } else {
      setDisableRegistrationButton(false);
    }
    if (eventData) {
      let eventRegStartDateTime = new Date(
        eventData.registrationStartDate + " " + eventData.registrationStartTime
      );
      console.log("eventRegStartDateTime ", eventRegStartDateTime);
      let status = dayjs(new Date()).isBefore(dayjs(eventRegStartDateTime));
      setUpcomingRegistration(status);
      let registrationEndDateTime = new Date(
        eventData.registrationEndDate + " " + eventData.registrationEndTime
      ); 
      if (
        eventData.totalSlots > 0 &&
        eventData.registrationStatus == true &&
        dayjs(registrationEndDateTime).isBefore(dayjs(new Date())) == false
      ) {
        setRegistrationOpen(true);
      } else setRegistrationOpen(false);
      if (eventData.totalVolunteers == 0 || mailSent==true ) {
        setDisableEmailButton(true);
      }
    }
  }, [eventData]);

  const handleToasterClose = () => {
    setOpenToaster(false);
  };

  const registerEvent = (e: any) => {
    e.preventDefault();
    setShowButtonLoader(true);
    let requestBody = {
      eventId: eventData.id,
      eventName: eventData.title,
      userId: user.id,
      //   createdAt: eventData.createdAt,
      //   createdAt: new Date(),
      deletedAt: "",
      mailSent: "false",
    };
    try {
      dispatch(registerForEvent(requestBody)).then((res) => {
        if (res.meta.requestStatus == "fulfilled") {
          setSlotsAvailable(res.payload.slotsAvailable);
          setToastMessage("Registered for the event successfully!");
          setShowButtonLoader(false);
          setOpenToaster(true);
        }
      });
      setDisableRegistrationButton(true);
    } catch (error) {
      setShowButtonLoader(false);
      console.log(error);
    }
    handleCloseDialog();
  };

  const onCancelEvent = (e: any) => {
   // setShowButtonLoader(true);
    setOpenCancellationReasonDialog(true)
  };
  const onCancelReasonEvent = (e: any) => {
    setShowButtonLoader(true);
    let comments = {
      comments :reason
      }
    let cancelData = {
      id:eventData.id,
      comments
    }
    if(reason.length>0){
      try {
        dispatch(cancelEvent(cancelData)).then((res) => {
          if (res.meta.requestStatus == "fulfilled") {
            setToastMessage("Event canceled successfully!");
            setShowButtonLoader(false);
            setOpenToaster(true);
            dispatch(totalEventCount());
            dispatch(listEvent(1));
          }
        });
        setDisableCancelButton(true);
      } catch (error) {
        setShowButtonLoader(false);
        console.log(error);
      }
      handleEventCancellationCloseDialog();
    }else{
    setToastMessage("Please add the reason for cancellation");
    setOpenToaster(true);
    setShowButtonLoader(false);
    setToastType("error");  
    }
  
   
  };

  const formatTime = (time: any) => {
    const day = dayjs(time, "HH:mm:ss");
    return day.format("hh:mm a");
  };

  const handleChange = (event: any) => {
    if (event.target.name == "checkboxFirst") {
      if (event.target.checked === true) {
        window.open(require("../../static/docs/Data Processing Consent.pdf"));
      }
      setChecked(event.target.checked);
    } else {
      if (event.target.checked === true) {
        window.open(require("../../static/docs/Volunteering Guidelines.pdf"));
      }
      setVolunteerChecked(event.target.checked);
    }
  };
  /*const handleVolunteerChange = (event: any) => {
        console.log('event in handleChange',event)
        if(event.target.checked === true){
            window.open((require("../../static/docs/Declaration of Consent for Recordings and Publication of Images 2.pdf")));
        }
        setVolunteerChecked(event.target.checked);
    }*/

  const sendMail = (e: any) => {
    e.preventDefault();
    dispatch(sendEmailToParticipants(eventData.id)).then((res) => {
      if (res.meta.requestStatus == "fulfilled") {
        setToastMessage("Mail sent successfully  to the participants!");
        setOpenToaster(true);
        setDisableEmailButton(true);
      }
    });
  };

  const onViewUsers = (e: any) => {
    e.preventDefault();
    setViewButtonLoader(true);

    dispatch(getUsersList(eventData.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setOpenModal(true);
        setViewButtonLoader(false);
        setUsersData(res.payload[0]);
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Stack spacing={2.5}>
        {eventData?.completedStatus && (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              paddingX: "5%",
              paddingY: "3%",
              width: "72%",
            }}
          >
            <Typography component="span" variant="h6" fontWeight={600}>
              Event Status:{" "}
            </Typography>
            <Typography component="span" variant="subtitle1">
              {" "}
              Completed
            </Typography>
          </Paper>
        )}
        <div className="event-details-section">
          <div className="icon-div">
            <EventAvailableOutlinedIcon fontSize="large" />
          </div>
          {/* <Item data = {eventData.eventDate} title="Event Info:"/> */}
          <Paper elevation={0}>
            <Typography variant="body1" fontSize="18px">
              Event Info:
            </Typography>
            <Typography variant="body1" fontSize="16px">
              {eventData?.eventStartDate != eventData.eventEndDate
                ? formatDate(eventData?.eventStartDate) +
                  "  " +
                  formatTime(eventData.eventStartTime) +
                  " till " +
                  formatDate(eventData.eventEndDate) +
                  "  " +
                  formatTime(eventData.eventEndTime)
                : (<div>{formatDate(eventData?.eventStartDate)}<br/>
        
                  {formatTime(eventData.eventStartTime)} - {formatTime(eventData.eventEndTime)}</div>)} 
                  
            </Typography>
            {/* <Typography variant="body1" fontSize="16px">
              {formatTime(eventData.eventStartTime)} -{" "}
              {formatTime(eventData.eventEndTime)}
            </Typography> */}
          </Paper>
        </div>
        <div className="event-details-section">
          <div className="icon-div">
            <RoomOutlinedIcon fontSize="large" />
          </div>
          {/* <Item data={eventData.location} title="Venue:" /> */}
          <Paper elevation={0}>
            <Typography variant="body1" fontSize="18px">
              Venue:
            </Typography>

            <Typography variant="body1" fontSize="16px">
              {eventData.venue}, {eventData.location}
            </Typography>
          </Paper>
        </div>
        <div className="event-details-section">
          <div className="icon-div">
            <EmailOutlinedIcon fontSize="large" />
          </div>
          <Item data={eventData.contact} title="Contact:" />
        </div>
        <div className="event-details-section">
          <div className="icon-div">
            <ChairOutlinedIcon fontSize="large" />
          </div>
          <Item
            data={
              slotsAvailable
                ? slotsAvailable
                : eventData.completedStatus
                ? "-"
                : eventData.totalSlots
            }
            title="Slots Available:"
          />
        </div>
        {/* <FormControlLabel required control={<Checkbox defaultChecked />} label="Accept terms and conditions" />
                <Button variant="contained" >Register Now</Button> */}

        {eventData.completedStatus ? (
          <>
            <Typography
              component="legend"
              variant="subtitle1"
              fontSize={"18px"}
            >
              Average based on volunteer rating
            </Typography>
            <Rating
              name="size-large"
              value={eventData.eventRating}
              size="large"
              precision={0.5}
              readOnly
              sx={{ mt: "0 !important", fontSize: "40px" }}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#fff",
                color: "#0078D6",
                paddingY: "3%",
                borderTop: "1px solid #0078D6",
                borderBottom: "1px solid #0078D6",
              }}
            >
              <Typography variant="h5" fontSize={"28px"} fontWeight={700}>
                {eventData.totalVolunteers} Volunteers | {eventData.totalHours}{" "}
                Hours
              </Typography>
            </Paper>
            {user && user.userType == "ADMIN" && (
              <>
                <Button
                  variant="contained"
                  disabled={disableEmailButton}
                  onClick={(e: any) => sendMail(e)}
                  className="action-buttons"
                >
                  Thank you Email
                </Button>
                <LoadingButton
                  loading={viewButtonLoader}
                  variant="contained"
                  onClick={(e: any) => onViewUsers(e)}
                  className="action-buttons"
                >
                  View Users
                </LoadingButton>
              </>
            )}
          </>
        ) : (
          <>
            <FormControlLabel
              onClick={() => setChecked(true)}
              sx={{ fontSize: "18px !important" }}
              required
              control={
                <Checkbox
                  checked={checked}
                  name="checkboxFirst"
                  onChange={handleChange}
                />
              }
              label={
                <a
                  target="_blank"
                  href={require("../../static/docs/Data Processing Consent.pdf")}
                >
                  Data Protection Consent
                </a>
              }
            />
            <FormControlLabel
              onClick={() => setVolunteerChecked(true)}
              sx={{ fontSize: "18px !important" }}
              required
              control={
                <Checkbox
                  checked={volunteerChecked}
                  name="checkboxSecond"
                  onChange={handleChange}
                />
              }
              label={
                <a
                  target="_blank"
                  href={require("../../static/docs/Volunteering Guidelines.pdf")}
                >
                  Volunteering Guidelines
                </a>
              }
            />
            {eventData.upcomingRegistration ? (
              <>
                <Typography variant="body1">
                  <b>Note:</b> Event Registration starts from{" "}
                  {formatDate(eventData.registrationStartDate)}{" "}
                  {formatTime(eventData.registrationStartTime)}
                </Typography>
              </>
            ) : (
              eventData.userRegistered && (
                <Typography variant="body1">
                  <b>Note:</b> You have already registered for this event
                </Typography>
              )
            )}
            <LoadingButton
              loading={showButtonLoader}
              variant="contained"
              loadingIndicator="Please wait…"
              onClick={handleOpenDialog}
              disabled={
                disableRegistrationButton || !checked || !volunteerChecked
              }
              className="action-buttons"
            >
              {" "}
              {eventData.registrationStatus && eventData.totalSlots > 0
                ? "Register Now"
                : eventData.upcomingRegistration
                ? "Registration will open soon"
                : "Registration closed"}
            </LoadingButton>
            {user && user.userType == "ADMIN" && (
            <>
              <LoadingButton
                loading={showButtonLoader}
                variant="contained"
                onClick={handleEventCancellationOpenDialog}
                disabled={disableCancelButton}
                className="action-buttons"
              >
                Cancel Event
              </LoadingButton>
              <LoadingButton
                loading={viewButtonLoader}
                variant="contained"
                onClick={(e: any) => onViewUsers(e)}
                className="action-buttons"
              >
                View Users
              </LoadingButton>
            </>
            )} 
          </>
        )}
        {openEventCancellationDialog && (
          <Dialog
            open={openEventCancellationDialog}
            onClose={handleEventCancellationCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ padding: "40px 80px" }}>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontSize: "28px",
                  fontWeight: 400,
                  color: "#000",
                  fontFamily: "mcregular !important",
                  width: "260px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to Cancel this event?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ margin: "25px auto" }}>
              <Button
                variant="contained"
                onClick={(e: any) => onCancelEvent(e)}
                autoFocus
                sx={{ background: "#008dfc", width: "120px" }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={handleEventCancellationCloseDialog}
                sx={{ background: "#008dfc", width: "120px" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
          {openCancellationReasonDialog && (
          <Dialog
            open={openCancellationReasonDialog}
            onClose={handleEventCancellationCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ padding: "40px 80px" }}>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontSize: "28px",
                  fontWeight: 400,
                  color: "#000",
                  fontFamily: "mcregular !important",
                  width: "260px",
                  textAlign: "center",
                }}
              >
                Mention the reason for this event cancellation?
              </DialogContentText>
              <TextField 
                // variant="outlined" 
                  className="reason-box"
                  label="Reason for cancelling this event"
                  id="outlined-multiline-static"
                  multiline
                  fullWidth
                  hiddenLabel
                  variant="filled"
                  onChange={(e: any) => setReason(e.currentTarget.value)}
                  required={true}
                  value={reason}
                />
            </DialogContent>
            <DialogActions sx={{ margin: "25px auto" }}>
              <Button
                variant="contained"
                onClick={(e: any) => onCancelReasonEvent(e)}
                autoFocus
                sx={{ background: "#008dfc", width: "120px" }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={handleEventCancellationCloseDialog}
                sx={{ background: "#008dfc", width: "120px" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {openRegisterationDialog && (
          <Dialog
            open={openRegisterationDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ padding: "40px 80px" }}>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontSize: "28px",
                  fontWeight: 400,
                  color: "#000",
                  fontFamily: "mcregular !important",
                  width: "260px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to Register for this event?
              </DialogContentText>
            </DialogContent>{" "}
            <DialogActions sx={{ margin: "25px auto" }}>
              <Button
                variant="contained"
                onClick={(e: any) => registerEvent(e)}
                autoFocus
                sx={{ background: "#008dfc", width: "120px" }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                sx={{ background: "#008dfc", width: "120px" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Stack>
      {openModal && (
        <UserListing
          open={openModal}
          handleClose={handleClose}
          userData={usersData}
        />
      )}
      <Toaster
        openToaster={openToaster}
        toastMessage={toastMessage}
        handleToasterClose={handleToasterClose}
      />
    </>
  );
};

export default DetailsGrid;
