import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/",
});
let isRefresh = false
export const fetchRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/token/refreshToken`, {
      headers: { Authorization: `Bearer ${refreshToken}` },
    });
    const token = response.data; 
    return token;
  } catch (error) {
    console.error("Failed to refresh token", error);
    throw error; 
  }
};

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("access_token");
    config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if(error.response.status === 401){
      originalRequest._retry = false;
  }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        if(isRefresh==false){
          isRefresh=true
          const newToken = await fetchRefreshToken(); 
          isRefresh=false
          localStorage.setItem("access_token", newToken.access_token);
          localStorage.setItem("refresh_token", newToken.refresh_token);
        }
    
        originalRequest.headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
        return axiosInstance(originalRequest); 
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
