import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import { spocs } from '../../helpers/constant';
import "./home.css";
import SearchIcon from "@mui/icons-material/Search";
import { search } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSpocsList } from "../../features/spocs/spocsSlice";
import EditSpocs from "./editSpocs";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddSpocs from "./addSpocs";
import Loader from "../loader";

const Spocs = React.forwardRef<HTMLDivElement>((props, ref) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const spocsList = useAppSelector((state) => state.spocsList.spocsList);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [spocList, setSpocList] = React.useState(spocsList);
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const user = useAppSelector((state) => state.userProfile.profile);

  const openEditSpocsForm = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  const openAddSpocsForm = () => {
    setOpenAdd(true);
  };

  const handleAddPopupClose = () => {
    setOpenAdd(false);
  };

  const searchSpocs = () => {
    setSpocList(search(spocsList, searchQuery));
  };

  React.useEffect(() => {
    setSpocList(spocsList);
  }, [spocsList]);

  React.useEffect(() => {
    dispatch(fetchSpocsList()).then((res) => {
      if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
    });
  }, []);

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
    searchSpocs();
  };

  return (
    <React.Fragment>
      <div ref={ref}>
        <Container>
          <Box mb={4} sx={{ flexGrow: 1 }}>
            <Grid pt={4} pb={4} container rowSpacing={1}>
              <Grid item sm={7}>
                <Typography variant="h3">Ambassadors of Change</Typography>
                <Typography mt={2} variant="body1" sx={{ fontSize: "18px" }}>
                  Navigating through our programs becomes seamless with our
                  dedicated list of Single Points of Contact (SPOCs) for each
                  department at MBRDI.
                </Typography>
              </Grid>
              <Grid item sm={5} textAlign={"right"}>
                <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment"
                    placeholder="Search by Name/Dept"
                    type="text"
                    sx={{ paddingRight: 0 }}
                    onChange={handleSearch}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchSpocs();
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end" variant="filled">
                        <IconButton
                          type="button"
                          aria-label="search"
                          className="searchButton"
                          onClick={searchSpocs}
                        >
                          <SearchIcon htmlColor="white" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {user && user.userType == "ADMIN" && (
                  <>
                    <Button
                      variant="contained"
                      className="spocs-edit-button"
                      onClick={openEditSpocsForm}
                    >
                      <ModeEditIcon
                        fontSize="small"
                        sx={{ paddingRight: "5px" }}
                      />{" "}
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      className="spocs-add-button"
                      onClick={openAddSpocsForm}
                    >
                      + Add
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mb={2} sx={{ flexGrow: 1 }}>
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              rowSpacing={1}
              spacing={4}
            >
              <Grid item sm={12} xs={8} textAlign={"center"}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={spocList.length > 1 ? true : false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 0.75s ease-in-out"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    //renderDotsOutside={true}
                  >
                    {spocList.length === 0 ? (
                      <div>No spocs available</div>
                    ) : (
                      spocList &&
                      spocList.map((item: any, i: any) => (
                        <Item key={i} item={item} />
                      ))
                    )}
                  </Carousel>
                )}
              </Grid>
            </Grid>
          </Box>

          {open && <EditSpocs open={open} handleClose={handlePopupClose} />}
          {openAdd && (
            <AddSpocs open={openAdd} handleClose={handleAddPopupClose} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
});

export default Spocs;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 540, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Item(props: any) {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Grid container rowSpacing={1}>
        <Grid item sm={12} textAlign={"center"}>
          {props.item.imageName && props.item.imageName != "" ? (
            <>
              <Avatar
                sx={{ width: 160, height: 160, m: "0px auto" }}
                src={props.item.imageName}
                alt="spoc-image"
              />
            </>
          ) : (
            <>
              <Avatar
                alt={props.item.name}
                className="spocs-profile-image"
                {...stringAvatar(props.item.name && props.item.name)}
              />
            </>
          )}
          <Typography sx={{ pt: 2 }} variant="h6">
            {props.item.name}
          </Typography>
          <Typography variant="body2" color="#8C8D8E">
            {props.item.department}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (name && name.split(" ").length == 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "3em",
      },
      children: `${name.split(" ")[0][0]}`,
    };
  } else if (name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "3em",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } else {
    return "";
  }
}
