import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL, URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface registrationState {
    eventRegistrationRes: {}
    status: string
}

const initialState:registrationState = {
    eventRegistrationRes: {},
    status: '',
}

const registrationSlice = createSlice({
    name: 'eventRegistration',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(registerForEvent.fulfilled, (state, action) => {
            state.eventRegistrationRes = action.payload
            state.status = 'success'
          })
          
      },
}
)

export default registrationSlice.reducer;

export const registerForEvent = createAsyncThunk('/create-eventRegistration', async (data:object) => {
    const response = await axiosInstance.post(`create-eventRegistration`, data)
    
    return response.data;
})