import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import Container from '@mui/material/Container';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logAnalyticsEvent } from "../utils/firebaseConfig";

const CheckInAttendance = () => {
    logAnalyticsEvent("Attendance");
    const dispatch = useAppDispatch();
    const details = useAppSelector((state) => state.eventCheckIn.eventCheckin);

    return (
        <>

            <Container disableGutters sx={{ px: 5 }}>
                
                <Grid container spacing={8}>
                    <Grid item xs={12} sm container >
                        <Typography mt={4} variant="h5" >{details}</Typography>
                    </Grid>
                </Grid>

            </Container>

        </>
    )
}
export default CheckInAttendance