import React, { useRef } from "react";
import { useState } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import quote from "../../static/images/Quotes.png";
import Carousel from 'react-material-ui-carousel';
import '../profile/profile.css';
import { itemData } from "../../helpers/const";
const Testimonials = ({testimonial}:any) => {
  
  return (
    <Box className="testimonial-container">
      <Typography variant="h6" gutterBottom>
        Testimonial
      </Typography>
     {testimonial.length>0 ?  <Carousel
      fullHeightHover={false}
      navButtonsWrapperProps={{
          style: {
            bottom: '0',
            top: '100%',
            height: '40px',
            position: 'relative',
            display: 'inline',
            
          },
      }}
        
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            margin: '30px 0'
          }
        }}
        indicators={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        interval= {6000}
        PrevIcon={<NavigateBeforeIcon fontSize="small" />}
        NextIcon={<NavigateNextIcon fontSize="small" />}  
      >
        {
          testimonial && testimonial.map((item:any, i:number) => {
            return (
              <Paper className="testimonial-paper" key={i}>
                <Typography key={i} variant="body1" gutterBottom>
                <Typography component="span" sx={{paddingRight: '15px'}}><img src={quote}/></Typography> {item.description}
              </Typography>
              </Paper>
              
            )
          })
        }
      </Carousel> : "No testimonials available" }
    </Box>
  )
}

export default Testimonials