import React, { useState } from "react";
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Card, CardContent, CardMedia, Box, Avatar } from '@mui/material';
import { Container, Typography } from '@mui/material';
import './eventDetails.css';


function Item(props: any) {
    console.log("props",props)
    let img = "";
    if(props.item.imageUrl && props.item.imageUrl.split(" ")){
        if(props.item.imageUrl.split(" ").length > 0)
            img = props.item.imageUrl.split(" ").join("%20");
        else
            img = props.item.imageUrl;
    }
    console.log("img",img)
     
    return (
        <Paper elevation={3} sx={{minHeight: 'auto', objectFit: 'contain', display: 'flex',}}>
            <Avatar sx={{ width: '100%', height: '100%', borderRadius: '0' }} src={img} alt='highlight-image' />
            <Typography component="span" display={'block'} gutterBottom variant='overline' sx={{pt: '1%', pl: '1%' , sm: 'body1', xs: 'body2', position: 'absolute' }} >
                <span style={{boxShadow: 'inherit', overlay: 'auto'}} className="carousel-event-category-tag">{props.event.eventCategory && props.event.eventCategory.name}</span>
            </Typography>
        </Paper>
    )
}


const EventCarousel = (props: any) => {
    return (
        <>
            <React.Fragment>
                <Carousel
                    onChange={() => console.log('updated')}
                    swipe={true}
                    indicators={true}
                    animation="slide"
                    navButtonsAlwaysInvisible={true}
                    indicatorIconButtonProps={{
                        style: {
                            color: '#D6D6D6',     // 3
                            margin: '5px',
                            padding: '3px',
                        }
                    }}
                    indicatorContainerProps={{
                        style: {
                            textAlign: 'right', // 4
                            position: 'absolute',
                            bottom: '5px',
                            zIndex: 1,
                            paddingRight: '50px',
                            marginTop: 0
                        }

                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            background: 'unset !important', // 2
                            border: '2px solid #fff',
                            color: '#fff',
                        }
                    }}
                >
                    {
                        props.events && props.events[0].eventImages && props.events[0].eventImages.length > 0 ? props.events[0].eventImages.map((item: any, i: number) =>
                            <Item key={i} item={item} event={props.events[0]} />
                        )
                        :
                        props.events.map((item: any, i: number) =>
                            <Item key={i} item={item} event={props.events[0]} />
                        )
                    }
                </Carousel>
            </React.Fragment>
        </>
    )
}

export default EventCarousel