import React, { useEffect, MouseEvent, useRef, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import type { InteractionItem } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getMonthlyEventsData } from "../../features/common/visualisationStats";



export const options = {
    plugins: {
      title: {
        display: true,
        text: 'Annual Data by Month',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

 

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const labels = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];


const BarChartStacked = ( { yearlyStats, selectedMonth }:any) => {

    const dispatch = useAppDispatch();
    let chartRef = useRef(null);
    const [totalHours, setTotalHours] = useState<any>([])
    const [totalEvents, setTotalEvents] = useState<any>([])
    const [totalVolunteers, setTotalVolunteers] = useState<any>([])

  
    const printElementAtEvent = (element: InteractionItem[]) => {
      if (!element.length) return;
  
      const { datasetIndex, index } = element[0];
      if(labels.indexOf(data.labels[index]) > -1){
        console.log("index >>>>>", labels.indexOf(data.labels[index]) )

        selectedMonth(labels.indexOf(data.labels[index]) + 1);
      }
  
      console.log(data.labels[index], data.datasets[datasetIndex].data[index]);
    };

  
    const onClick = (event: any) => {
      
      const { current: chart } = chartRef;
  
      if (!chart) {
        return;
      }
  
      //printDatasetAtEvent(getDatasetAtEvent(chart, event));
      printElementAtEvent(getElementAtEvent(chart, event));
      //printElementsAtEvent(getElementsAtEvent(chart, event));
    };

    useEffect(() => {
            if(yearlyStats){
              
              let data = yearlyStats?.monthly_event_creation;
              if(data && Object.keys(data)) graphData(data);  
            }
                   
    }, [yearlyStats])

    const graphData = async(data:any) => {

        let hours:any=[];
        let events:any=[]; 
        let volunteers:any=[];
        //console.log("IN >>>>>>>>>>>>>>>>>",data)
        if(Object.keys(data)){
          let gData = await labels.map((month) => {
            hours?.push(data[month]['total_hours'])
            events?.push(data[month]['total_events'])
            volunteers?.push(data[month]['total_user_registered'])
        })

      
          if(gData){
            console.log("DATA >>>>", gData, hours, events, volunteers)
              setTotalHours(hours)
              setTotalEvents(events)
              setTotalVolunteers(volunteers)
          }

        


        }
        

    }

    const data = {
        labels,
        datasets: [ 
            {
              label: 'Events',
              data: totalEvents,
              backgroundColor: 'rgb(255, 99, 132, 0.4)',
            },
            {
                label: 'Volunteers',
                data: totalVolunteers,
                backgroundColor: 'rgb(54, 162, 235, 0.4)',
              },
              {
                label: 'Hours',
                data: totalHours,
                backgroundColor: 'rgb(75, 192, 192, 0.4)',
              },
    
        ]
    }

    return(
      <>
      {
        (Object.keys(data)) && (
        <Bar
        ref={chartRef}
        options={options}
        data={data}
        onClick={onClick}
       
        />
      )
      }
      </>
        
    )
}

export default BarChartStacked;