import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface impactStoriesState {
    feedbackImages: {}
}

const initialState:impactStoriesState = {
    feedbackImages: {},
}

const feedbackImagesSlice = createSlice({
    name: 'feedbackImages',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(uploadFeedbackImages.fulfilled, (state, action) => {
            state.feedbackImages = action.payload;
          })
          
      },
}
)

export default feedbackImagesSlice.reducer;

export const uploadFeedbackImages = createAsyncThunk('feedbackImages/createFeedbackHighlights', async (data:object) => {
    const response = await axiosInstance.post(`api/feedbackImages/createFeedbackHighlights`, data)
    return response.data;
})