import { useEffect } from "react";

export default function ScrollToTop() {
    const { pathname } = window.location;
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}