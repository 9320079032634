import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../profile/profile.css";
import CompletedEvents from "./completedEvents";
import ImageGallery from "../imageGallery";
import { useAppSelector } from "../../app/hooks";
import CompletedRegistration from "./CompletedRegistration";
import  "./certificates.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Certificates = ({ data, feedbackData, shortId, certificates ,registeredData}: any) => {
  console.log("feedbackData", feedbackData);
  const feedbackImages = feedbackData.map(
    (feedback: any) => feedback.imageUrlList
  );
  const feedbackImageName = feedbackData.map(
    (feedback: any) => feedbackImages
  );
  const user = useAppSelector((state) => state.userProfile.profile);
  console.log("Feedback Images", feedbackData);

  return (
    <>
      <Box className="certificate-container">
      <Box py={3}  sx={{ flexGrow: 1 }}>
          <Typography variant="h3" gutterBottom>
            Events Registered
          </Typography>
          
          {registeredData && registeredData.length ? (
            <div  className="button-container">
            <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all 0.75s ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            
               
              {registeredData.map((event: any, index: any) => (
                <Grid
                container
                
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 8, md: 12 }}   
                 
              >
               
                <Grid key={index} item xs={2} sm={4} md={11} >
                  <CompletedRegistration key={index} event={event} />
                </Grid>
                </Grid>
              ))}
             
            
            </Carousel>
            </div>
            
          ) : (
            <Typography variant="body1">
              No Events Registered
            </Typography>
          )}
          
      </Box>
        <Box py={3}>
          <Typography variant="h3" gutterBottom>
            Events Completed
          </Typography>
          {data && data.length ? (
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >
              {data.map((event: any, index: any) => (
                <Grid key={index} item xs={2} sm={4} md={6}>
                  <CompletedEvents key={index} event={event} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">
              You have not volunteered for any event!
            </Typography>
          )}
        </Box>
        {/*<Box py={3}>
          <Typography variant="h3" gutterBottom>
            Certificates
          </Typography>
          {certificates && certificates.length ? (
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >
              {certificates.map((c: any, index: any) => (
                <Grid key={index} item xs={2} sm={4} md={6}>
                  <object
                    data={c}
                    type="application/pdf"
                    width="350px"
                    height="350px"
                  ></object>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">
              No Certificates are available!
            </Typography>
          )}
          </Box>*/}
        <Box py={3}>
        {user && user.userType == "USER" &&<><Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h3" gutterBottom>
                Featured Images
              </Typography>
            </Grid>
          </Grid>
          <ImageGallery gallery={feedbackImages} feedbackImageName={feedbackData} />
          </>}
          <Grid item xs={12} md={4}>
            <div className="share-btn">
              <Typography variant="body2" gutterBottom>
                Share
              </Typography>
              <ul>
                <li>
                  <a
                    href="https://social.cloud.corpintra.net/groups/csr-mbrdi/"
                    target="_blank"
                  >
                    <img src={require("../../static/images/hubspot.png")} />
                  </a>
                </li>
              </ul>
            </div>
          </Grid>
        </Box>

        {/* <Paper elevation={0} sx={{ backgroundColor: '#0078d6', width: '110px', height: '40px', paddingX: '15px', paddingY: '8px' }}>
          <Typography variant="body2" sx={{ padding: '0 10px' }} gutterBottom><a href="#" aria-label="edit profile" className="update-link">See More</a>
          </Typography>
        </Paper> */}
      </Box>
    </>
  );
};

export default Certificates;
