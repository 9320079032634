import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useScrollTrigger } from "@mui/material";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "../../helpers/constant";
import EventCard from "../card";
import Container from "@mui/material/Container";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SelectChangeEvent } from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./event.css";
import EventForm from "./eventForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";
import {
  createEvent,
  createEventImages,
} from "../../features/eventDetails/createEventSlice";
import {
  listEvent,
  searchEvent,
  totalEventCount,
  searchEventsCount,
} from "../../features/eventDetails/eventListSlice";
import { uploadFile } from "../../features/common/fileUploadSlice";
import { fetchEventCategories } from "../../features/eventDetails/eventCategorySlice";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Toaster from "../toaster";
import Loader from "../loader";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      disableScrollLock: true,
    },
  },
};

interface file {
  lastModified: number;
  lastModifiedDate: {};
  name: string;
  size: number;
  type: string;
}

export default function EventList() {
  const dispatch = useAppDispatch();
  const trigger = useScrollTrigger();
  const [isLoading, setIsLoading] = React.useState(true);
  const [openToaster, setOpenToaster] = React.useState(false);
  const [disablePublish, setDisablePublish] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [filter, SetFilter] = React.useState<string[]>([]);
  const [filters, setFilters] = React.useState<string[]>([]);
  const [filterForSearch, setFilterForSearch] = React.useState([]);
  const eventCategories = useAppSelector(
    (state) => state.eventCategories.eventCategories
  );
  const [eventData, setEventData] = React.useState<string[]>([]);
  const events = useAppSelector((state) => state.eventList.eventList);
  const [open, setOpen] = React.useState(false);
  const [selectOpen, setSelectOpen] = React.useState(false);
  const createEventFormTitle = "Create Event";
  const user = useAppSelector((state) => state.userProfile.profile);
  const [pageNo, setPageNo] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [imgPath, setImgPath] = React.useState("");
  const [eventLoadingMessage, setEventLoadingMessage] =
    React.useState("Loading Events...");
  const [toastType, setToastType] = React.useState("");
  const PAGE_SIZE = 9;
  // toaster
  const [toastMessage, setToastMessage] = React.useState("");
  const totelEveCount = useAppSelector(
    (state) => state.eventList.totalEventCount
  );

  logAnalyticsEvent("Volunteering Events");

  const openCreateEventForm = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    trigger && handleClose();
  }, [trigger]);

  const handleToasterClose = () => {
    setOpenToaster(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value);
    setPageNo(1)
  };

  const handleInputChange = (event: any) => {
    setKeyword(event.target.value);
    setPageNo(1)
  };

  const getSearchParam = (filterParam?: any) => {
    console.log("IN", filterParam, filterForSearch);
    let qp = "";
    if (keyword !== "") {
      qp = `keyword=${keyword}`;
    }
    if (location !== "") {
      qp = qp + `${keyword ? "&" : ""}location=${location}`;
    }
    if (filterParam?.length) {
      console.log("In if>>");
      filterParam.forEach((item: any) => {
        qp =
          qp +
          `${keyword || location ? "&" : ""}filter=${item}${
            filterParam.length > 1 ? "&" : ""
          }`;
      });
    }
    if (filterParam === undefined && filterForSearch?.length > 0) {
      console.log("In if>>");
      filterForSearch.forEach((item: any) => {
        qp =
          qp +
          `${keyword || location ? "&" : ""}filter=${item}${
            filterForSearch.length > 1 ? "&" : ""
          }`;
      });
    }
    qp = qp + "&page=" + pageNo;
    
    console.log("QP >>", qp);
    return qp;
  };

  const handleSubmit = (event: any) => {
    dispatch(searchEventsCount(getSearchParam()));
    dispatch(searchEvent(getSearchParam())).then((res: any) => {
      if (!res.payload.length) {
        setEventData([]);
        setEventLoadingMessage("No matching event found");
      }
    });
  };

  const handleSelectChange = (event: any) => {
    event.stopPropagation();
    console.log("check", event);
    setFilterForSearch(event.target.value);
    // let filterParam = event.target.value[0];
    let otherParams = getSearchParam(event.target.value);
    // let queryString = `${otherParams}${otherParams?'&':''}filter=${filterParam}`
    if (event.target.value.length) {
      setIsLoading(true);
      dispatch(searchEventsCount(otherParams));
      dispatch(searchEvent(otherParams)).then((res) => {
        if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
      });
    } else if (otherParams) {
      console.log("In else if");
      setIsLoading(true);
      dispatch(searchEventsCount(otherParams));
      dispatch(searchEvent(otherParams)).then((res) => {
        if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
      });
    } else {
      console.log("In else");
      setIsLoading(true);
      dispatch(totalEventCount());
      dispatch(listEvent(pageNo)).then((res) => {
        if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
      });
    }

    const {
      target: { value },
    } = event;
    SetFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFormSubmission = (e: any, postBody: any, files: any) => {
    console.log("files in createEvent",files)
    e.preventDefault();
    let uploadFileResponse;
    setToastType("error");
    if (postBody.contact == "") {
      setToastMessage("All fields are mandatory!");
      setOpenToaster(true);
      return null;
    }
    // else if (postBody.dept == "") {
    //   setToastMessage("All fields are mandatory!");
    //   setOpenToaster(true);
    //   return null;
    // }  Note: commented code for department lists for Phase 2
    else if (postBody.venue == "") {
      setToastMessage("All fields are mandatory!");
      setOpenToaster(true);
      return null;
    }  else if (!files.length) {
      setToastMessage("Feature image is required!");
      setOpenToaster(true);
      return null;
    } else if (files.length > 5) {
      setToastMessage("More then 5 images are not allowed!");
      setOpenToaster(true);
      return null;
    } else if (!validateEventDates(postBody)) {
      return null;
    } else {
      var filter =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!filter.test(postBody.contact)) {
        setToastMessage("Please enter a valid email!");
        setOpenToaster(true);
        return false;
      } else {
        let emailDomain = postBody.contact.split("@");
        if (emailDomain[1] != "mercedes-benz.com") {
          setToastMessage("Only Mercedes Benz email is allowed!");
          setOpenToaster(true);
          return false;
        }
      }
    }

    let uploadImages = [
      {
        imageName: String,
        event: {},
      },
    ];
    let formData = new FormData();
    let images:any = []
    for (let index = 0; index < files.length; index++) {
      images.push(files[index]['name'])
      formData.append("images",files[index],files[index].name);
     
      //formData.append("images", files,images[index]['name']);

    }
    //formData.append('images', images)
    //formData.append('images',JSON.stringify(images))
  //formData.append("images", JSON.stringify(images))
    ///formData.append("images", images);
    console.log("images",images)

    ///formData.append("images", images);
    formData.append("event", JSON.stringify(postBody));
    console.log("formData",formData)
    dispatch(createEvent(formData)).then((res) => {
      if (res.meta.requestStatus == "fulfilled") {
        setDisablePublish(false);
        
       
        // event Images
        
        setToastType("success");
        setToastMessage("New event created successfully!");
        setOpenToaster(true);
        setIsLoading(true);
        dispatch(totalEventCount());
        dispatch(listEvent(pageNo)).then((res) => {
          if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
        });
        handlePopupClose();
        setCreateEventBody({
          title: "",
          imagePath: imgPath,
          imageName: "",
          description: "",
          location: "Bangalore",
          venue: "",
          eventStartDate: dayjs(""),
          eventStartTime: dayjs(""),
          eventEndDate: dayjs(""),
          eventEndTime: dayjs(""),
          registrationStartDate: dayjs(""),
          registrationStartTime: dayjs(""),
          registrationEndDate: dayjs(""),
          registrationEndTime: dayjs(""),
          allslots: null,
          totalSlots: null,
          contact: "",
          createdBy:
            user.userName == "" || user.userName == undefined
              ? "ADMIN_USER"
              : user.userName,
          eventCategory: {
            id: "",
          },
        })
        setLocation("")
      } else {
        setDisablePublish(false);
        setToastType("error");
        setToastMessage("Failed to create this event!");
        setOpenToaster(true);
        return null;
      }
    });

    /*dispatch(uploadFile(formData)).then((res) => {
      if (res.meta.requestStatus == "fulfilled") {
        // console.log("res.payload",res.payload,postBody.imageName)
        
         postBody.imageName=res.payload
         
        // //console.log("postBody.imageName", postBody.imageName)
        // postBody.imageName = res.payload
        // console.log("Images uploaded!",postBody);
        console.log("files in create",files)
        const files_modified = res.payload.split(',')
        console.log("files_name",files_modified)
        dispatch(createEvent(postBody,formData)).then((res) => {
          if (res.meta.requestStatus == "fulfilled") {
            setDisablePublish(false);
            for (let index = 0; index < files_modified.length; index++) {
              uploadImages.push({
                imageName: files_modified[index],
                event: {
                  id: res.payload.id,
                },
              });
            }
    
            let eventImagesPayload = {
              eventImages: uploadImages.slice(1),
            };
            // event Images
            dispatch(createEventImages(eventImagesPayload));
            setToastType("success");
            setToastMessage("New event created successfully!");
            setOpenToaster(true);
            setIsLoading(true);
            dispatch(totalEventCount());
            dispatch(listEvent(pageNo)).then((res) => {
              if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
            });
            handlePopupClose();
          } else {
            setDisablePublish(false);
            setToastType("error");
            setToastMessage("Failed to create this event!");
            setOpenToaster(true);
            return null;
          }
        });
      }
    });*/
   
    setDisablePublish(true);
    console.log("uploadFileResponse>>>>>>",uploadFileResponse)

  };

  const validateEventDates = (data: any) => {
    let registrationStartDateTime = new Date(
      data.registrationStartDate + " " + data?.registrationStartTime
    );
    let registrationEndDateTime = new Date(
      data.registrationEndDate + " " + data?.registrationEndTime
    );
    let eventstartDateTime = new Date(
      data.eventStartDate + " " + data?.eventStartTime
    );
    let eventEndDateTime = new Date(
      data.eventEndDate + " " + data?.eventEndTime
    );

    // console.log(
    //   "Dates eventlist ",
    //   registrationStartDateTime,
    //   registrationEndDateTime,
    //   eventstartDateTime,
    //   eventEndDateTime
    // );

    if (
      dayjs(registrationStartDateTime).isAfter(
        dayjs(registrationEndDateTime)
      ) ||
      dayjs(registrationStartDateTime).isSame(dayjs(registrationEndDateTime))
    ) {
      setToastMessage(
        "Registration end date must be greater then registration start date."
      );
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(registrationStartDateTime).isAfter(dayjs(eventstartDateTime)) ||
      dayjs(registrationStartDateTime).isSame(dayjs(eventstartDateTime))
    ) {
      setToastMessage(
        "Registration start date must be before event start date."
      );
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(registrationEndDateTime).isAfter(dayjs(eventstartDateTime)) ||
      dayjs(registrationEndDateTime).isSame(dayjs(eventstartDateTime))
    ) {
      setToastMessage("Registration end date must be before event start date.");
      setOpenToaster(true);
      return false;
    } else if (
      dayjs(eventstartDateTime).isAfter(dayjs(eventEndDateTime)) ||
      dayjs(eventstartDateTime).isSame(dayjs(eventEndDateTime))
    ) {
      setToastMessage("Event start date must be before event end date.");
      setOpenToaster(true);
      return false;
    } else return true;
  };

  const [createEventBody, setCreateEventBody] = React.useState<any>({
    title: "",
    imagePath: imgPath,
    imageName: "",
    description: "",
    location: "Bangalore",
    venue: "",
    eventStartDate: dayjs(""),
    eventStartTime: dayjs(""),
    eventEndDate: dayjs(""),
    eventEndTime: dayjs(""),
    registrationStartDate: dayjs(""),
    registrationStartTime: dayjs(""),
    registrationEndDate: dayjs(""),
    registrationEndTime: dayjs(""),
    allslots: null,
    totalSlots: null,
    // dept: "",
    contact: "",
    createdBy:
      user.userName == "" || user.userName == undefined
        ? "ADMIN_USER"
        : user.userName,
    eventCategory: {
      id: "",
    },
  });

  React.useEffect(() => {
    dispatch(totalEventCount());
    dispatch(listEvent(pageNo)).then((res) => {
      if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
    });
    dispatch(fetchEventCategories());
  }, []);

  React.useEffect(() => {
    if (keyword != "") handleSubmit(event);
    else if (location != "") handleSubmit(event);
    else if (filterForSearch.length > 0) handleSubmit(event);
    else dispatch(listEvent(pageNo));
  }, [pageNo]);

  React.useEffect(() => {
    if (eventCategories && eventCategories.length > 0) {
      let categories = eventCategories.map((ec: any) => {
        return ec.name;
      });
      setFilters(categories);
    }
  }, [eventCategories]);

  React.useEffect(() => {
    if (events && events.length > 0) {
      setEventData(events);
    }
  }, [events]);

  React.useEffect(() => {
    dispatch(totalEventCount());
    setTotal(Math.ceil(totelEveCount / 9));
  }, [eventData]);

  const handlePageChange = (event: any, pageNumber: any) => {
    // set page Number
    setPageNo(pageNumber);
    // console.log(pageNumber)
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClose = () => {
    setSelectOpen(false);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Typography mt={4} variant="h3">
          Our Volunteering Events
        </Typography>
        <Box mb={3} sx={{ flexGrow: 1 }}>
          <Grid pt={4} container rowSpacing={1}>
            <Grid item sm={8}>
              <Paper
                className="search-form"
                component="form"
                sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search with any keyword"
                  // inputProps={{ 'aria-label': 'search with any keyword' }}
                  // inputProps={{ 'aria-label': 'search with any keyword' }}
                  name="keyword"
                  onChange={handleInputChange}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        color: "#505050",
                        opacity: 1, // otherwise firefox shows a lighter color
                        fontSize: "18px",
                      },
                    },
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    },
                  }}
                />

                <Divider
                  sx={{ height: 28, m: 0.5, backgroundColor: "#000" }}
                  orientation="vertical"
                />

                <FormControl variant="standard" className="location-search-box">
                  {/* <InputLabel id="simple-select-label">Search by location</InputLabel> */}
                  <Select
                    labelId="simple-select-label"
                    displayEmpty
                    renderValue={
                      location !== "" ? undefined : () => "Search by location"
                    }
                    id="simple-select"
                    value={location}
                    onChange={handleChange}
                    sx={{ width: "90%" }}
                    variant="standard"
                    disableUnderline
                  >
                    <MenuItem value={""}>Select Location</MenuItem>
                    <MenuItem value={"bengaluru"}>Bengaluru</MenuItem>
                    <MenuItem value={"pune"}>Pune</MenuItem>
                    <MenuItem value={"virtual"}>Virtual</MenuItem>
                  </Select>
                </FormControl>
                <IconButton
                  type="button"
                  onClick={handleSubmit}
                  sx={{
                    p: "10px",
                    backgroundColor: "#0078D6",
                    color: "#d6e9f8",
                    marginBottom: "10px",
                  }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item sm={4} sx={{ alignSelf: "center", textAlign: "end" }}>
              {user && user.userType == "ADMIN" && (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 0,
                    height: "50px",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                  onClick={openCreateEventForm}
                >
                  + Create Event
                </Button>
              )}

              {/* <Button sx={{textTransform:'capitalize'}} size='large' variant="contained" endIcon={<FilterAltIcon />}>Filter</Button> */}
              <FormControl className="filterButton">
                {/* <InputLabel id="multiple-checkbox-label">Filters</InputLabel> */}
                <Select
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  displayEmpty
                  multiple
                  onClick={() => setSelectOpen(!selectOpen)}
                  value={filter}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={handleSelectChange}
                  input={<OutlinedInput placeholder="Filter" />}
                  className="filterSelect"
                  onClose={handleClose}
                  open={selectOpen}
                  renderValue={(selected: any) => {
                    // if (!filter.length) {
                    //   return
                    // }
                    return (
                      <Typography
                        variant="body1"
                        component="span"
                        color="#fff"
                        sx={{ fontSize: "1.125rem", fontWeight: 600 }}
                      >
                        Filter
                        <FilterListIcon
                          sx={{ marginLeft: "8px", verticalAlign: "bottom" }}
                        />
                      </Typography>
                    );
                    // return selected.join(', ');
                  }}
                >
                  {filters &&
                    filters.length > 0 &&
                    filters.map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={filter.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {isLoading ? (
          <Loader />
        ) : (
          <Box mt={4} mb={4} sx={{ flexGrow: 1 }}>
            <Grid container spacing={5}>
              {eventData &&
                eventData.length > 0 &&
                eventData?.map((event, index) => (
                  <Grid key={index} item xs={12} sm={2} md={4}>
                    <EventCard key={index} event={event} />
                  </Grid>
                ))}

              {eventData.length == 0 && (
                <>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography mt={10} mb={2} variant="h6">
                      {eventLoadingMessage}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Stack alignItems={"center"} p={5} spacing={2}>
              {eventData && eventData.length > 0 && (
                <Pagination
                  count={total}
                  page={pageNo}
                  onChange={(event, pageNumber) =>
                    handlePageChange(event, pageNumber)
                  }
                  color="primary"
                />
              )}
            </Stack>
          </Box>
        )}

        <Toaster
          openToaster={openToaster}
          toastMessage={toastMessage}
          type={toastType}
          handleToasterClose={handleToasterClose}
        />
        {open && (
          <EventForm
            open={open}
            handleClose={handlePopupClose}
            title={createEventFormTitle}
            data={createEventBody}
            handleChange={setCreateEventBody}
            submitForm={handleFormSubmission}
            disablePublish={disablePublish}
          />
        )}
      </Container>
    </React.Fragment>
  );
}
