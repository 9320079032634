import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { testimonials } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface impactStoriesState {
    storiesList: [],
    testimonial:{}
}

const initialState:impactStoriesState = {
    storiesList: [],
    testimonial:{}
}

const impactStoriesSlice = createSlice({
    name: 'impactStories',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(fetchStories.fulfilled, (state, action) => {
            state.storiesList = action.payload;
          })
          .addCase(createTestimonial.fulfilled, (state, action) => {
            state.testimonial = action.payload;
          })
          
      },
}
)

export default impactStoriesSlice.reducer;

export const fetchStories = createAsyncThunk('testimonial/getAllTestimonials', async () => {
    const response = await axiosInstance.get('api/testimonial/getAllTestimonials')
    return response.data;
})

export const createTestimonial = createAsyncThunk('testimonial/createTestimonial', async (data:any) => {
  const response = await axiosInstance.post('api/testimonial/create-testimonial', data)
  return response.data;
})

export const updateTestimonial = createAsyncThunk('testimonial/updateTestimonial', async (data:any) => {
  const response = await axiosInstance.put(`api/testimonial/admin/updateTestimonial/${data.id}`, {
    description:data.description
  })
  return response.data;
})

export const deleteTestimonial = createAsyncThunk('testimonial/deleteTestimonial', async (id:any) => {
  const response = await axiosInstance.delete(`api/testimonial/admin/deleteTestimonial/${id}`)
  return response.data;
})