import React from "react";
import Avatar from '@mui/material/Avatar';
import Compressor from "compressorjs";
import "./profile.css";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Testimonials from "./testimonial";
import profilePhoto from "../../static/images/Jane-Doe.png"
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useAppDispatch } from "../../app/hooks";
import { updateProfileImage } from "../../features/profile/profileDetailsSlice";
import { uploadSingleFile } from "../../features/common/fileUploadSlice";
import Toaster from "../toaster";
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../../features/authenticate/authSlice";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#4872e9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#f5dfdf',
    // borderRadius: 0,
    alignSelf: 'baseline',
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'visible',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    if (name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                fontSize: '5em'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    else {
        return "";
    }
}

const ProfileDetails = ({ userDetails, userFeedback, hours, events, user, testimonial }: any) => {
    const dispatch = useAppDispatch();
    const [toastMessage, setToastMessage] = React.useState('');
    const [openToaster, setOpenToaster] = React.useState(false);
    const [profileImage, setProfileImage] = React.useState<Array<File>>([])
    const [compressedFile,setCompressedFile] = React.useState([] as any) ;
    const [toastType, setToastType] = React.useState('');

    const handleToasterClose = () => {
        setOpenToaster(false);
    }
    const compressImage = (file:File):Promise<File>=>{
        return new Promise((resolve,reject)=>{
          new Compressor(file,{
            quality:0.8,
            width:512,
            height:512,
            success(result:any){
            resolve(new File([result], file.name, {type: result.type}))
              
            },
            error(err:any){
              console.log(err.message)
            }
          })
        })
      }
    const handleUploadClick = async(e: any) => {
        const fileOutput:any = []
        e.preventDefault()
        const compressedImage:any = await compressImage(e.target.files[0])
        setCompressedFile(compressedImage)
        /*if(e.target.files[0]?.size>1000000){
            setToastMessage('Image size more than 1 MB is not allowed!');
            setOpenToaster(true);
            return null;
        }*/
        fileOutput.push(compressedImage)
        console.log("fileOutput",fileOutput)
        setProfileImage(e.target.files);
        dispatch(uploadSingleFile(fileOutput)).then((res=>{
            if(res.meta.requestStatus == 'fulfilled'){
                dispatch(updateProfileImage(res.payload))
                setToastType('success');
                setToastMessage('Profile image uploaded successfully!');
                setOpenToaster(true);
            }          
        })).catch(error=>{
            setToastType('error');
            setToastMessage('Error!');
            setOpenToaster(true);
        })
        
    }
    const logoutClick = () => {
        dispatch(logout()).then((res:any) => {
            if(res.meta.requestStatus == 'fulfilled'){
                // token refesh
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                setTimeout(() => {
                document.location.href ="/";
                }, 1000)
            }
        })
    }


    return (
        <>
            <Box className="profile-image-container">
                {profileImage.length ? (
                    <Avatar alt='profileImage' className="profile-image" src={URL.createObjectURL(compressedFile)} />
                ) : (
                    userDetails && userDetails.imageName != null && userDetails.imageName != "" ? (<Avatar
                        alt={userDetails.imageName}
                        src={userDetails.imageName}
                        className="profile-image"
                    />)
                        : (
                            <Avatar alt={userDetails.userName} className="profile-image"
                                {...stringAvatar(userDetails && userDetails.userName)}

                            />
                        )

                )}
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    className="upload-image-button"

                >
                    <LinkedCameraIcon fontSize="large" sx={{color:'#ffffff'}} />
                    <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        multiple={false}
                        onChange={(e: any) => handleUploadClick(e)}
                    />
                </Button>
            </Box>
            <Box className="profile-details-container">
                <Stack spacing={{ xs: 2, sm: 5, }}>
                    <Item elevation={0} data-testid="profile-name" className="profile-background">
                        <Typography variant="h3">
                            {userDetails.userName}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            {userDetails.department}
                        </Typography>
                    </Item>
                    <Item elevation={0} className="events-block profile-background">
                        <Box className="block-alignmment">
                            <div>
                                <Typography variant="h3" gutterBottom sx={{ marginBottom: 0 }}>
                                    {events}
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Events
                                </Typography>
                            </div>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <div>
                                <Typography variant="h3" gutterBottom sx={{ marginBottom: 0 }}>
                                    {hours}
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Hours
                                </Typography>
                            </div>
                        </Box>
                    </Item>
                    <Item elevation={0} sx={{ width: '100%' }} className="profile-background"><Testimonials testimonial={testimonial} /></Item>

                    {/* <Button sx={{fontSize:'18px', fontWeight:400, borderRadius: 0, padding:'8px 16px', width: 'fit-content', backgroundColor: '#0078d6'}} variant="contained" endIcon={<LogoutIcon />} onClick={logoutClick}>
                    Logout
                    </Button> */}

                </Stack>
            </Box>
            <Toaster toastMessage={toastMessage} openToaster={openToaster} handleToasterClose={handleToasterClose} />
        </>
    )
}

export default ProfileDetails