import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventCategoryState {
  eventCategories:[]
}

const initialState: eventCategoryState = {
    eventCategories:[]
  
}

const eventCategorySlice = createSlice({
  name: 'eventCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchEventCategories.fulfilled, (state, action) => {
        state.eventCategories = action.payload;
      })

  },
}
)

export default eventCategorySlice.reducer;

export const fetchEventCategories = createAsyncThunk('eventCategories/getAllCategories', async (id: string | undefined) => {
  const response = await axiosInstance.get(`api/eventCategories/getAllCategories`)
  return response.data;
})