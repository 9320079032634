import React from "react";
import FaqBanner from "../components/faq/faqBanner";
import Faq from "../components/home/faq";
import FaqAnswers from "../components/faq/faqAnswers";
import { logAnalyticsEvent } from "../utils/firebaseConfig";

const FaqPage = () => {
    logAnalyticsEvent("FAQ");
    return (
        <>
            <FaqBanner />
            <FaqAnswers />
        </>
    )
}
export default FaqPage