import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface userRegisteredState {
    getAllUserRegistered:[]
}

const initialState:userRegisteredState = {
    getAllUserRegistered:[],
}

const userRegisteredSlice = createSlice({
    name: 'userRegistered',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(getAllUserRegistered.fulfilled, (state, action) => {
            state.getAllUserRegistered = action.payload;
          })
          
      },
}
)

export default userRegisteredSlice.reducer;

export const getAllUserRegistered = createAsyncThunk(
    "notcompleted/",
    async (userId: number | null)  => {
      //const response = await axiosInstance.get(`Myprofile/getEvent/1234`)
      
      const response = await axiosInstance.get(
        `api/profile/event/notcompleted/${userId}`
      );
  
      return response.data;
    }
  );