import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const UserListing = ({ open, handleClose, userData }: any) => {
  const [value, setValue] = React.useState(0);

  const list = [0, 1, 2];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formatDate = (date: any) => {
    // var dt = new Date(date);
    // dt.setMinutes(dt.getMinutes() + 330);
    // // transformed date to IST
    // let transformedDate =
    //   dt.getFullYear() +
    //   "-" +
    //   (dt.getMonth() < 10 ? "0" + dt.getMonth() : dt.getMonth()) +
    //   "-" +
    //   dt.getDate() +
    //   " " +
    //   dt.toTimeString().split(" ")[0];
    // var d = new Date(date);
    // console.log("ddddddd ", d);
    // d.setHours(d.getHours() + 5);
    // d.setMinutes(d.getMinutes() + 30);
    let istDate = dayjs(date).add(330, "minute");
    let datestring = dayjs(istDate, "YYYY-MM-DD HH:mm:ss").format(
      "DD MMM, YYYY hh:mm a"
    );
    return datestring;
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="modal-title"
        >
          List Of Users
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    <Badge
                      badgeContent={userData?.registrations}
                      color="primary"
                      sx={{ p: "4px 6px" }}
                    >
                      Registered Users
                    </Badge>
                  }
                />

                <Tab
                  label={
                    <Badge
                      badgeContent={userData?.attended}
                      color="primary"
                      sx={{ p: "4px 6px" }}
                    >
                      Checked-In Users
                    </Badge>
                  }
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData?.registeredUsers?.length == 0 ? (
                      <Typography px={"15px"} variant="body2" fontSize="14px">
                        No registered user for this event.
                      </Typography>
                    ) : (
                      userData?.registeredUsers?.map(
                        (listItem: any, index: number) => (
                          <TableRow
                            key={listItem.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell scope="row">{listItem.name}</TableCell>
                            <TableCell scope="row">
                              {listItem.department}
                            </TableCell>
                            <TableCell scope="row">{listItem.email}</TableCell>
                            <TableCell scope="row">
                              {formatDate(listItem.date)}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData?.attendedUsers?.length == 0 ? (
                      <Typography px={"15px"} variant="body2" fontSize="14px">
                        No checked-in user for this event.
                      </Typography>
                    ) : (
                      userData?.attendedUsers?.map(
                        (listItem: any, index: number) => (
                          <TableRow
                            key={listItem.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell scope="row">{listItem.name}</TableCell>
                            <TableCell scope="row">
                              {listItem.department}
                            </TableCell>
                            <TableCell scope="row">{listItem.email}</TableCell>
                            <TableCell scope="row">
                              {formatDate(listItem.date)}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
export default UserListing;
