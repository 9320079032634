import React, { useEffect } from "react";
import Compressor from "compressorjs";
import { styled } from "@mui/material/styles";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import NativeSelect from "@mui/material/NativeSelect";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { allSlots } from "../../features/eventDetails/updateEventSlice";
import dayjs, { Dayjs } from "dayjs";
import {
  FormControl,
  Grid,
  Button,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import OutlinedInput from "@mui/material/OutlinedInput";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./event.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchEventCategories } from "../../features/eventDetails/eventCategorySlice";
// import {getAllDepartments} from '../../features/eventDetails/createEventSlice'
import { getImageName } from "../../helpers/functions";
import Toaster from "../toaster";
import { AnyARecord } from "dns";

interface file {
  lastModified: number;
  lastModifiedDate: {};
  name: string;
  size: number;
  type: string;
}
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "visible",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EventForm = ({
  open,
  handleClose,
  title,
  data,
  handleChange,
  submitForm,
  disablePublish,
}: any) => {
  const dispatch = useAppDispatch();
  const eventCategories = useAppSelector(
    (state) => state.eventCategories.eventCategories
  );
  // const departments = useAppSelector(
  //   (state) => state.createEvent.department
  // );
  const getEvent = useAppSelector((state) => state.eventDetails.details.event);
  const location = ["Select Location", "Bengaluru", "Pune", "Virtual"];
  const [toastMessage, setToastMessage] = React.useState("");
  const [openToaster, setOpenToaster] = React.useState(false);
  const [toastType, setToastType] = React.useState("");
  const [imageName, setImageName] = React.useState<Array<file>>([]);
  const [blobFile, setBlobFile] = React.useState([]);
  const [idToDelete,setIdToDelete] = React.useState<any>([])

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
  };

  const handleUploadClick = async (e: any) => {
    let images: Array<File> = e.target.files;
    let files = Array.from(images);

    const compressedFilesPromises = files.map((file) => compressImage(file));

    const compressedFiles: any = await Promise.all(compressedFilesPromises);
    handleFile(compressedFiles);
  };

  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        width: 1024,
        height: 1024,
        success(result: any) {
          resolve(new File([result], file.name, { type: result.type }));
          ///resolve(result)
        },
        error(err) {
          console.log(err.message);
        },
      });
    });
  };

  const handleFile = (files: File[]) => {
    let newImageNames: any;
    if (files && files.length > 0) {
      handleChange((prevData: any) => ({
        ...prevData,
        imageName: files[0].name,
      }));
      newImageNames = files.filter((file) => {
        return !newImageNames?.some((f: any) => f.name === file.name);
      });
      setImageName((prevData: any) => [...prevData, ...newImageNames]);
    } else {
      setImageName((prevData: any) => [...prevData, ...files]);
    }

    console.log("SetImageNames ", imageName);
  };

  const changeDateFormat = (date: Dayjs | null) =>
    dayjs(date).format("YYYY-MM-DD");
  const changeTimeFormat = (time: Dayjs | null) =>
    dayjs(time).format("HH:mm:ss");

  const handleFormChange = (e: any, keyName?: string) => {
    let name = e.target ? e.target.name : keyName;
    let value: any = "";
    if (e.target) {
      if (e.target.name == "eventCategory") {
        value = { id: e.target.value };
      } else {
        value = e.target.value;
      }
      if (e.target.name == "allslots") {
        if(title=="Create Event"){
        handleChange((prevData: any) => ({ ...prevData, ['totalSlots']: e.target.value }));
        }
        if(title=="Update Event"){
          let slotData={
            id:data.id,
            allslots: e.target.value
          }         
          if(parseInt(e.target.value)>getEvent.allslots) {    
          dispatch(allSlots(slotData)).then((res:any) => {
            if (res.meta.requestStatus == "fulfilled") {
            
            handleChange((prevData: any) => ({ ...prevData, ['totalSlots']: res?.payload?.availableslots }));
            }else{
              setToastType("error");
              setOpenToaster(true)

              setToastMessage("Give total slots should be greater than existing total slots.");
            }
          })     
        }else{
          setToastType("error");
          setOpenToaster(true)
          setToastMessage("Give total slots should be greater than existing total slots.");          
        }
        }
      } 
    } else {
      value = e;
    }
    handleChange((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const getImageName = (imageName: string) => {
    if (imageName?.includes("?")) {
      let name = imageName.split("?")[0].split("/");
      let img = name[name.length - 1];
      return img;
    } else return imageName;
  };
  const getIdByImage = (image:any)=>{
    const event = data.eventImages.find((event:any)=>event.imageName===image);
    return event?event.id:[]
}
  const deleteImage = (e: any, imageToDelete: string) => {
    // console.log("deleteImage ", imageToDelete);
    e.preventDefault();

    let imageArray = [...imageName];
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i].name === imageToDelete) {
        imageArray.splice(i, 1);
        break;
      }
    }
    if(title=='Update Event'){
    const id = getIdByImage(imageToDelete)
    //const idArray:any = []
    //idToDelete.push()
    idToDelete.push(id)
    setIdToDelete(idToDelete);
    
    console.log("id++",id)
    }
    //console.log("idArray",idArray)
    console.log("imageArray after delete",imageArray)
    setImageName(imageArray);
    
  };
  console.log("idToDelete+++",idToDelete)

  useEffect(() => {
    dispatch(fetchEventCategories());
    // dispatch(getAllDepartments()); Note: commented code for department lists for Phase 2
  }, []);

  useEffect(() => {
    if (data.eventImages?.length) {
      const imageData = data.eventImages.map((item: any) =>
        Object.assign({}, item, { name: getImageName(item.imageName) })
      );
      //   console.log(imageData);
      console.log("imageData",imageData)
      setImageName(imageData);
    }
  }, [data.eventImages]);
  console.log("imageName",imageName)
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="modal-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box component="form" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth required>
                  <DemoItem label="Event Name">
                    <OutlinedInput
                      name="title"
                      placeholder="Event Name"
                      size="small"
                      value={data.title}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Location">
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="location"
                      placeholder="Select"
                      value={data.location}
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput />}
                      size="small"
                    >
                      {/* <option aria-label="None" value="" /> */}
                      {location.map((lc: string, index: number) => {
                        return (
                          <option key={index} value={lc}>
                            {lc}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth required>
                  <DemoItem label="Venue">
                    <OutlinedInput
                      name="venue"
                      value={data.venue}
                      placeholder={
                        data.location == "Virtual"
                          ? "Meeting Id"
                          : "Event Venue"
                      }
                      id="bootstrap-input"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event Start Date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="eventStartDate"
                        format="DD/MM/YYYY"
                        disablePast
                        value={dayjs(data.eventStartDate)}
                        onChange={(newValue) =>
                          handleFormChange(
                            changeDateFormat(newValue),
                            "eventStartDate"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event Start Time">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en"
                    >
                      <TimePicker
                        name="eventStartTime"
                        aria-placeholder="HH:MM AA"
                        value={dayjs(data.eventStartTime, "HH:mm:ss")}
                        onChange={(newValue) =>
                          handleFormChange(
                            changeTimeFormat(newValue),
                            "eventStartTime"
                          )
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            error: false,
                            inputProps: { ["placeholder"]: "anything" },
                          },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event End Date">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="eventEndDate"
                        value={dayjs(data.eventEndDate)}
                        disablePast
                        onChange={(newValue) =>
                          handleFormChange(
                            changeDateFormat(newValue),
                            "eventEndDate"
                          )
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            error: false,
                            placeholder: "DD/MM/YYYY",
                          },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event End Time">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        name="eventEndTime"
                        value={dayjs(data.eventEndTime, "HH:mm:ss")}
                        onChange={(newValue) =>
                          handleFormChange(
                            changeTimeFormat(newValue),
                            "eventEndTime"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Registration Start Date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="registrationStartDate"
                        format="DD/MM/YYYY"
                        value={dayjs(data.registrationStartDate)}
                        disablePast
                        onChange={(newValue) =>
                          handleFormChange(
                            changeDateFormat(newValue),
                            "registrationStartDate"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Registration Start Time">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        name="registrationStartTime"
                        value={dayjs(data.registrationStartTime, "HH:mm:ss")}
                        onChange={(newValue) =>
                          handleFormChange(
                            changeTimeFormat(newValue),
                            "registrationStartTime"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Registration End Date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="registrationEndDate"
                        format="DD/MM/YYYY"
                        value={dayjs(data.registrationEndDate)}
                        disablePast
                        onChange={(newValue) =>
                          handleFormChange(
                            changeDateFormat(newValue),
                            "registrationEndDate"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Registration End Time">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        name="registrationEndTime"
                        value={dayjs(data.registrationEndTime, "HH:mm:ss")}
                        onChange={(newValue) =>
                          handleFormChange(
                            changeTimeFormat(newValue),
                            "registrationEndTime"
                          )
                        }
                        slotProps={{
                          textField: { size: "small", error: false },
                          popper: { style: { zIndex: 9999 } },
                        }}
                      />
                    </LocalizationProvider>
                  </DemoItem>
                </FormControl>
              </Grid>
              {/* <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Department">
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="dept"
                      value={data?.dept}
                      placeholder="Select Department"
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput />}
                      size="small"
                    >
                      <option value="">Select Department</option>
                      {departments.length>0&&departments.map((dep: any, index: number) => {
                        return (
                          <option key={index} value={dep}>
                            {dep}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </DemoItem>
                </FormControl>
              </Grid> */}
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event Category">
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="eventCategory"
                      placeholder="Select Category"
                      value={
                        data?.eventCategory?.id ? data?.eventCategory?.id : ""
                      }
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput />}
                      size="small"
                    >
                      <option value="">Select Category</option>
                      {eventCategories.map((ec: any, index: number) => {
                        return (
                          <option key={index} value={ec.id}>
                            {ec.name}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Contact Person">
                    <OutlinedInput
                      name="contact"
                      placeholder="Email Address"
                      required
                      size="small"
                      value={data.contact}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Total Slots">
                    <OutlinedInput
                      name="allslots"
                      placeholder="No. of Slots"
                      id="bootstrap-input"
                      size="small"
                      value={data.allslots}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>
              {title=='Update Event'&&
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Slots Available">
                    <OutlinedInput
                      name="totalSlots"
                      disabled
                      placeholder="No. of Slots"
                      id="bootstrap-input"
                      size="small"
                      value={data.totalSlots}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>}
              <Grid item xs={9}>
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Description">
                    <OutlinedInput
                      name="description"
                      multiline={true}
                      minRows={5}
                      placeholder="Event Description"
                      id="bootstrap-input"
                      size="small"
                      value={data.description}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </DemoItem>
                </FormControl>
              </Grid>
              <Grid item xs={3} direction="column">
                <FormControl variant="standard" fullWidth>
                  <DemoItem label="Event Banners:">
                    <Typography variant="caption" fontSize="11px">
                      Max. 5 Banners
                    </Typography>
                    <Button
                      component="label"
                      role={undefined}
                      disabled={imageName.length >= 5}
                      variant="outlined"
                      tabIndex={-1}
                      sx={{ textTransform: "capitalize" }}
                      className="image-upload-button"
                    >
                      Upload Photo
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        multiple={true}
                        onChange={(e: any) => handleUploadClick(e)}
                      />
                    </Button>
                  </DemoItem>
                </FormControl>
                {imageName.length > 0 && (
                  <Box
                    sx={{
                      display: "grid",
                      marginTop: "10px",
                    }}
                    gap={1}
                  >
                    {imageName.map((image: any, index: number) => {
                      return (
                        <OutlinedInput
                          key={index}
                          size="small"
                          value={image.name}
                          className="image-name-style"
                          sx={{ color: "primary" }}
                          endAdornment={
                            <InputAdornment position="end">
                              <DeleteOutlinedIcon
                                fontSize="small"
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => deleteImage(e, image.name)}
                              />
                            </InputAdornment>
                          }
                        />
                      );
                    })}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={(e) => submitForm(e, data, imageName,idToDelete)}
            className="mui-modal-button"
            disabled={disablePublish}
          >
            Publish
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Toaster
        openToaster={openToaster}
        toastMessage={toastMessage}
        handleToasterClose={() => setOpenToaster(false)}
      />
    </>
  );
};

export default EventForm;
