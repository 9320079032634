import * as React from 'react';
import dayjs from 'dayjs'


// frmat date as per the event date format
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export function formatDate(date:any){
    let datestring = dayjs(date, "YYYY-MM-DD").format('Do MMM, YYYY')
    return datestring;

}


// Add search functionality in the application

export function search(data: any,searchQuery: string,){
    let queryString = searchQuery.toLocaleLowerCase();
    return data.filter((dataBlock: any) => {
        return Object.keys(dataBlock).some(key =>
            typeof dataBlock[key] === "string" && dataBlock[key].toLowerCase().includes(queryString)
          );
    })
}

export function getImageName(imageName:string){
    if(imageName?.includes('?')){
        let name = imageName.split('?')[0].split('/');
        let img = name[name.length-1]; 
        return img; 
    } else return imageName;
    
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name: string) {
    if (name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                fontSize: '5em'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    else {
        return "";
    }
}