import React from "react"
import { Box, Snackbar } from "@mui/material"

const Toaster = ({toastMessage, type, openToaster, handleToasterClose}:any) => {
    React.useEffect(()=>{
        checkType();
    },[])
    const checkType = ()=> {
        switch(type) {
            case 'success':
              return '#07bc0c';
            case 'error':
                return '#e74c3c';
            
            default:
              return '#3498db';
          }
    }
    return (
        <Box sx={{ width: 500 }}>
            <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openToaster}
                    autoHideDuration={3000}
                    onClose={handleToasterClose}
                    message={toastMessage}
                    key='topright'
                    sx={{ backgroundColor: checkType()+'!important', top: '90px!important' }}
                />
            </Box>
    )
}
export default Toaster