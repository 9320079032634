import { useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React from "react";
import EventCarousel from "../components/eventDetails/eventCarousel";
import { useParams } from "react-router-dom";
import DetailsGrid from "../components/eventDetails/detailsGrid";
import EventCard from "../components/card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../components/eventDetails/eventDetails.css";
import QRCode from "react-qr-code";
import dayjs from "dayjs";
import Feedback from "../components/event/feedback";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchEventDetails } from "../features/eventDetails/eventDetailsSlice";
import { logAnalyticsEvent } from "../utils/firebaseConfig";
import {
  exploreEvent,
  listEvent,
  topEvent,
} from "../features/eventDetails/eventListSlice";
import { useSearchParams } from "react-router-dom";
import { checkInForEvent } from "../features/eventDetails/eventCheckInSlice";
import Loader from "../components/loader";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface eventDetails {
  contact: string;
  createdAt: string;
  createdBy: string;
  deleted: boolean;
  description: string;
  eventEndTime: string;
  eventStartDate: string;
  eventEndDate: string;
  id: number;
  imageName: string | null;
  imagePath: string | null;
  location: string;
  qrCode: string;
  eventStartTime: string;
  title: string;
  totalSlots: number;
  allslots: number;
  updatedAt: string;
  code: string;
  userRegistered: Boolean;
  checkInStatus: Boolean;
  completedStatus: Boolean;
}

const EventDetails = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const details = useAppSelector((state) => state.eventDetails.details.event);
  const eventData = useAppSelector((state) => state.eventList.exploreEvents);
  const [events, setEvents] = React.useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const reduxEventId = useAppSelector((state) => state.eventDetails.eventId);
  const [eventDetails, setEventDetails] = React.useState<eventDetails>();
  const [upcomingEvent, setUpcomingEvent] = React.useState(false);
  const [showQRCode, setshowQRCode] = React.useState(false);

  const user = useAppSelector((state) => state.userProfile.profile);
  const {id}=useParams()
  const eventDataId = localStorage.getItem("eventId");

  logAnalyticsEvent("Event Details");
  useEffect(() => {
    const eventId = id
    ? id
    : eventDataId
    dispatch(fetchEventDetails(eventId)).then((res: any) => {
      setEventDetails(res?.payload?.event);
      setIsLoading(false);      
       if(res.meta.requestStatus === 'fulfilled'){
        console.log('event details fetched fulfilled');
        localStorage.removeItem("redirect_after_login");
       }
    });
    dispatch(exploreEvent());
    console.log("hit>>>")
  }, [id,eventDataId]);

  useEffect(() => {
    if (reduxEventId) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [reduxEventId]);

  useEffect(() => {
    let eventId = localStorage.getItem("eventId");

    if (eventData && eventData.length > 0) {
      let eventList = eventData
        ?.map((e: any) => {
          if (e.id != eventId) return e;
        })
        .filter(Boolean)
        .slice(0, 5);

      setEvents(eventList);
    }
    if (details) {
      let eventStartDate = new Date(
        details.eventStartDate + " " + details?.eventStartTime
      );
      let eventEndDate = new Date(
        details.eventEndDate + " " + details?.eventEndTime
      );
      eventEndDate.setDate(eventEndDate.getDate() + 2);
      let status =
        dayjs().isAfter(dayjs(eventStartDate)) &&
        dayjs().isBefore(dayjs(eventEndDate));
      setUpcomingEvent(status);

      if (user.userType == "ADMIN") {
        setshowQRCode(true);
      } else {
        setshowQRCode(Boolean(details.userRegistered));
      }
    }
  }, [eventData, reduxEventId]);

  return (
    <>
      {!isLoading ? (
        <Container disableGutters sx={{ px: 5 }}>
          <Typography variant="h3" mt={4} mb={2} sx={{ fontSize: "46px" }}>
            {eventDetails && eventDetails.title}
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  {eventDetails && <EventCarousel events={[eventDetails]} />}
                  {eventDetails && eventDetails.description && (
                    <Typography
                      variant="body1"
                      sx={{ py: 2, fontSize: "18px" }}
                    >
                      {eventDetails.description
                        .split("\n")
                        .map((paragraph, index) => (
                          <span key={index}>
                            {paragraph}
                            <br />
                          </span>
                        ))}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <DetailsGrid eventData={eventDetails} />
              {eventDetails &&
                // eventDetails.userRegistered &&
                // !eventDetails.completedStatus &&
                // !eventDetails.checkInStatus &&
                showQRCode &&
                upcomingEvent && (
                  <Paper elevation={0} sx={{ p: 2 }}>
                    <QRCode
                      value={`${process.env.REACT_APP_API_URL}/?state=${details.code}-${details.id}`}
                      size={180}
                    />
                    <Typography variant="body2" fontSize="18px">
                      Scan the QR Code to mark your attendance
                    </Typography>
                  </Paper>
                )}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Loader />
      )}

      <Box
        px={details.completedStatus ? 1 : 8}
        py={details.completedStatus ? 1 : 8}
        sx={{ backgroundColor: "#F1F0F0", height: "auto", flexGrow: 1, mt: 2 }}
      >
        {details.completedStatus ? (
          <Feedback eventDetails={details} />
        ) : (
          <>
            <Typography variant="h4" align="left" py={3}>
              Explore More Events
            </Typography>

            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all 0.75s ease-in-out"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              {events &&
                events.length > 0 &&
                events.map((event, index) => (
                  <Grid key={index} item xs={2} sm={4} md={4} sx={{ mx: 1 }}>
                    <EventCard key={index} event={event} />
                  </Grid>
                ))}
            </Carousel>
          </>
        )}
      </Box>
    </>
  );
};
export default EventDetails;
