import * as React from "react";
import Box from "@mui/material/Box";
import Compressor from "compressorjs";
import {
  Button,
  Container,
  Grid,
  Typography,
  Avatar,
  Paper,
  Modal
} from "@mui/material";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createFeedback, deleteFeedbackImg } from "../../features/home/feedbackSlice";
import {fetchEventDetails} from '../../features/eventDetails/eventDetailsSlice'
import {
  uploadFile,
  uploadAdminFiles,
} from "../../features/common/fileUploadSlice";
import { createTestimonial } from "../../features/home/impactStoriesSlice";
import { uploadFeedbackImages } from "../../features/eventDetails/uploadFeedbackImagesSlice";
import "./event.css";
import DownloadIcon from '@mui/icons-material/Download';
import Toaster from "../toaster";

export default function Feedback({ eventDetails }: any) {
  const dispatch = useAppDispatch();
  const [ratings, setRatings] = React.useState<number | null>(null);
  const [feedback, setFeedback] = React.useState<string | null>("");
  const [testimonial, setTestimonial] = React.useState<string | null>("");
  const [survey, setSurvey] = React.useState<string | null>("");
  const [files, setFiles] = React.useState("");
  const [imageName, setImageName] = React.useState<Array<File>>([]);
  const [imgName, setImgName] = React.useState("");
  const [openToaster, setOpenToaster] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [compressedFiles, setCompressedFiles] = React.useState<Array<File>>([]);
  const [isApi,setIsApi] = React.useState(false)
  const[fileUploadData,setFileUploadData]=React.useState<string | null>("");
  const feedbackImages = useAppSelector((state) => state.eventDetails.details.feedbackImages);
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   const [submitButton, setSubmitButton] = React.useState(false);
  const user = useAppSelector((state) => state.userProfile.profile);
  const fileUploadStatus:any = useAppSelector((state) => state.fileUpload.file);
  const shortId = localStorage.getItem("shortId");
  React.useEffect(()=>{
    console.log("fileUploadState",fileUploadData)
    setFileUploadData(fileUploadStatus)
  },[imgName])
  console.log("imgName", imgName)
  const submitHandler = (e: any) => {
    e.preventDefault();
    var output:any = [];
    let userId = localStorage.getItem("userId");

    let testimonialPayload = {
      userId: userId,
      description: testimonial,
      isDeleted: false,
      displayStatus: false,
    };
    if (testimonial != null && testimonial != "") {
      // added to testimonial
      if (user.userType == "USER") {
        dispatch(createTestimonial(testimonialPayload));
      }
    }

    //let feedbackImages = [];
    let feedbackImages = [
      {
        imageUrl: "",
        imageName: "",
      },
    ];

    let formData = new FormData();

    for (let index = 0; index < compressedFiles.length; index++) {
      formData.append("file", compressedFiles[index]);
      feedbackImages.push({
        imageUrl: "",
        imageName: imageName[index]["name"],
      });
    }
    console.log("Feedback Image Payload >>", feedbackImages);
    if (files.length > 0) {
      // Images will be uploaded to Container
      //   dispatch(uploadFile(formData)).then((res) => {
      //     if (res.meta.requestStatus == "fulfilled") {
      //       console.log("Images uploaded!");
      //     }
      //   });
      // dispatch(uploadFilesToServer(formData));
      // dispatch(uploadFilesToServer(formData));
      if (user.userType == "ADMIN") {  
        let data = {
          formData,
          id: eventDetails.id,
        }

        dispatch(uploadAdminFiles(data)).then((res) => {
          if (res.meta.requestStatus == "fulfilled") {
            console.log("Images uploaded!");
            setToastMessage("Images saved sucessfully");
            setOpenToaster(true);
            setCompressedFiles([])
            if(ratings&&feedback&&survey){
              let postObj = {
                event: {
                  id: eventDetails.id,
                },
                userId: userId,
                rating: ratings,
                imageName: res.payload,
                comments: feedback,
                feedbackSurvey: survey,
              };
              var arr = res.payload.split(',')
              dispatch(createFeedback(postObj)).then((res: any) => {
                arr.map((i: any) => {
                  let feedbackImagesPayload = {
                    feedback: {
                      id: res?.payload?.id,
                    },
                    imageName: i,
                  };
                  // feedback Images
                  dispatch(uploadFeedbackImages(feedbackImagesPayload));
                });
                setToastMessage("Feedback submitted sucessfully");
                setOpenToaster(true);
           
              });
            }
          }
        });
      } else {
        dispatch(uploadFile(formData)).then((res) => {
          if (res.meta.requestStatus == "fulfilled") {
            let postObj = {
              event: {
                id: eventDetails.id,
              },
              userId: userId,
              rating: ratings,
              imageName: res.payload.replace(/\s*,\s*/g, ","),
              comments: feedback,
              feedbackSurvey: survey,
            };
            var arr = res.payload.split(',')
            if (user.userType == "USER") {
            dispatch(createFeedback(postObj)).then((res: any) => {
              arr.map((i: any) => {
                let feedbackImagesPayload = {
                  feedback: {
                    id: res?.payload?.id,
                  },
                  imageName: i.trim()
                };       
                // feedback Images
                dispatch(uploadFeedbackImages(feedbackImagesPayload));
              });
              setToastMessage("Feedback submitted sucessfully");
              setOpenToaster(true);
            });
          }
        }
        });
      }
      console.log("imageName in state",imgName)
    }
    console.log("imageName in state",imgName)
    console.log("fileUploadData", fileUploadData)
    console.log("output",output)

    // Reset fields
    setFeedback("");
    setRatings(null);
    setTestimonial("");
    setSurvey("");
  };

  const checkSubmitButton = () => {
    let status;
    if (user && user.userType == "ADMIN" ) {
      status = false;
    } else {
      if (
        eventDetails.checkInStatus == true &&
        feedback !== "" &&
        testimonial !== "" &&
        survey !== ""
      ) {
        status = false;
      } else {
        status = true;
      }
    }
    return status;
  };

  const handleToasterClose = () => {
    setOpenToaster(false);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "visible",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        width: 1024,
        height: 1024,
        success(result: any) {
          resolve(new File([result], file.name, { type: result.type }));
          // resolve(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    });
  };

  const handleFile = (files: File[]) => {
    let newImageNames: any;
    if (files && files.length > 0) {
      newImageNames = files.filter((file) => {
        return URL.createObjectURL(file)
      });   
      setImageName((prevData: any) => [...prevData, ...newImageNames]);
    } else {
      setImageName((prevData: any) => [...prevData, ...files]);
    }
  };

  /*function renameFiles(fileList:any,newNames:any)  {
    const dataTransfer = new DataTransfer();
    for(let i=0;i<fileList.length;i++){
        const file = fileList[i];
        const newName = newNames[i];
        const newFile = new File([file],newName,{type:file.type});
        dataTransfer.items.add(newFile);
    }
return dataTransfer.files
}*/

  const handleUploadClick = async (e: any) => {
    /*const ModifiedFileArray:any = Array.from(e.target.files).map((file:any,index:any)=>
         {
            console.log("file+++",file['name'].split('.'))
            return `${shortId}_${Date.now()}_${index+1}.${file['name'].split('.')[1]}`
        })*/
    
    let image: Array<File> = e.target.files;
    setFiles(e.target.files);
    const files = Array.from(image);
    const compressedFilesPromises = files.map((file) => compressImage(file));
    const compressedFile: any = await Promise.all(compressedFilesPromises);
    handleFile(compressedFile);   
    ///const renamedFileList:any = renameFiles(compressedFiles,ModifiedFileArray)
    // console.log("compressedFiles in feedback.tsx", compressedFiles);
    compressedFile.map((file:any)=>{
      compressedFiles.push(file)
    })
    console.log("file inside map",compressedFiles)
    const modifiedFile = compressedFile.map((file:any,index:any)=>{
    
        const modifiedFileName = `${shortId}_${Date.now()}_${index+1}.${file['name'].split('.')[1]}`
        return modifiedFileName
        //return setImgName(modifiedFileName)
      })
    /////setImgName(modifiedFile.toString())
  };

  const deleteImage = (e: any, imageToDelete: string) => {
    e.preventDefault();

    let imageArray = [...imageName];
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i].name === imageToDelete) {
        imageArray.splice(i, 1);   
        break;
      }
    }
    setImageName(imageArray);
    setCompressedFiles(imageArray)
  };

  React.useEffect(() => {
    console.log("Event details >> ", feedbackImages,imageName);
    const names:any = feedbackImages?.map((image:any)=>{return {url:image.imageName,id:image.id}})
    setImageName(names)
   
  }, [feedbackImages]);

function Item(props: any) {
  const deleteExistingImage = (id:any)=>{
       dispatch(deleteFeedbackImg(id)).then((res: any) => {
        setOpenToaster(true)
        setToastMessage("Image deleted successfully!");
        dispatch(fetchEventDetails(eventDetails.id))
      })    
  }
  return (
    <Paper>
      <IconButton
      disableRipple={true}
        aria-label="settings"
        sx={{ background: "white", color: "#0078D6", float: "right" }}
      >
       {typeof props.item?.url == "string"&& <a href={props.item.url}><DownloadIcon  sx={{marginRight:1,color:'#808080',marginTop:1}} /></a>}
        {typeof props.item?.url == "string" &&
        user.userType == "USER" ? null : (
          <DeleteIcon
            className="delete-icon"
            onClick={(e) =>
              typeof props.item?.url == "string" && user.userType == "ADMIN"
                ? deleteExistingImage(props.item?.id)
                : props.deleteImage(e, props.item.name)
            }
          />
        )}
      </IconButton>
      <Button
      
        onClick={() => {
          handleOpen();
          typeof props.item?.url == "string"
            ? setUrl(props.item.url)
            : setUrl(URL.createObjectURL(props.item));
        }}
      >
        <Avatar
          sx={{ width: "95%", height: 440, m: "0px auto", borderRadius: "0" }}
          src={
            typeof props.item?.url == "string"
              ? props.item.url
              : URL.createObjectURL(props.item)
          }
          alt="highlight-image"
        ></Avatar>
      </Button>
    </Paper>
  );
}

// const downloadZip = async () => {
//   const zip = new JSZip();
//   const images = [
//    { id:1,url:'https://images.pexels.com/photos/214574/pexels-photo-214574.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'},
//    { id:2,url:'https://images.pexels.com/photos/214574/pexels-photo-214574.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'},
//     // Add your image URLs here
//   ];
//   // Fetch each image and add it to the zip file
//   const fetchImagePromises = images.map(async (image:any) => {
   
//       const response = await fetch(
//        image.url
//       );
//       if(response.ok){
//         console.log('ress',response);
//         const blob =  response?.blob();
//           zip.file(image.id, blob);
//       }
    
    
  
//   });

//   // Wait for all images to be added to the zip
//   await Promise.all(fetchImagePromises);

//   // Generate the zip file and trigger the download
//   zip.generateAsync({ type: "blob" }).then((content) => {
//     saveAs(content, "images.zip");
//   });
// };

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  p: 4,
};
  return (
    <React.Fragment>
      <Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
         <Avatar
        sx={{ width: "95%", height: 440, m: "0px auto", borderRadius: "0" }}
        src={url}
        alt="highlight-image"
      ></Avatar>
         </Box>
  </Modal>
        <Box mb={4} mt={4} sx={{ flexGrow: 1 }}>
          <Grid pt={8} pb={8} container rowSpacing={1}>
            <Grid item md={4}>
              <Typography variant="h2">Feedback Corner</Typography>
              {eventDetails.checkInStatus !== true &&
                user &&
                user.userType == "USER" &&  (
                  <Typography variant="caption" sx={{ color: "#f00000" }}>
                    <b>Note:</b> Only Checked-In users are allowed to submit the
                    feedback
                  </Typography>
                )}

              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, ml: 0 },
                }}
                noValidate
                autoComplete="off"
                mt={4}
              >
                <Typography component="legend" fontSize="18px">
                  How would you rate the experience?
                </Typography>
                <Rating
                  name="controlled"
                  value={ratings}
                  disabled={
                    eventDetails.checkInStatus == true ||
                    (user && user.userType == "ADMIN")
                      ? false
                      : true
                  }
                  sx={{ fontSize: "55px" }}
                  onChange={(event, newValue) => {
                    setRatings(newValue);
                  }}
                />
              </Box>

              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "100%", ml: 0, pt: 2 },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="feedback"
                  label="Feedback about the event"
                  variant="standard"
                  onChange={(e: any) => setFeedback(e.currentTarget.value)}
                  required={true}
                  value={feedback}
                />
                <TextField
                  id="testimonial"
                  label="Testimonial submission"
                  variant="standard"
                  onChange={(e: any) => setTestimonial(e.currentTarget.value)}
                  required
                  value={testimonial}
                />
                <TextField
                  id="survey"
                  label="Detailed feedback survey"
                  variant="standard"
                  onChange={(e: any) => setSurvey(e.currentTarget.value)}
                  required
                  value={survey}
                />

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: 0,
                    marginTop: "45px!important",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                  onClick={(e) => submitHandler(e)}
                  disabled={checkSubmitButton()}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "end",
                  marginBottom: "40px",
                }}
              >
                <Typography variant="h2">Photo gallery</Typography>
                <Typography
                  sx={{ alignContent: "right", flexGrow: 1 }}
                  variant="body1"
                  color="primary.main"
                >
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    sx={{ textTransform: "capitalize" }}
                    disabled={
                      eventDetails.checkInStatus == true ||
                      (user && user.userType == "ADMIN")
                        ? false
                        : true
                    }
                    className="image-upload-button feedback-upload"
                  >
                    Upload Photo
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      multiple={true}
                      onChange={(e: any) => handleUploadClick(e)}
                    />
                  </Button>
                  {/* <Button
                  onClick={downloadZip}
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    sx={{ textTransform: "capitalize" }}
                    disabled={
                      eventDetails.checkInStatus == true ||
                      (user && user.userType == "ADMIN")
                        ? false
                        : true
                    }
                    className="image-upload-button feedback-upload"
                  >
                   <DownloadIcon  sx={{color:'blue'}}/> images
                    </Button> */}
                </Typography>
              </Box>
              {imageName.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                  }}
                  gap={2}
                ></Box>
              )}
              <Box>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all 2s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  itemClass="carousel-item-padding-40-px"
                  arrows={true}
                >
                  {imageName &&
                    imageName.length > 0 &&
                    imageName.map((item, i) => (
                      <Item key={i} item={item} deleteImage={deleteImage} />
                    ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
          <Toaster
            openToaster={openToaster}
            toastMessage={toastMessage}
            severity="success"
            handleToasterClose={handleToasterClose}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

