import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventState {
    eventStats: [],
    eventUserRegisteredCheckInStats:[]
}

const initialState:eventState = {
    eventStats: [],
    eventUserRegisteredCheckInStats:[]
}

const eventStatsSlice = createSlice({
    name: 'eventStats',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(eventYearlyCombinedStats.fulfilled, (state, action) => {
            //console.log("Payload >> ",action.payload);
            state.eventStats = action.payload
          })
          .addCase(combinedEventAndRegisteredUserStatistics.fulfilled, (state, action) => {
            //console.log("Payload >> ",action.payload);
            state.eventStats = action.payload
          })
          .addCase(getMonthlyEventsData.fulfilled, (state, action) => {
            state.eventUserRegisteredCheckInStats = action.payload
          })
          
      },
}
)

export default eventStatsSlice.reducer;

export const eventYearlyCombinedStats = createAsyncThunk('events/combinedEventStatistics', async () => {
    const response = await axiosInstance.get(`api/events/admin/combinedEventStatistics`)
    return response.data;
})

export const combinedEventAndRegisteredUserStatistics = createAsyncThunk('events/combinedEventAndRegisteredUserStatistics', async () => {
    const response = await axiosInstance.get(`api/events/admin/combinedEventAndRegisteredUserStatistics`)
    return response.data;
})

export const getMonthlyEventsData = createAsyncThunk('events/getMonthlyEventsData', async (data:any) => {
    const response = await axiosInstance.get(`api/events/admin/getEventIdDetails?month=${data.month}&year=${data.year}`)
    return response.data;
})
