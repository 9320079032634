import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { faq } from '../helpers/constant';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/material';
import './style.css';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '0px',
  '&:not(:last-child)': {
    borderBottom: '1px solid #c0c0c0',
    
  },
  '&::before': {
    display: 'none',
  },
}));

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
          transform: 'none !important',
        },
        ".expandIconWrapper": {
          display: "none",
          transform: 'none !important',
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
          transform: 'none !important',
        }
      }}
    >
      <div className="expandIconWrapper"><RemoveCircleOutlineIcon fontSize='large' sx={{color: '#0078D6' }} /></div>
      <div className="collapsIconWrapper"><AddCircleOutlineIcon fontSize='large' sx={{color: '#fff' }} /></div>
    </Box>
  );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<CustomExpandIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    textDecoration: 'underline',
    color: '#0078D6'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  background: '#000',
  color: '#d0d0d0',
  paddingLeft: theme.spacing(4.5)
}));

export default function CustomizedAccordions({faq}:any) {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
        { faq && faq.map ((f:any, index:number) => {
            return(
        <Accordion key={f.id} expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography pl={2} variant='h6' sx={{fontWeight:'400!important'}} dangerouslySetInnerHTML={{__html: f.question}}></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className="faq-answers" pl={5} pb={2} dangerouslySetInnerHTML={{__html: f.answer}}>
            </Typography>
            </AccordionDetails>
        </Accordion>

            )
        })}
      
    </div>
  );
}