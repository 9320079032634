import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventState {
    updateEventBody: {},
    allSlots: {}
}

const initialState:eventState = {
    updateEventBody: {},
    allSlots: {}
}

const updateEventSlice = createSlice({
    name: 'updateEvent',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(updateEvent.fulfilled, (state, action) => {
            state.updateEventBody = action.payload
          })
          .addCase(allSlots.fulfilled, (state, action) => {
            state.allSlots = action.payload
          })       
      },
}
)

export default updateEventSlice.reducer;

export const updateEvent = createAsyncThunk('events/updateEvent', async (data:any) => {
    console.log("data inside updateEvent",data.get('event'))
    const dataEvent = data.get('event');
    const dataValue = JSON.parse(dataEvent)
    const response = await axiosInstance.put(`api/events/admin/updateEventById/${dataValue.id}`, data)
    return response.data;
})
export const allSlots = createAsyncThunk('events/getSlotsData', async (slotData:any) => {
    const response = await axiosInstance.get(`/api/events/getSlotsData/eventId/${slotData.id}/allSlots/${slotData.allslots}`)
    return response.data;
})