import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Container, Link } from '@mui/material';
import logo from '../static/images/logo-mbrdi.svg'
import { pages } from '../helpers/constant'
import { useAppSelector } from '../app/hooks';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const drawerWidth = 240;

export default function NavigationBar() {
  const navigate = useNavigate();
  const user = useAppSelector((state)=>state.userProfile.profile)
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState:Boolean) => !prevState);
  };

  const navigateTo = (e:any, path:string) =>{
    e.preventDefault();
    navigate(path);
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Avatar alt='logo' src={logo} sx={{ m: 1, borderRadius: 0, width: '240px', height: '50px'}}/>
      </Typography>
      <Divider />
      <List>
        {pages.map((page, index)=>{
          return (
            <>
            {page.title === 'Dashboard' && user && user.userType !== 'ADMIN'?'':(
            <ListItem key={page.link} component={RouterLink} to={page.link} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={page.title} />
            </ListItemButton>
          </ListItem> 
          )}</>
          )
        })}
      </List>
    </Box>
  );

  return (
    <Container maxWidth={false} disableGutters className='header-navigation'>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="sticky" component="nav" sx={{background: '#000000', boxShadow:'none'}}>
        <Toolbar sx={{padding: '0.2rem 0'}}>
          <Avatar alt='logo' src={logo} sx={{ m: '0.6rem' , borderRadius: 0, width: '229px', height: '50px', cursor:'pointer', marginLeft:'38px'}} component={RouterLink} to={"/"} />
            
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end'}}>
            {pages.map((page) => {
              return (
                <>
                {page.title === 'Dashboard' && user && user.userType !== 'ADMIN'?'':
              (
                <Button key={page.link} sx={{ color: '#fff', mr: '2.39rem', textTransform: 'none', fontWeight: 300 }} component={RouterLink} to={page.link}>
                <Typography className='navigationLink' variant='body1' sx={{fontSize:'18px'}}>{page.title}</Typography>
              </Button>
              )}
                </>
              )
              
})}
          </Box>
          <Box sx={{display: { flexGrow: 1, sm: 'none', justifyContent: 'flex-end' }}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' }, float:'right', mr:'2%' }}
          >
            <MenuIcon />
          </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </>
    </Box>
    </Container>
  );
}