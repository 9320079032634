import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Toaster from "./toaster";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { deleteFeedbackImg } from "../features/home/feedbackSlice";
import { fetchProfileDetails } from "../features/profile/profileDetailsSlice";

const ImageGallery = (props: any) => {
  const sm = useMediaQuery("(max-width:600px)");
  const dispatch = useAppDispatch();
  const [feedbackImage, setFeedbackImage] = React.useState<Array<File>>([]);
  const [gallery, setGallery] = React.useState<Array<File>>(props.gallery);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastType, setToastType] = React.useState("");
  const [openToaster, setOpenToaster] = React.useState(false);

  React.useEffect(() => {
    setFeedbackImage(props.feedbackImageName)
  },[props])

  const deleteImage = (e: any, imageToDelete:any) => {
    e.preventDefault();
    dispatch(deleteFeedbackImg(imageToDelete)).then((res: any) => {
      console.log('res del',res);
      setOpenToaster(true)
      setToastMessage("Image deleted successfully!");
      dispatch(fetchProfileDetails());
    })
  };
  const handleToasterClose = () => {
    setOpenToaster(false);
  };
  return (
    <>
             <Toaster
        openToaster={openToaster}
        type={toastType}
        toastMessage={toastMessage}
        handleToasterClose={handleToasterClose}
      />
      <ImageList
        className="gallery-image-list"
        cols={sm ? 1 : 2}
        rowHeight="auto"
        gap={30}
        aria-label="gallery"
      >
        {feedbackImage && feedbackImage.length ? (
          feedbackImage?.map((item: any, index: number) =>
             (
              <ImageListItem key={index}>
                <img srcSet={item.imageName} src={item.imageName} alt="image" loading="lazy" />
                <IconButton
        aria-label="settings"
        sx={{ background: "white", color: "#0078D6", float: "right" }}
      >
        <DeleteIcon onClick={(e) => deleteImage(e, item.id)} />
      </IconButton>
              </ImageListItem>
            )
          )
        ) : (
          <Typography variant="body1">No image added!</Typography>
        )}
      </ImageList>
    </>
  );
};
export default ImageGallery;
