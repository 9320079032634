import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Avatar, Typography } from '@mui/material';
import './home.css'
import VolunteerIcon from '../../static/images/volunteers-new.png';
import HourIcon from '../../static/images/hours-new.png';
import EventIcon from '../../static/images/events-new.png';
import { socialImpactCount } from '../../features/home/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

export default function SocialImpact() {

    const socialCounter = useAppSelector((state) => state.home.socialImpact);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(socialImpactCount())
    }, [])

    return (
        <React.Fragment>
            <Container className='dark-bg' disableGutters maxWidth={false}>
                <Box mb={4} sx={{ flexGrow: 1 }}>
                    <Grid pt={4} pb={4} justifyContent="center" alignItems="center" container rowSpacing={1} >
                        <Grid item md={7} textAlign={'center'} >
                            <Typography variant='h2'>Volunteering Impact at MBRDI</Typography>
                            <Typography mt={2} variant='body1' className='subtext' sx={{ fontSize: '18px' }}>All volunteering opportunities are closely aligned with our CSR strategy focusing on Sustainable Mobility, Environmental Sustainability, and Diversity and Inclusion.</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={4} mb={4} sx={{ flexGrow: 1 }}>
                    <Grid justifyContent="center" alignItems="center" container rowSpacing={1} >
                        {/* <Grid item md={8} sm={12}  textAlign={'center'} > */}

                            <Grid pb={8} spacing={1} container textAlign={'center'} rowSpacing={1} columnSpacing={2}>
                                <Grid item md={4} sm={4} xs={4}>
                                    <Avatar sx={{ width: 50, height: 50, borderRadius: 0, margin: '0px auto', marginBottom: '10px' }} alt='volunteersIcon' src={VolunteerIcon} />
                                    <Typography variant='h3'>{socialCounter.csrUser}</Typography>
                                    <Typography className='h6' variant='body1' sx={{ fontSize: '18px', color: '#D9D9D9' }}>Volunteers</Typography>
                                </Grid>
                                <Grid item md={4} sm={4} xs={4}>
                                    <Avatar sx={{ width: 50, height: 50, borderRadius: 0, margin: '0px auto', marginBottom: '10px' }} alt='hoursIcon' src={HourIcon} />
                                    <Typography variant='h3'>{socialCounter.hours}</Typography>
                                    <Typography className='h6' variant='body1' sx={{ fontSize: '18px' }}>Hours</Typography>
                                </Grid>
                                <Grid item md={4} sm={4} xs={4}>
                                    <Avatar sx={{ width: 50, height: 50, borderRadius: 0, margin: '0px auto', marginBottom: '10px' }} alt='eventsIcon' src={EventIcon} />
                                    <Typography variant='h3'>{socialCounter.events}</Typography>
                                    <Typography className='h6' variant='body1' sx={{ fontSize: '18px' }}>Events</Typography>
                                </Grid>
                            </Grid>

                        {/* </Grid> */}
                    </Grid>

                </Box>
            </Container>
        </React.Fragment>
    )
}