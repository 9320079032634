import React, { useRef, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Slider from '../components/home/slider';
import VolunteeringEvent from '../components/home/events';
import SocialImpact from '../components/home/socialImpact';
import Spocs from '../components/home/spocs';
import ImpactStories from '../components/home/impactStories';
import Faq from '../components/home/faq';
import Highlight from '../components/home/highlight';
import { logAnalyticsEvent } from "../utils/firebaseConfig";
import { Box, Button } from '@mui/material';

export default function LandingPage() {
  logAnalyticsEvent("CSR_Home");
  const SPOCsRef = useRef<HTMLDivElement | null>(null);
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);
  const prevScrollPos = useRef(0);

  const toggleVisibility = () => {
    const currentScrollPos = window.scrollY; 
    // scroll to top will be displayed when user scrolling for 300 pixels
    if (currentScrollPos > 300) {
      setIsScrollTopVisible(true);
    } else {
      setIsScrollTopVisible(false);
    }
    prevScrollPos.current = currentScrollPos;
  };

  const scrollToTop = () => {
    let currentPath = window.location.pathname;
    if (currentPath.includes('spocs')) {
      window.location.pathname = '';
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let currentPath = window.location.pathname;
    if (currentPath.includes('spocs')) {
      if (SPOCsRef) {
        SPOCsRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Slider />
      <VolunteeringEvent />
      <SocialImpact />
      <Spocs ref={SPOCsRef} />
      <ImpactStories />
      <Faq />
      <Highlight />
      {isScrollTopVisible && 
      <Box sx={{
        position: 'fixed',
        textTransform: "initial",
        fontSize: "15px",
        borderRadius: 0,
        fontWeight: 400,
        bottom: '20px', 
        right: '0px', 
        width: '120px', 
        height: 30,
        borderWidth: '60px',
        zIndex: 1,
      }} pb={8} textAlign={"center"}>
        <Button
          onClick={() => scrollToTop()}
          sx={{ backgroundColor: '#000', textTransform:'none', borderRadius: 2, fontWeight: 400, fontSize: '18px'}}
        >
        <img width='50px' style={{transform: 'rotate(180deg)'}} src={require('../static/images/first_page.svg').default} alt='double_up_arrow' />
      </Button>
    </Box>}
    </Container>
  );
}
