import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { testimonials } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface highlightsState {
  imageList: [];
}

const initialState: highlightsState = {
  imageList: [],
};

const highlightsSlice = createSlice({
  name: "highlights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHighlightsImageList.fulfilled, (state, action) => {
      state.imageList = action.payload.feedbackImages;
    });
  },
});

export default highlightsSlice.reducer;

export const fetchHighlightsImageList = createAsyncThunk(
  "feedbackImages/getAllFeedbackHighlights",
  async () => {
    const response = await axiosInstance.get(
      "api/feedbackImages/getAllHighlights"
    );
    return response.data;
  }
);

export const editHighlightsImageList = createAsyncThunk(
  "feedbackImages/editAllFeedbackHighlights",
  async (data: any) => {
    const response = await axiosInstance.put(
      "api/feedbackImages/admin/updateHighlightImage",
      data
    );
    return response.data;
  }
);

export const deleteHighlightsImage = createAsyncThunk(
  "feedbackImages/deleteHighlightsImage",
  async (id: any) => {
    const response = await axiosInstance.delete(
      `api/feedbackImages/admin/deleteHighlightImage/${id}`
    );
    return response.data;
  }
);
