import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const labels = ['Events', 'Volunteers', 'Hours']

const PieChart = ( { yearlyStats }:any) => {

    const [totalHours, setTotalHours] = useState<any>([])
    const [totalEvents, setTotalEvents] = useState<any>([])
    const [totalVolunteers, setTotalVolunteers] = useState<any>([])

    useEffect(() => {
      if(yearlyStats) graphData(yearlyStats); 
        
    }, [yearlyStats])

    const graphData = async(data:any) => {
            setTotalHours(data.total_events_hours)
            setTotalEvents(data.total_events_created)
            setTotalVolunteers(data.total_user_registered)
    }

    const data = {
        labels,
        datasets: [
            {
              label: '',
              data: [totalEvents, totalVolunteers, totalHours],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 1,
            },
          ],
    }

    return(
        <Doughnut data={data} />
    )
}

export default PieChart;