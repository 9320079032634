import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { fetchHighlightsImageList } from "../../features/home/highlightsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import UpdateHighlights from "./updateHighlights";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Loader from "../loader";

export default function Highlight() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const imageList = useAppSelector((state) => state.highlightsImages.imageList);
  const [open, setOpen] = React.useState(false);
  const [listOfFeaturedImages, setListOfFeaturedImages] = React.useState([]);
  const user = useAppSelector((state) => state.userProfile.profile);

  const openUpdateHighlights = () => {
    setOpen(true);
  };
  const handlePopupClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (imageList.length > 0) {
      let list = imageList?.filter((list: any) => list.featured !== false);
      setListOfFeaturedImages(list);
    }
  }, [imageList]);

  React.useEffect(() => {
    dispatch(fetchHighlightsImageList()).then((res) => {
      if (res.meta.requestStatus == "fulfilled") setIsLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Box mb={1} sx={{ flexGrow: 1 }}>
          <Grid pt={4} pb={4} alignItems="center" container rowSpacing={1}>
            <Grid item sm={12} textAlign="center">
              <Typography variant="h3">Highlights</Typography>
            </Grid>
            {user && user.userType == "ADMIN" && (
              <>
                {/* <Grid item sm={2} textAlign={'center'}> */}
                <Button
                  variant="contained"
                  className="highlights-edit-button"
                  onClick={openUpdateHighlights}
                >
                  <ModeEditIcon fontSize="small" sx={{ paddingRight: "5px" }} />{" "}
                  Edit
                </Button>
                {/* </Grid> */}
              </>
            )}
          </Grid>
        </Box>

        <Box mb={8} sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              {isLoading ? (
                <Loader />
              ) : (
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1800}
                  keyBoardControl={true}
                  customTransition="all 2s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  itemClass="carousel-item-padding-40-px"
                  arrows={true}
                >
                  {listOfFeaturedImages &&
                    listOfFeaturedImages.map((item, i) => (
                      <Item key={i} item={item} />
                    ))}
                </Carousel>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      {open && (
        <UpdateHighlights
          open={open}
          handleClose={handlePopupClose}
          imageList={imageList}
        />
      )}
    </React.Fragment>
  );
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Item(props: any) {
  return (
    <Paper>
      <Avatar
        sx={{ width: "95%", height: 240, m: "0px auto", borderRadius: "0" }}
        src={props.item.imageName}
        alt="highlight-image"
      />
    </Paper>
  );
}
