import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";
import { create } from "@mui/material/styles/createTransitions";


interface spocsState {
    spocsList: []
}

const initialState:spocsState = {
    spocsList: [],
}

const spocsSlice = createSlice({
    name: 'spocs',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(fetchSpocsList.fulfilled, (state, action) => {
            state.spocsList = action.payload;
          })
          
      },
}
)
//export const {fetchEventList} = homeSlice.actions;
export default spocsSlice.reducer;

export const fetchSpocsList = createAsyncThunk('spocs/getAllSpocs', async () => {

    const response = await axiosInstance.get(`api/spocs/getAllSpocs`);

    return response.data
  })

  export const updateSpocsList = createAsyncThunk('spocs/updateSpocs', async (data:any) => {

    const response = await axiosInstance.put(`api/spocs/admin/updateSPoc/${data.id}`, data);

    return response.data
  })
  export const deleteSpoc = createAsyncThunk('spocs/deleteSpocs', async (id:any) => {

    const response = await axiosInstance.delete(`/api/spocs/admin/deleteSpoc/${id}`);

    return response.data
  })

  export const createSpoc = createAsyncThunk('spocs/createSpocs', async (data:any) => {

    const response = await axiosInstance.post(`/api/spocs/admin/create-spocs`, data);

    return response.data
  })

