import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL, URL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface userState {
    userId: number
}

const initialState:userState = {
    userId: 1234
}

const userSlice = createSlice({
    name: 'getUser',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(getUserRegisterForEvent.fulfilled, (state, action) => {
            state.userId = action.payload.userId
          })
          
      },
}
)

export default userSlice.reducer;

export const getUserRegisterForEvent = createAsyncThunk('/Volunteer/getLoggedInVolunteer', async () => {
    let eventId = localStorage.getItem("eventId")
    const response = await axiosInstance.get(`Volunteer/getLoggedInVolunteer?eventId=${eventId}`)
    return response.data;
})