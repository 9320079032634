import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface impactStoriesState {
  feedbackList: {};
}

const initialState: impactStoriesState = {
  feedbackList: {},
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFeedback.fulfilled, (state, action) => {
      state.feedbackList = action.payload;
    });
  },
});

export default feedbackSlice.reducer;

export const createFeedback = createAsyncThunk(
  "postData",
  async (data: object) => {
    const response = await axiosInstance.post(
      `api/feedbacks/createFeedback`,
      data
    );
    return response.data;
  }
);
export const deleteFeedbackImg = createAsyncThunk(
  "deleteFeedbackImg",
  async (id: number) => {
    const response = await axiosInstance.delete(
      `${"api/feedbackImages/deleteFeedbackImg/"+id}`
    );
    return response.data;
  }
);
