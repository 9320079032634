import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import { faq } from '../../helpers/constant';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import './faq.css';
import editIcon from "../../static/images/editIcon.svg"
import deleteIcon from "../../static/images/deleteIcon.svg"
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { editFaq, fetchFaq, deleteFaq } from '../../features/faq/faqSlice';
import Toaster from '../toaster';



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '0px',
  '&:not(:last-child)': {
    borderBottom: '1px solid #c0c0c0',

  },
  '&::before': {
    display: 'none',
  },
}));

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
          transform: 'none !important',
        },
        ".expandIconWrapper": {
          display: "none",
          transform: 'none !important',
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
          transform: 'none !important',
        }
      }}
    >
      <div className="expandIconWrapper"><RemoveCircleOutlineIcon fontSize='large' sx={{ color: '#0078D6' }} /></div>
      <div className="collapsIconWrapper"><AddCircleOutlineIcon fontSize='large' sx={{ color: '#000' }} /></div>
    </Box>
  );
};


const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<CustomExpandIcon />}
    // expandIcon={<AddCircleOutlineIcon fontSize='large' sx={{color: '#000' }} />}

    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    textDecoration: 'underline',
    color: '#0078D6'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content: hover': {
    color: '#0078D6'
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  background: '#fff',
  color: '#000',
}));

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -70%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #c0c0c0',
  boxShadow: 24,
  p: 4,
};

export default function CustomizedLightAccordions({ faq }: any) {
  const dispatch = useAppDispatch();
  const [openToaster, setOpenToaster] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string | false>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [id, setId] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const user = useAppSelector((state)=>state.userProfile.profile)



  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleEdit = (event:any, f:any) => {
    console.log("Data", f);

    setId(f.id);
    setQuestion(f.question);
    setAnswer(f.answer);

    handleOpen();
  }  

  const handleDelete = (event:any, f:any) => {

    setId(f.id);
    handleOpenDialog();
  }

  const handleToasterClose = () => {
    setOpenToaster(false)
  }

  const handleTextChange= (event:any, type:string) =>{

    if(type == 'answer'){
      setAnswer(event.target.value);
    }
    else if(type == 'question'){
      setQuestion(event.target.value);
    }
  }

  const handleSubmit = () => {
    if(id != 0){
      let data ={
        id: id,
        payload:{
          question: question,
          answer: answer
        }
      }
      setMessage("FAQ updated successfully!")
      dispatch(editFaq(data)).then(() => {
        dispatch(fetchFaq());
      });
      handleClose()
      setOpenToaster(true)

    }
  }

  const handleDeleteSubmit = () => {
    if(id != 0){
      let data ={
        id: id
      }
      setMessage("FAQ deleted successfully!")
      dispatch(deleteFaq(data)).then(() => {
        dispatch(fetchFaq());
      });
      handleCloseDialog()
      setOpenToaster(true)

    }
  }

  return (
    <div>
      {faq && faq.map((f: any, index: any) => {
        return (
          <Accordion key={f.id} expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
            <AccordionSummary className='accordion-summary-header' aria-controls="panel1d-content" id="panel1d-header"  sx={{ transform: 'none', padding: '10px 0'}}>
              <Typography pl={2} variant='h6' sx={{fontWeight:'400!important'}} dangerouslySetInnerHTML={{__html: f.question}}></Typography>
              
              {user && user.userType == 'ADMIN' &&
              <Box sx={{display:'flex', float: 'right'}}>
              <IconButton sx={{padding:0}} onClick={ (event) => handleEdit(event, f)}>
                  <Avatar src={editIcon} alt='editIcon' />
              </IconButton> 
              <IconButton sx={{padding:0}} onClick={ (event) => handleDelete(event, f)}>
                <Avatar src={deleteIcon} alt='deleteIcon' sx={{marginLeft: '12px'}}/>
              </IconButton>
              </Box>}
              
            </AccordionSummary>
            <AccordionDetails>
              <Typography pl={5.5} pb={2} className="added-link" dangerouslySetInnerHTML={{__html: f.answer}}>
                
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <TextField fullWidth label="Question" defaultValue={question} onChange={ (event) => handleTextChange(event, "question")}/>
          <TextField
            id="outlined-multiline-static"
            label="Answer"
            multiline
            fullWidth
            rows={4}
            defaultValue={answer}
            onChange={ (event) => handleTextChange(event, "answer")}
            sx={{mt:'20px'}}
          />

          <Button sx={{mt:'20px', borderRadius: 0}} variant="contained" disableElevation onClick={handleSubmit}>Update</Button>
          <Button sx={{mt:'20px', borderRadius: 0, ml:2}} variant="contained" disableElevation onClick={handleClose}>Cancel</Button>
        </Box>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Delete FAQ"}
        </DialogTitle> */}
        <DialogContent sx={{padding:'40px 80px'}}>
          <DialogContentText id="alert-dialog-description" sx={{fontSize:'28px', fontWeight:400, color:'#000', fontFamily: 'mcregular !important', width:'260px', textAlign:'center'}}>
            Are you sure you want to delete this FAQ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{margin: '25px auto'}}>
          <Button variant='contained' onClick={handleDeleteSubmit} autoFocus sx={{background:'#008dfc', width:'120px'}}>
            Yes
          </Button>
          <Button variant='contained' onClick={handleCloseDialog} sx={{background:'#008dfc', width:'120px',}}>No</Button>
        </DialogActions>
      </Dialog>
      <Toaster openToaster={openToaster} toastMessage={message} handleToasterClose={handleToasterClose}/>
    </div>
  );
}