import React, { useEffect } from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Box, Button, DialogContentText, FormControl, Grid } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getImageName } from "../../helpers/functions";
import { useAppDispatch } from "../../app/hooks";
import { createSpoc, fetchSpocsList } from "../../features/spocs/spocsSlice";
import dayjs, { Dayjs } from 'dayjs';
import { uploadFile } from '../../features/common/fileUploadSlice';
import { createTestimonial, deleteTestimonial, fetchStories, updateTestimonial } from "../../features/home/impactStoriesSlice";
import Toaster from "../toaster";

interface file {
    lastModified: number,
    lastModifiedDate: {}
    name: string
    size: number
    type: string
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'visible',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UpdateTestimonials = ({ open, handleClose, testimonial, mode, title }: any) => {
    const dispatch = useAppDispatch();
    const [imageName, setImageName] = React.useState<Array<file>>([]);
    const [file, setFile] = React.useState([]);
    const [openToaster, setOpenToaster] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [toastType, setToastType] = React.useState('info');
    const [testimonialData, setTestimonialData] = React.useState({
        displayStatus: 1,
        userId: localStorage.getItem('userId'),
        description: "",
        createdAt: "",
        updatedAt: "",
        isDeleted: 0
    })

    const handleFormChange = (e: any) => {

        setTestimonialData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))
    }

    const handleToasterClose = () => {
        setOpenToaster(false)
    }

    const createTestimonialsData = (e: any) => {
        e.preventDefault();
        setToastType('success');
        if (mode === 'edit') {
            dispatch(updateTestimonial(testimonialData)).then((res) => {
                if (res.meta.requestStatus == 'fulfilled') {
                    setToastMessage('Testimonial updated successfully!')
                    setOpenToaster(true)
                    dispatch(fetchStories());
                    setTimeout(()=>{
                        handleClose(false);
                    },2000)
                }
            })
        } else if (mode === 'add') {
            dispatch(createTestimonial(testimonialData)).then(() => {
                setOpenToaster(true);
                setToastMessage('Testimonial created successfully!')
                dispatch(fetchStories());
                setTimeout(()=>{
                    handleClose(false);
                },2000)
            })

        }
    }

    const handleDeleteSubmit = () => {
        dispatch(deleteTestimonial(testimonial.testimonial_id)).then((res) => {
            if (res.meta.requestStatus == 'fulfilled') {
                setToastMessage('Testimonial deleted successfully!')
                setToastType('success');
                setOpenToaster(true);
                dispatch(fetchStories());
                setTimeout(()=>{
                    handleClose(false);
                },2000)
            }
        })
    }


    React.useEffect(() => {
        let copyTestimonialData = JSON.parse(JSON.stringify(testimonialData))
        if (testimonial && mode === 'edit') {
            copyTestimonialData.description = testimonial.description
            copyTestimonialData.id = testimonial.testimonial_id
            setTestimonialData(copyTestimonialData)
        }

    }, [testimonial])


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='lg'

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="modal-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ backgroundColor: '#EAEBEB' }}>
                    {mode !== 'delete' ?
                        (<Box component="form" noValidate >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <DemoItem label="Testimonial (Max. 400 chars):">
                                            <OutlinedInput name="description"
                                                value={testimonialData.description}
                                                placeholder="Add here" id="bootstrap-input" size="small"
                                                onChange={(e) => handleFormChange(e)}
                                                multiline={true}
                                                minRows={5}
                                                inputProps={{ maxLength: 400 }}
                                                
                                            />
                                        </DemoItem>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>) :
                        (
                            <DialogContentText id="alert-dialog-description" sx={{ fontSize: '28px', fontWeight: 400, color: '#000', fontFamily: 'mcregular !important', width: '260px', textAlign: 'center' }}>
                                Are you sure you want to delete this Testimonial?
                            </DialogContentText>
                        )
                    }
                </DialogContent>
                {mode !== 'delete' ?
                    (<DialogActions sx={{ alignSelf: 'center' }}>
                        <Button variant="contained" sx={{ textTransform: 'capitalize' }} className="mui-modal-button" onClick={(e) => createTestimonialsData(e)}>
                            Publish
                        </Button>
                    </DialogActions>) :
                    (
                        <DialogActions sx={{ margin: '25px auto' }}>
                            <Button variant='contained' onClick={handleDeleteSubmit} autoFocus sx={{ background: '#008dfc', width: '120px' }}>
                                Yes
                            </Button>
                            <Button variant='contained' onClick={handleClose} sx={{ background: '#008dfc', width: '120px', }}>No</Button>
                        </DialogActions>
                    )
                }
            </BootstrapDialog>

            <Toaster openToaster={openToaster} type={toastType} toastMessage={toastMessage} handleToasterClose={handleToasterClose}/>

        </>
    )
}
export default UpdateTestimonials;
