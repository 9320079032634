import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface faqState {
    faqList: [],
    faq:{}
}

const initialState:faqState = {
    faqList: [],
    faq:{}
}

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(fetchFaq.fulfilled, (state, action) => {
            state.faqList = action.payload;
          })
          .addCase(editFaq.fulfilled, (state, action) => {
            state.faq = action.payload;
          })
          .addCase(addFaq.fulfilled, (state, action) => {
            state.faq = action.payload;
          })
          
      },
}
)
//export const {fetchEventList} = homeSlice.actions;
export default faqSlice.reducer;

export const fetchFaq = createAsyncThunk('faqs/getAllFaqs', async () => {
    const response = await axiosInstance.get('api/faqs/getAllFaqs')
    return response.data
  })

  export const editFaq = createAsyncThunk('faqs/edit', async (data:any) => {

    const response = await axiosInstance.put(`api/faqs/admin/${data.id}`, data.payload)
    return response.data
  })

  export const addFaq = createAsyncThunk('faqs/add', async (data:any) => {

    const response = await axiosInstance.post(`api/faqs/admin/createFaq`, data)
    return response.data
  })

  export const deleteFaq = createAsyncThunk('faqs/delete', async (data:any) => {

    const response = await axiosInstance.delete(`api/faqs/admin/${data.id}`)
    return response.data
  })