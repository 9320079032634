import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIURL } from "../../helpers/constant";
import axiosInstance from "../../helpers/axiosInstance/axiosInstance";

interface eventState {
    createEventBody: {},
    file:{},
    createEventImagesBody: {},
 //   department: []
}

const initialState:eventState = {
    createEventBody: {},
    file:{},
    createEventImagesBody: {},
  //  department: []
}

const createEventSlice = createSlice({
    name: 'createEvent',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          
          .addCase(createEvent.fulfilled, (state, action) => {
            state.createEventBody = action.payload,
            state.file = action.payload
          })
          .addCase(createEventImages.fulfilled, (state, action) => {
            state.createEventImagesBody = action.payload
          })
          // .addCase(getAllDepartments.fulfilled, (state, action) => {
          //   state.department = action.payload
          // })
      },
}
)

export default createEventSlice.reducer;

export const createEvent = createAsyncThunk('events/createEvent', async (formData:any) => {
    const response = await axiosInstance.post(`api/events/admin/createEvent`,formData)
    return response.data;
})

export const createEventImages = createAsyncThunk('eventImages/createEventImages', async (data:object) => {
    const response = await axiosInstance.post(`api/eventImages/admin/createEventImages`, data)
    return response.data;
})
// export const getAllDepartments = createAsyncThunk('events/getAllDepartments', async () => {
//     const response = await axiosInstance.get(`api/events/getAllDepartments`)
//     return response.data;
//   })
//Note: commented code for department lists for Phase 2